import React from "react";

import DateCell from "$components/table/cells/date.js";
import Flags from "$components/flags";
import InitialsCell from "$components/table/cells/initials";
import LargeNumber from "$components/info-list/content/large-number";
import LogoCell from "$components/table/cells/logo";

import { abbrNum } from "$utils/number-helper";

export const defaultColumns = [
    {
        Header: "Project Type",
        accessor: "name", // accessor is the "key" in the data
        id: "name", // id is used as a sort key (i.e., actual api name)
        Cell: (data) => (
            <div>
                <Flags data={[{ classnames: { "-independence": data.row?.original?.requiresIndependence }, label: "Check Independence" }]} />
                {data.value}
            </div>
        ),
    },
    {
        Header: "Client",
        accessor: "clientGroupName",
        id: "clientGroupName",
        Cell: (data) => <LogoCell data={data} />,
    },
    {
        Header: "Sub Client",
        accessor: "clientName",
        id: "clientName",
        className: "",
    },
    {
        Header: "Project PIC",
        accessor: "projectPic",
        id: "projectPicName",
        width: 100,
        Cell: (data) => <InitialsCell data={data} />,
    },
    {
        Header: "Start Date",
        accessor: "startDate",
        id: "startDate",
        sortDescFirst: true,
        width: 100,
        Cell: (data) => <DateCell data={data} />,
    },
    {
        Header: "Last Date",
        accessor: "endDate",
        id: "endDate",
        sortDescFirst: true,
        width: 100,
        Cell: (data) => <DateCell data={data} noValue="Ongoing" />,
    },
    {
        Header: "Status",
        accessor: "status",
        id: "projectstatus",
        sortDescFirst: true,
        width: 100,
    },
    {
        Header: "Revenue To Date",
        accessor: "revenueToDate",
        id: "revenueToDate",
        sortDescFirst: true,
        width: 140,
        Cell: (data) => {
            return <LargeNumber value={`$${abbrNum(data.value)}`} isHealthy={true} />;
        },
    },
];
