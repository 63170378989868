import React, { useEffect, useState } from "react";
import { Radio } from "../inputs";

import { updateFn } from "../libs/helpers";

export const RadioGroup = (props) => {
    const { defaultValue, id, name, onChange, options, updateOnChange } = props;
    const [value, setValue] = useState(defaultValue || "");

    const handleOnChange = (n, v) => {
        const newValue = value === v ? "" : v;
        setValue(newValue);
        if (updateOnChange) {
            updateOnChange(id || name, newValue);
        }
    };

    return (
        <div className="radio-group">
            {options.map((o) => {
                return <Radio key={`radio_${o.value}`} id={`${props.id || props.name || ""}`} value={o.value} label={o.text} defaultValue={o.value === value} updateOnChange={handleOnChange} />;
            })}
        </div>
    );
};

RadioGroup.defaultProps = {
    value: false,
    options: [],
};
