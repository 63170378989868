import React from "react";
import { abbrNum } from "../../../utils/number-helper";

const ChartSummary = ({ data }) => {
    return (
        <div className="chart-summary">
            {data.map((d, index) => {
                return (
                    <div key={`summary_${index}`}>
                        {d.label || d.text}: <em>${abbrNum(d.value)}</em>
                    </div>
                );
            })}
        </div>
    );
};

export default ChartSummary;
