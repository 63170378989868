import React, { useEffect, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownWideShort, faArrowDownShortWide } from "@fortawesome/free-solid-svg-icons";

import HealthPanel from "./subclients";
import Select from "$components/form-inputs/select";
import Pagination from "$components/table/components/pagination";

import { useInput } from "$utils/hooks/use-input";
import { sortByKey } from "$utils/mapping";
import { scrollToName } from "$utils/scroll-to";

import "./subclients.scss";
import { SelectedSubclientStore } from "$stores/subclients";

const perPage = 10;

const HealthContent = ({ subClients, totalPercentOfRevenue }) => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [paginatedClients, setPaginatedClients] = useState([]);

    const [sortAsc, setSortAsc] = useState(true);
    const [sortedSubClients, setSortedSubClients] = useState([...subClients]);
    const selectSort = useInput({ text: "Portion of Revenue", value: "portionOfRevenue", isAsc: false });

    const selectedSubclient = SelectedSubclientStore.useState((s) => s);
    useEffect(() => {
        if (selectedSubclient) {
            const indexOfClient = sortedSubClients.findIndex((client) => client.id === selectedSubclient);
            const page = Math.ceil((indexOfClient + 1) / perPage);
            setPage(page);
            window.setTimeout(() => {
                scrollToName(`client_${selectedSubclient}`);
            }, 200);
        }
    }, [selectedSubclient]);

    useEffect(() => {
        if (subClients.length > 0) {
            setPages(Math.ceil(subClients.length / perPage));
            setSortedSubClients(_.take(subClients, perPage));
        }
    }, [subClients]);

    useEffect(() => {
        const defaultValueSort = selectSort?.value?.isAsc;
        if (sortAsc === defaultValueSort) {
            setSortedSubClients([...subClients].sort((a, b) => sortByKey(a, b, selectSort?.value?.value, defaultValueSort)));
        } else {
            setSortAsc(defaultValueSort);
        }
    }, [subClients, selectSort.value]);

    useEffect(() => {
        const skip = (page - 1) * perPage;
        const take = perPage;
        setPaginatedClients(_.slice(sortedSubClients, skip, skip + take));
    }, [sortedSubClients, page]);

    useEffect(() => {
        setSortedSubClients([...subClients].sort((a, b) => sortByKey(a, b, selectSort?.value?.value, sortAsc)));
        setPage(1);
    }, [subClients, sortAsc]);

    const handleSortChange = (name, value) => {
        selectSort.setValue(value);
        setPage(1);
    };

    const handleSort = () => {
        setSortAsc(!sortAsc);
    };

    const goNextPage = () => {
        if (page >= pages) {
            setPage(pages);
            return;
        }
        setPage(page + 1);
    };

    const goPreviousPage = () => {
        if (page <= 1) {
            setPage(1);
            return;
        }
        setPage(page - 1);
    };

    return (
        <div className="subclients-content">
            <div className="flex-header">
                <h3>
                    All <em>Sub Clients</em>
                </h3>
                <div className="button-group">
                    <div className="sort-icon" onClick={handleSort}>
                        <FontAwesomeIcon icon={sortAsc ? faArrowDownShortWide : faArrowDownWideShort} />
                    </div>
                    <Select
                        label="Sort By"
                        options={[
                            { text: "Portion of Revenue", value: "portionOfRevenue", isAsc: false },
                            { text: "Subclient Name", value: "name", isAsc: true },
                            { text: "Score", value: "healthScore", isAsc: true },
                            { text: "Revenue At Risk", value: "revenueAtRisk", isAsc: true },
                        ]}
                        updateOnChange={handleSortChange}
                        defaultValue={selectSort.value}
                    />
                </div>
            </div>
            {paginatedClients && paginatedClients.length > 0 ? (
                <>
                    {paginatedClients.map((subClient) => (
                        <HealthPanel key={subClient.id} id={subClient.id} subClient={subClient} totalPercentOfRevenue={totalPercentOfRevenue} />
                    ))}
                    <Pagination
                        pageIndex={page - 1}
                        pageCount={pages}
                        nextPage={goNextPage}
                        previousPage={goPreviousPage}
                        canPreviousPage={page > 1}
                        canNextPage={page < pages}
                        pageOptions={Array.from({ length: pages }, (x, i) => i)}
                    />
                </>
            ) : null}
        </div>
    );
};

export default HealthContent;
