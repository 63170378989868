export const mapByKey = (data, key) => {
    return data
        ? data.reduce((obj, iv) => {
              obj[iv[key]] = iv;
              return obj;
          }, {})
        : {};
};

export const sortByKey = (a, b, key = "name", isAsc) => {
    if (a[key] < b[key]) {
        return isAsc ? -1 : 1;
    }
    if (a[key] > b[key]) {
        return isAsc ? 1 : -1;
    }
    return 0;
};
