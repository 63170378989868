export const TOP5_TITLES = {
    ClientGroupsTotalGrowth: {
        header: (
            <h3>
                Parent Clients by
                <br />
                <em>Total Growth Potential</em>
            </h3>
        ),
        type: "currency",
    },
    /*ClientGroupsSVIScore: {
        header: (
            <h3>
                Parent Clients by
                <br />
                <em>SVI Score</em>
            </h3>
        ),
        type: "number",
    },*/
    /*ClientGroupsRevenueRisk: {
        header: (
            <h3>
                Parent Clients by
                <br />
                <em>Revenue At Risk</em>
            </h3>
        ),
        type: "currency",
    },*/
    ProjectTypesTotalGrowth: {
        header: (
            <h3>
                Project Types by
                <br />
                <em>Total Growth Potential</em>
            </h3>
        ),
        type: "currency",
    },
    ProjectTypesAverageGrowth: {
        header: (
            <h3>
                Project Types by
                <br />
                <em>Average Growth Potential</em>
            </h3>
        ),
        type: "currency",
    },
    PotentialProjectsGrowth: {
        header: (
            <h3>
                Potential Projects by
                <br />
                <em>Growth Potential</em>
            </h3>
        ),
        type: "currency",
    },
    PartnersTotalGrowth: {
        header: (
            <h3>
                Partners by
                <br />
                <em>Total Growth Potential</em>
            </h3>
        ),
        type: "currency",
    },
    /*PartnersSVIScore: {
        header: (
            <h3>
                Partners by
                <br />
                <em>SVI Score</em>
            </h3>
        ),
        type: "number",
    },*/
    /*PartnersRevenueRisk: {
        header: (
            <h3>
                Partners by
                <br />
                <em>Revenue At Risk</em>
            </h3>
        ),
        type: "currency",
    },*/
};
