import React from "react";

import HelpNavigation from "./components/navigation";

import { useDocumentTitle } from "$utils/document-title";

import "./help.scss";

const HelpIndex = () => {
    useDocumentTitle("Help");
    return (
        <div id="help" className="page">
            <div className="main">
                <aside>
                    <HelpNavigation />
                </aside>
                <main>
                    <h1>Help</h1>

                    <h2 name="overview">
                        <em>Overview of Orgaimi</em>
                    </h2>
                    <div className="help-section">
                        <ol>
                            <li>Simplify your firm's approach to expanding client relationships with key predictions, intelligent insights, and timely recommendations from Orgaimi.</li>
                            <li>
                                Orgaimi is your firm's own predictive model that learns from past successes with similar clients based on Finance and CRM data, and helps you identify where clients are underserved and
                                opportunities are untapped.
                            </li>
                            <li>Orgaimi creates the following primary outputs to help partners, business development executives, marketing teams, and leaders achieve greater value for their clients:</li>
                            <ol>
                                <li>
                                    <b>Potential Projects:</b> Untapped service opportunities for each client based on the clients pattern of services purchased with your firm, lifetime value of the client, and industry
                                    grouping.
                                </li>
                                <li>
                                    <b>Growth Potential:</b> Quantification of how much value has yet to be untapped within each client account, based on how many potential projects are projected in the next year.
                                </li>
                                <li>
                                    <b>Client Health Scores:</b> Indication of how well your historical client management actions suggest growth potential for the future.
                                </li>
                                <li>
                                    <b>Recommendations:</b> Validated trends over time that when implemented will increase likelihood of growth.
                                </li>
                            </ol>
                        </ol>
                    </div>

                    <h2 name="use-cases" className="txt -primary">
                        Primary Use Cases
                    </h2>
                    <div className="help-section">
                        <ol>
                            <li>
                                Orgaimi's primary use case is to make it as easy as possible for partners and business developers to identify untapped growth potential and clients and help them start the journey to
                                capture that revenue.
                            </li>
                            <li>Each user persona has a unique way of achieving this goal through Orgaimi:</li>
                            <ol>
                                <li>
                                    <b>Client Partner:</b> Orgaimi helps Client Partners to focus on the accounts with the most growth potential, connect them to the potential projects and firm team members that will
                                    drive expansion, and alert them to potential trends that could indicate the client is being underserved.
                                </li>
                                <li>
                                    <b>Project Partner:</b> Orgaimi helps Project Partners to identify new project opportunities for clients they are currently supporting through existing projects and alert them to
                                    potential trends that could indicate the client is being underserved.
                                </li>
                                <li>
                                    <b>Business Development/Marketing Executive:</b> Orgaimi helps Business Development Executives identify potential projects with the most revenue potential and probability, with the
                                    ability to aggregate to spot overall trends for sub-segments of the firm.
                                </li>
                                <li>
                                    <b>Firm Leader:</b> Orgaimi helps Firm Leaders understand aggregate trends in performance across industries, service lines, and geographies, identifying how to best support Client and
                                    Project Partners.
                                </li>
                            </ol>
                        </ol>
                    </div>

                    <h2 name="potential-projects-core">
                        <em>Potential Projects:</em> Core Concepts
                    </h2>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/potential-projects-core.jpg" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            <b>Potential Projects</b> are untapped service opportunities for a client based on the client's pattern of services purchased with your firm, lifetime value of the client, and industry
                            grouping.
                        </p>
                        <h4>How Does It Work?</h4>
                        <ul>
                            <li>
                                Every existing client has a journey with you as a firm: what type of work they initially engaged with you on, how well you performed at the beginning of the relationship, and how that
                                relationship transformed over time.
                            </li>
                            <li>Orgaimi's AI processors measure the hundreds of thousands of unique journeys to spot patterns in the data of outcomes that have led to sustained, strategic growth.</li>
                            <li>
                                Orgaimi compares journeys of “like clients” using the following segmentation:
                                <ul>
                                    <li>Service History</li>
                                    <li>Industry Grouping</li>
                                    <li>Lifetime Value of the Client</li>
                                </ul>
                            </li>
                            <li>Orgaimi's models are trained and updated on a monthly basis, so as new data emerges, the model adjusts over time.</li>
                        </ul>
                    </div>

                    <h2 name="client-health-scores-core">
                        <em>Client Health Scores:</em> Core Concepts
                    </h2>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-health-scores.jpg" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            <b>Client Health Scores</b> indicate how well your historical client management actions suggest growth potential for the future.
                        </p>
                        <h4>How Does It Work?</h4>
                        <ul>
                            <li>Orgaimi measures leading indicators of growth across Delivery Process, Team Composition, Cross Collaboration, Business Administration, Client Demographics, and External Market Trends.</li>
                            <li>
                                Orgaimi's AI processors measure which leading indicators have historically led to the most growth for similar types of clients and prioritizes which combination of indicators are most
                                associated with growth.
                            </li>
                            <li>Orgaimi's models are trained and updated on a monthly basis, so as new data emerges, the model adjusts over time.</li>
                        </ul>
                    </div>

                    <h2 name="sitemap" className="txt -primary">
                        Sitemap
                    </h2>
                    <h3 name="login">Getting Logged In</h3>

                    <div className="help-images">
                        <div>
                            <img src="/images/help/login-screen.jpg" alt="Login Screen" />
                        </div>
                        <div>
                            <img src="/images/help/login-microsoft.jpg" alt="Login Microsoft" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Congratulations! You have been granted access to Orgaimi. Here is how you log-in.</p>
                        <ol>
                            <li>Navigate to your organization's Orgaimi link provided by your Orgaimi administrator or Customer Success Consultant.</li>
                            <li>Click the "Login with Microsoft" button.</li>
                            <li>
                                If you are not logged into your organization's Microsoft 365 account, you will automatically be prompted to enter your organization's Microsoft 365 email address and password. Complete the
                                log-in process.
                            </li>
                            <li>Once you have completed your log-in process, you will be directed to the Orgaimi home page.</li>
                            <li>If you are already logged in to your organization's Microsoft 365 account, you will be directed to the home page.</li>
                        </ol>
                    </div>

                    <h3 name="navigation">Navigation</h3>
                    <div className="help-images">
                        <div>
                            <img src="images/help/header.jpg" alt="header" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>At the top of each Orgaimi page are tabs and links for navigating to major features.</p>
                        <ul>
                            <li data-number="1">Navigate to other pages and log-out</li>
                            <li data-number="2">Return to Dashboard Page</li>
                            <li data-number="3">Access your personal dashboard to compare your client account's growth potential to the firm's growth potential</li>
                            <li data-number="4">View growth potential for Service, Industry, and Geography Segments</li>
                            <li data-number="5">Compare Offices based on Growth Potential</li>
                            <li data-number="6">Access a client list to compare clients based on growth opportunity</li>
                            <li data-number="7">Access a project list to view potential and existing projects in the context of client accounts</li>
                            <li data-number="8">Apply client-level filters to create custom views</li>
                            <li data-number="9">Find the top clients to focus on based on growth opportunity</li>
                        </ul>
                    </div>

                    <h3 name="dashboard">Dashboard</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/dashboard.jpg" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Your Dashboard provides a personalized view of growth potential for your portfolio of clients and how it compares to the rest of the firm.</p>
                        <ul>
                            <li data-number="1">
                                <b>My Growth Potential</b>
                                <ul>
                                    <li>My total Growth Potential is the sum of Growth Potential for all clients in which you are a Client Partner and who have actively worked with the firm in the past 3 years.</li>
                                    <li>Growth Potential is split by Consulting, Assurance, and Tax based on the service line of Potential Projects.</li>
                                </ul>
                            </li>
                            <li data-number="2">
                                <b>12-Month Revenue of My Clients</b>
                                <ul>
                                    <li>Net revenue less expenses of all projects in the last 12 months where you are a Client Partner.</li>
                                </ul>
                            </li>
                            <li data-number="3">
                                <b>Firm Growth Potential</b>
                                <ul>
                                    <li>Firm Growth Potential is the sum of Growth Potential for all clients who have actively worked with the firm in the past 3 years.</li>
                                </ul>
                            </li>
                            <li data-number="4">
                                <b>12-Month Revenue of The Firm</b>
                                <ul>
                                    <li>Net revenue less expenses of all projects in the last 12 months for the firm</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="growth-potential-panel">Growth Potential Panel</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/growth-potential-panel.jpg" alt="Growth Potential Panel" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            Your Dashboard also shows your Growth Potential panel. The panel prioritizes the top 5 clients based on Growth Potential for various roles you might be playing on the account. This can be
                            accessed at anytime in the upper right corner.
                        </p>
                        <ul>
                            <li data-number="1">Access "My Clients" to see the top 5 clients by Growth Potential in which you are a Client Partner</li>
                            <li data-number="2">Access "My Projects" to see the top 5 clients by Growth Potential where you are the Project Partner</li>
                            <li data-number="3">Access "All Firm" to see the top 5 clients by Growth Potential for the firm</li>
                            <li data-number="4">Access "Search" to find a different Team Member's client accounts</li>
                        </ul>
                    </div>

                    {/*<h3 name="segments">Segments</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/segments.jpg" alt="Segments Page" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Segment view provides a summary of Client Health and Growth Potential for the following segments of the firm: Service Line and Industry. This view helps you identify "hotspots" of clients
                            where you may want to focus on for growth or risk mitigation.
                        </p>
                        <ul>
                            <li data-number="1">
                                The x-axis (rows) represent different segments of the firm (Service Line or Industry)
                                <ul>
                                    <li>
                                        Within each segment description, you will see the Growth Potential for that segment based on the Clients who are:
                                        <ul>
                                            <li data-number="2">in that Service Line or Industry</li>
                                            <li data-number="3">as well as an indicator if Growth Potential is trending upward or downward for this segment</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li data-number="4">The y-axis (columns) represent Client Health Score Themes</li>
                            <li data-number="5">Each square in the chart represents that Segment's Average Scores for each Theme</li>
                            <li data-number="6">
                                The colors of each square are based on a 10-point scale from 100-700, where light blue indicates Excellent Client Health, dark blue/dark gold indicate Good Client Health, and bright gold
                                indicate Client Health to Review
                            </li>
                        </ul>
                    </div>*/}

                    <h3 name="offices-map">Offices Map</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/offices-map.jpg" alt="Offices Map" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Offices view provides a summary of Client Health and Growth Potential for Offices. Clients are allocated to Offices based on the GL location of the Client Partner. This view helps you
                            identify "hotspots" of offices where you may want to focus on for growth or risk mitigation.
                        </p>
                        <ul>
                            <li data-number="1">
                                Each bubble represents one of the firm's offices
                                <ul>
                                    <li data-number="2">The size of the bubble represents the Total Growth Potential per Office</li>
                                    <li data-number="2">
                                        The color of the bubble represents the average Client Health Score, where light blue indicates Excellent or Good Client Health and bright gold indicate Client Health to Review
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Hover over one of the offices to see summary statistics for the office
                                <ul>
                                    <li data-number="3">Average Health Score for the Office</li>
                                    <li data-number="4">Percentage change in Health Score over the past month</li>
                                    <li data-number="5">Office name and Office Managing Partner</li>
                                    <li data-number="6">Link to Detailed View</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="office-details">Office Details</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/office-details.jpg" alt="Office Details" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Offices Detail view provides detailed statistics on the Office:</p>
                        <ul>
                            <li data-number="1">
                                <b>Scores Tab</b>
                                <ul>
                                    <li data-number="2">Office name and Office Managing Partner</li>
                                    <li data-number="3">Average Health Score for the Office</li>
                                    <li data-number="4">Percentage change in Health Score over the past month</li>
                                    <li data-number="5">Ranking of Offices Shown on the Map based on Average Health Score</li>
                                </ul>
                            </li>
                            <li data-number="6">
                                <b>Financials Tab</b>
                                <ul>
                                    <li data-number="7">Office name and Office Managing Partner</li>
                                    <li data-number="8">
                                        Statistics on Total Revenue of the Office, Average Revenue per Client and Average Revenue per Partner, where gold indicates selected office and white indicates average of all firm
                                        offices
                                    </li>
                                    <li data-number="9">Six-month revenue history of selected office</li>
                                </ul>
                            </li>
                            <li data-number="10">
                                <b>Drivers Tab</b>
                                <ul>
                                    <li data-number="11">Office name and Office Managing Partner</li>
                                    <li data-number="12">Spider chart indicating performance against drivers, where gold indicates selected office and white indicates average of all firm offices</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="client-list">Client List</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-list.jpg" alt="Client List" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Client List provides a tabular view of all existing client accounts where there has been billing activity in the last 3 years. The Client List includes the following fields:</p>
                        <ul>
                            <li data-number="1">Client Name: the “Parent Client” or the highest level of aggregation of accounts</li>
                            <li data-number="2">Client PIC: the Client Partner who owns the account for the firm</li>
                            <li data-number="3">Industry: industries that the Parent Client represents &ndash; can have multiple due to multiple sub-clients</li>
                            <li data-number="4">Service Line: service lines where the Parent Client has had billings in the last 12-months</li>
                            <li data-number="5">12-Month Revenue: total net revenue less expenses in the last 12-months, showing a trend over the past 12 months</li>
                            <li data-number="6">Growth Potential: the Growth Potential for the client in the next 12 months</li>
                            <li data-number="7">Top Potential: the Potential Project with the highest revenue amount</li>
                            <li data-number="8">Utilize the page navigation at the bottom to see more clients</li>
                            <li data-number="9">Click on a Client's row to navigate to the Client Page</li>
                        </ul>
                    </div>

                    <h3 name="client-page">Client Page</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-page.jpg" alt="Client Page" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Client View provides detail on a specific Client account.</p>
                        <ul>
                            <li data-number="1">
                                Navigation Panel
                                <ul>
                                    <li data-number="2">Access detail on Potential Projects</li>
                                    <li data-number="3">Access Recommendations based on client management trends</li>
                                    <li data-number="4">Access Client Details</li>
                                    <li data-number="5">Access Client Health Scores</li>
                                </ul>
                            </li>
                            <li data-number="6">
                                Client Header
                                <ul>
                                    <li data-number="7">Client Name</li>
                                    <li data-number="8">Date of latest Orgaimi update</li>
                                    <li data-number="9">Growth Potential Multiplier based on last 12-months revenue</li>
                                    <li data-number="10">Total Growth Potential in the next 12 months</li>
                                    <li data-number="11">Dollar Change in Growth Potential since last month</li>
                                    <li data-number="12">Percentage Change in Growth Potential since last month</li>
                                    <li data-number="13">Client Health Score</li>
                                    <li data-number="14">Point Change in Client Health Score since last month</li>
                                    <li data-number="15">Number Change in Client Health Score since last month</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="potential-projects">Potential Projects</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/potential-projects.jpg" alt="Potential Projects" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Potential Projects View provides three potential project opportunities for the client based on the client's pattern of services purchased with your firm, lifetime value of the client, and
                            industry grouping.
                        </p>
                        <ul>
                            <li data-number="1">Select the "Average Revenue" toggle to see the top three projects ranked by Average Revenue Range of the potential project</li>
                            <li data-number="2">Select the "Expansion Probability" toggle to see the top three projects ranked by Expansion Probability of the potential project</li>
                            <li>
                                Each Potential Project has the following elements:
                                <ul>
                                    <li data-number="3">Project Type of the Potential Project</li>
                                    <li data-number="4">Sub-client to target for the project</li>
                                    <li data-number="5">Average Revenue Range, based on historical data of clients who have purchased this Project Type who have similar lifetime fees and are in the same industry</li>
                                    <li data-number="6">Expansion Probability indicates how frequently this Project Type has occurred in the past for clients with a similar pattern to the selected client</li>
                                    <li data-number="7">Service Line for the Project Type</li>
                                    <li data-number="8">Industry of the Sub-Client</li>
                                    <li data-number="9">Experienced Team Members, based on team members who have significant experience in this project type, client, or industry</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="recommendations">Recommendations</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-recommendations.jpg" alt="Client Recommendations" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Recommendations View provides three client trends that can be optimized to drive further growth and prevent client churn. These trends have been selected based on similar clients to the
                            selected client who have improved their growth. Each Recommendation card provides the following information:
                        </p>
                        <ul>
                            <li data-number="1">Client management trend that should be increased, decreased, or maintained to improve likelihood of growth</li>
                            <li data-number="2">Justification for why Orgaimi is recommending a certain client management behavior change</li>
                            <li data-number="3">Statistics on this client's trends vs. clients like them who have improved their growth</li>
                        </ul>
                    </div>

                    <h3 name="client-details">Client Details</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-details.jpg" alt="Client Details" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Client Details View provides additional information about the client to put Orgaimi's potential projects and recommendations into context.</p>
                        <ul>
                            <li data-number="1">
                                Client Information Box
                                <ul>
                                    <li data-number="2">Client Partner</li>
                                    <li data-number="3">Primary Office Location</li>
                                    <li data-number="4">Active Service Lines</li>
                                    <li data-number="5">Industry Group Affiliations</li>
                                </ul>
                            </li>
                            <li data-number="6">
                                12-Month Revenue by Service Line / Like Clients
                                <ul>
                                    <li data-number="7">Toggle to change view to be based on Service Lines or Like Clients</li>
                                    <li data-number="8">Chart of last 12 months of revenue by Service Lines or Like Clients</li>
                                </ul>
                            </li>
                            <li data-number="9">
                                Existing Projects
                                <ul>
                                    <li data-number="10">Project Name</li>
                                    <li data-number="11">Sub Client affiliated with the project</li>
                                    <li data-number="12">Project Partner</li>
                                    <li data-number="13">Start/open date of project</li>
                                    <li data-number="14">End/closed date of project</li>
                                    <li data-number="15">Net revenue less expenses of the project to date</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="scores">Scores</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-scores.jpg" alt="Client Scores" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Scores View provides insight into how the client's management trends are performing against historical trends of how client's have grown successfully in the past. </p>
                        <ul>
                            <li data-number="1">The main banner describes your client's 12 Month Score Trend and the definition of Health Scores, Themes and Features</li>
                            <li data-number="2">
                                The Score Themes and Features Detail breaks down your client's health score into themes that are categories of related client management behaviors &ndash; depending on your implementation,
                                you will likely see 4-6 themes
                            </li>
                            <li data-number="3">
                                Each theme has a summary view of the Theme Score, which includes:
                                <ul>
                                    <li data-number="4">Categorization of Health: Review, Good, or Excellent</li>
                                    <li data-number="5">Categorization of Impact: Low, Moderate, or High</li>
                                    <li data-number="6">Theme Score</li>
                                    <li data-number="7">Percent Change in Theme Score since last month</li>
                                    <li data-number="8">Text that describes how a point increase in the health score translates to growth</li>
                                    <li data-number="9">Expansion toggle allowing the user to expand the theme score to reveal more details</li>
                                </ul>
                            </li>
                            <li data-number="10">
                                When the theme is expanded, the card reveals additional details
                                <ul>
                                    <li data-number="11">Two features that are contributing to the theme score, and a description of each feature</li>
                                    <li data-number="12">Percent Change in the value of the feature in the last month</li>
                                    <li data-number="13">Description of the types of features that are included in the theme</li>
                                    <li data-number="14">6-Month Theme Score Trend</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="potential-project-list">Potential Project List</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/potential-project-list.jpg" alt="Potential Project List" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Project List View displays data about potential projects and existing projects (both closed and active projects) to provide a more tabular view of identifying project opportunities. The
                            Project List for Potential Projects includes:
                        </p>
                        <ul>
                            <li data-number="1">Project Type for the potential project</li>
                            <li data-number="2">Client Name</li>
                            <li data-number="3">Sub-Client Name to target the potential project</li>
                            <li data-number="4">Service Line of the Project Type</li>
                            <li data-number="5">Client Size</li>
                            <li data-number="6">Average Revenue Range for the Project Type based on Client Size and Industry</li>
                            <li data-number="7">Expansion Probability</li>
                            <li data-number="8">Toggle to switch between Potential and Existing Projects</li>
                        </ul>
                    </div>

                    <h3 name="existing-project-list">Existing Project List</h3>
                    <div className="help-images">
                        <div>
                            <img src="images/help/existing-projects-list.jpg" alt="Existing Project List" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Project List View displays data about potential projects and existing projects (both closed and active projects) to provide a more tabular view of identifying project opportunities. The
                            Project List for Existing Projects includes:
                        </p>
                        <ul>
                            <li data-number="1">Project Type of the existing project</li>
                            <li data-number="2">Client Name</li>
                            <li data-number="3">Sub-Client Name of the existing project</li>
                            <li data-number="4">Project Partner of the existing project</li>
                            <li data-number="5">Start/open date of the project</li>
                            <li data-number="6">End/closed date of the project: if the project is open, this will show as "ongoing"</li>
                            <li data-number="7">Status of project: open, completed/closed, suspended</li>
                            <li data-number="8">Revenue to date</li>
                        </ul>
                    </div>

                    <h3 name="potential-project-detail">Potential Project Detail</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/potential-project-detail.jpg" alt="Potential Project" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Potential Project Detail view gives additional information on the potential project to help decision makers, including:</p>
                        <ul>
                            <li>Project Type for the potential project (1)</li>
                            <li>Client Name (2)</li>
                            <li>Sub-Client Name to target the potential project (3)</li>
                            <li>Client Partner (4)</li>
                            <li>Service Line of the Project Type (5)</li>
                            <li>Industry of the Client (6)</li>
                            <li>Average Revenue Range for the Project Type based on Client Size and Industry (7)</li>
                            <li>Expansion Probability (8)</li>
                            <li>Experienced Team Members, based on team members who have significant experience in this project type, client, or industry (9)</li>
                            <li>Distribution chart of previous projects like this that have occurred in the past, based on project type, industry, and client size (10)</li>
                        </ul>
                    </div>

                    <h3 name="existing-project-detail">Existing Project Detail</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/existing-project-detail.jpg" alt="Existing Project Detail" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Existing Project Detail view gives additional information on existing projects to help contextualize future opportunities.</p>
                        <h5>Summary</h5>
                        <ul>
                            <li data-number="1">Project Type of the existing project</li>
                            <li data-number="2">Client Name</li>
                            <li data-number="3">Client Partner</li>
                            <li data-number="4">Start/open date of the project</li>
                            <li data-number="5">End/closed date of the project: if the project does not have an end/closed date, this will show as "ongoing"</li>
                            <li data-number="6">Sub Client of the existing project</li>
                            <li data-number="7">Project Partner of the existing project</li>
                            <li data-number="8">Status of project: open, completed/closed, suspended</li>
                            <li data-number="9">Service Line</li>
                            <li data-number="10">Industry of the Client</li>
                        </ul>
                        <h5 data-number="11">12-Month Revenue Comparison to Like Projects</h5>
                        <ul>
                            <li data-number="12">Chart of last 12 months of revenue for similar projects</li>
                        </ul>
                        <h5 data-number="13">Billing By Team Member</h5>
                        <ul>
                            <li data-number="14">Team Member Name</li>
                            <li data-number="15">Role on the Project</li>
                            <li data-number="16">Hours Billed</li>
                            <li data-number="17">Total Hours Billed</li>
                            <li data-number="18">Total Revenue</li>
                        </ul>
                    </div>

                    <h3 name="overview-panel">Overview Panel</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/overview-panel.jpg" alt="Overview Panel" />
                        </div>
                    </div>
                    <div className="help-section">
                        <ul>
                            <li data-number="1">
                                Summary Panel
                                <ul>
                                    <li data-number="2">Client Name</li>
                                    <li data-number="3">Client Partner and Description</li>
                                    <li data-number="4">Client's Last 12-Months of Revenue</li>
                                    <li data-number="5">Percentage Change in Last 12-Months of Revenue in last month</li>
                                    <li data-number="6">Client's Growth Potential</li>
                                    <li data-number="7">Percentage Change in Growth Potential since last month</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="filters">Filters</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/filters.jpg" alt="Filters" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Filter Options Include:</p>
                        <ul>
                            <li data-number="1">Search by Client</li>
                            <li data-number="2">Search by Client PIC</li>
                            <li data-number="3">Search by Project PIC</li>
                            <li data-number="4">
                                Filter by Industry of the Parent Client
                                <ul>
                                    <li>Note: There may be more than one industry represented by Parent Client</li>
                                </ul>
                            </li>
                            <li data-number="5">
                                Filter by Service Line Involvement of the Parent Client
                                <ul>
                                    <li>Notes: There may be more than one service line represented by Parent Client</li>
                                </ul>
                            </li>
                            <li data-number="6">Filter by the Geographic Location of the Client PIC for the Parent Client</li>
                        </ul>
                    </div>

                    <h3 name="key-terms">Key Terms</h3>
                    <div className="help-section">
                        <ol>
                            <li>
                                <b>Potential Projects:</b> Untapped service opportunities for each client based on the client's pattern of services purchased with your firm, lifetime value of the client, and industry
                                grouping.
                            </li>
                            <li>
                                <b>Growth Potential:</b> Quantification of how much value has yet to be untapped within each client account, based on how many potential projects are projected in the next year.
                            </li>
                            <li>
                                <b>Client Health Scores:</b> Indication of how well your historical client management actions suggest growth potential for the future.
                                <ol>
                                    <li>
                                        <b>Themes:</b> Groups of Features that behave similarly to each other and target a similar positive client outcome.
                                        <br />
                                        <br />
                                        <div className="table-grid">
                                            <div className="row -two-column">
                                                <div className="cell">
                                                    <h5>Delivery Process</h5>
                                                </div>
                                                <div className="cell">Indicators about project management, team processes, and how the work is being delivered</div>
                                            </div>
                                            <div className="row -two-column">
                                                <div className="cell">
                                                    <h5>Team Composition</h5>
                                                </div>
                                                <div className="cell">Indicators about team size, roles, and experiences</div>
                                            </div>
                                            <div className="row -two-column">
                                                <div className="cell">
                                                    <h5>Cross Collaboration</h5>
                                                </div>
                                                <div className="cell">Indicators about relationship networks, interactions across the firm, and practice, industry, and geographic collaboration</div>
                                            </div>
                                            <div className="row -two-column">
                                                <div className="cell">
                                                    <h5>Client Demographics</h5>
                                                </div>
                                                <div className="cell">Indicators about the firmographics of the client, marketing behavior, and relationship factors</div>
                                            </div>
                                            <div className="row -two-column">
                                                <div className="cell">
                                                    <h5>External Market</h5>
                                                </div>
                                                <div className="cell">Indicators about market and industry trends that may limit or enhance growth opportunity</div>
                                            </div>
                                            <div className="row -two-column">
                                                <div className="cell">
                                                    <h5>Business Administration</h5>
                                                </div>
                                                <div className="cell">Indicators about the financial, contractual, or administrative client dynamics</div>
                                            </div>
                                        </div>
                                        <br />
                                    </li>
                                    <li>
                                        <b>Features:</b> Features are specific behavioral trends of clients that suggest growth or risk.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Recommendations:</b> Validated trends over time that when implemented will increase likelihood of growth.
                            </li>
                            <li>
                                <b>Like Clients:</b> clients with a similar history of services purchased with your firm, lifetime value of the client, and industry grouping.
                            </li>
                            <li>
                                <b>Like Projects:</b> projects that have followed a similar client journey of services purchased with your firm, lifetime value of the client, and industry grouping.
                            </li>
                            <li>
                                <b>Last 12 Months Revenue:</b> Last 12 months of net revenue from all existing clients in the firm with at least one month of billing activity in the last 3 years.
                            </li>
                            <li>
                                <b>Average Project Revenue Size:</b> Average revenue of projects with this Project Type for like clients.
                            </li>
                            <li>
                                <b>Expansion Probability:</b> How frequently like clients successfully expanded the client account with this Project Type.
                            </li>
                            <li>
                                <b>Segments:</b> Portions of the firm based on Industry, Geography, or Service Line.
                            </li>
                        </ol>
                    </div>

                    <h3 name="key-contacts">Key Contacts</h3>
                    <div className="help-section">
                        <h4 className="txt -white">Help Desk</h4>
                        <p>
                            <a href="mailto:BTsupport@orgaimi.com">BTsupport@orgaimi.com</a>
                        </p>
                        <h4 className="txt -white">Derek Jett, Customer Success Manager</h4>
                        <p>
                            <a href="mailto:djett@axiomcp.com ">djett@axiomcp.com</a>
                        </p>
                        <h4 className="txt -white">Maggie Miller, Chief Client Officer</h4>
                        <p>
                            <a href="mailto:mmiller@orgaimi.com">mmiller@orgaimi.com</a>
                        </p>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default HelpIndex;
