import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

import CustomLegend from "$components/charts/legend";

import { DateCurrencyTooltip } from "$components/charts/tooltips/currency";
import { CHART_DEFAULT_COLORS } from "$components/charts/lib/constants";
import { formatDate, SHORT_MONTH_FORMAT } from "$utils/date-helpers";
import { abbrNum } from "$utils/number-helper";
import { GRID_STROKE } from "$components/charts/svg-definitions";

const RevenueChart = ({ data, highlightFn }) => {
    return (
        <ResponsiveContainer width="99%" height="100%">
            <LineChart
                width={500}
                height={300}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke={GRID_STROKE} vertical={false} />
                <XAxis dataKey="date" axisLine={false} tickFormatter={(label) => formatDate(label, SHORT_MONTH_FORMAT)} allowDuplicatedCategory={false} />
                <YAxis axisLine={false} tickFormatter={(label) => `$${abbrNum(label)}`} />
                <Tooltip content={<DateCurrencyTooltip />} />
                <Legend wrapperStyle={{ top: -30, left: 0 }} content={CustomLegend} />
                {data.map((l, i) => {
                    return (
                        <Line
                            key={`line_${i}_${data[i].key}`}
                            url={data[i].url}
                            data={data[i].data}
                            type="monotone"
                            dataKey="value"
                            name={data[i].label}
                            stroke={CHART_DEFAULT_COLORS[i]}
                            strokeWidth={highlightFn ? highlightFn(l) : 4}
                            dot={false}
                        />
                    );
                })}
            </LineChart>
        </ResponsiveContainer>
    );
};

RevenueChart.defaultProps = {
    data: [],
    highlightFn: null,
};

export default RevenueChart;
