import React, { useEffect, useState } from "react";

import Flags from "$components/flags";
import ProfileIcon from "$components/profile-icon";
import ProbabilityBubble from "$components/probability";
import StatWithChange from "$components/dashboard/components/stat";
import ScoreBubble from "$components/score-bubble";

import { AppContextStore } from "$stores/app-context";
import { formatDate, SHORT_DATE_FORMAT } from "$utils/date-helpers";
import { calculateScoreMark, checkIsHealthy, checkIsHealthyByChange } from "$utils/health-helper";
import { getNumberUnitPlace } from "$utils/number-helper";
import { TOOLTIP_12_MONTH_REVENUE, TOOLTIP_GROWTH_POTENTIAL, TOOLTIP_HEALTH_SCORE } from "$utils/tooltips";

import "./client-dashboard.scss";

const ClientSharedDashboard = ({ client }) => {
    const lastDataUpdate = AppContextStore.useState((f) => f.versions.lastModelRun);

    return (
        <div className="client-dashboard">
            <Flags data={[{ classnames: { "-independence": client?.requiresIndependence }, label: "Check Independence" }]} />
            <div className="client-logo">
                <ProfileIcon imageUrl={null} name={client?.name} modifierClassNames={["-client", "-medium"]} />
                <h2>
                    {client?.name}
                    <span>Updated {formatDate(lastDataUpdate, SHORT_DATE_FORMAT)}</span>
                </h2>
            </div>
            <div className="divide"></div>
            {!!client?.twelveMonthRevenue && (
                <StatWithChange label="12-Month Revenue" value={client.twelveMonthRevenue} increase={Math.round(client.twelveMonthRevenueChangePercent)} tooltip={TOOLTIP_12_MONTH_REVENUE} isHealthy={true} />
            )}
            <div className="divide"></div>
            {!!client?.growthPotential && <StatWithChange label="Growth Potential" value={client.growthPotential} increase={Math.round(client.growthPotentialChangePercent)} tooltip={TOOLTIP_GROWTH_POTENTIAL} />}
            <div className="divide"></div>
            {!!client?.growthPotential && <StatWithChange label="Revenue At Risk" value={client.revenueAtRisk} increase={undefined} />}
            <div className="divide"></div>
            <div className="stat-group">
                <div>
                    <ScoreBubble graph={true} value={client?.healthScore} healthy={checkIsHealthy(client?.healthScore)} percent={(client?.healthScore / 700) * 100} modifierClassNames={["-small"]} />
                    <h4 className="txt -center">Health</h4>
                </div>
                &nbsp; &nbsp;
                <div>
                    <ProbabilityBubble value={client.sviScore * 10} cssClasses={["-importance"]} />
                    <h4 className="txt -center">SVI</h4>
                </div>
            </div>
        </div>
    );
};

export default ClientSharedDashboard;
