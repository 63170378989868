import React from "react";

import { AppContextStore } from "../../../stores/app-context";

const FilterCount = () => {
    const filterCount = AppContextStore.useState((s) => s.currentFiltersCount);
    const label = filterCount > 1 ? "Filters" : "Filter";
    return (
        <div className="filter-count">
            <img src="/images/icon-filter.svg" className="svg -detail" alt="Filters" width="20" />
            <b>{filterCount}</b>
            <span>{label}</span>
        </div>
    );
};

export default FilterCount;
