import { useDocumentTitle } from "$utils/document-title";
import { useEffect } from "react";
import { logout } from "../../stores/user";

const resetAction = async () => {
    await logout();
    window.location = "/";
};

/**
 * @description This component runs after receiving a successful logout from azure
 *
 **/
const Reset = () => {
    useDocumentTitle("Reset");
    useEffect(() => {
        resetAction();
    }, []);
    return null;
};

export default Reset;
