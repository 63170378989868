import React, { useEffect } from "react";
import debounce from "debounce";

import ClientList from "./list";
import FilterStats from "$components/filter-stats";

import { ClientStore, fetchClients, watchPagination, setLoading, reset } from "$stores/clients";
import { AppContextStore, fetchOverview } from "$stores/app-context";
//import { fetchOffices } from "$stores/offices";
import { useDocumentTitle } from "$utils/document-title";
import GlobalDashboard from "$components/dashboard";

var firstLoad = true;

const debouncedFetchClients = (currentFilters, pagingInfo) => {
    fetchClients(currentFilters, { ...pagingInfo });
    fetchOverview(currentFilters);
};

const ClientIndex = () => {
    useDocumentTitle("Client List");
    const { hasLoadedFilters, currentFilters, overviewLoading } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, currentFilters: f.currentFilters || {}, overviewLoading: f.overviewLoading };
    });

    const pagingInfo = ClientStore.useState((f) => {
        return { page: f.pageIndex, limit: f.pageSize, sort: f.sort, isSortAsc: f.isSortAsc };
    });
    useEffect(() => {
        const unsubs = watchPagination();
        return () => {
            if (unsubs && unsubs.length > 0) {
                unsubs.forEach((unsub) => unsub());
            }

            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (firstLoad) {
            firstLoad = false;
        }
        if (!firstLoad && hasLoadedFilters && currentFilters && pagingInfo) {
            setLoading();
            debouncedFetchClients(currentFilters, pagingInfo);
            // TODO: Make sure this office call isn't needed
            //fetchOffices(currentFilters);
        }
    }, [hasLoadedFilters, currentFilters, pagingInfo]);

    //const overview = AppContextStore.useState((s) => s.overview);

    return (
        <div id="client-list" className="page">
            <GlobalDashboard>
                <FilterStats />
            </GlobalDashboard>
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <h1>
                            <em>Client</em> List
                        </h1>
                        <ClientList />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ClientIndex;
