import React from "react";

const MapLegend = () => {
    return (
        <div className="map-legend">
            <ul>
                <li>
                    <b>Size:</b> Growth Potential Per Office
                </li>
                <li>
                    <b>Color:</b> Average Score Per Office
                </li>
                <li>
                    <div className="dot -unhealthy"></div>
                    <b>REVIEW</b> (100-299)
                </li>
                <li>
                    <div className="dot -healthy"></div>
                    <b>GOOD/EXCELLENT</b> (300-700)
                </li>
            </ul>
        </div>
    );
};

export default MapLegend;
