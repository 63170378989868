import PaginatedStore from "./base/PaginatedStore";
import endpoints from "../services/endpoint";

export const DEFAULT_SORT = "name";
const CLIENT_STORAGE_KEY = `/api/client-groups${endpoints("clients_groups_list")}`;

const { store, fetchList, setLoading, reset, previousPage, nextPage, gotoPage, setPageSize, setSort, watchPagination } = PaginatedStore({
    key: "clients",
    label: "Clients",
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    storageKey: CLIENT_STORAGE_KEY,
});

export const ClientStore = store;

export const fetchClients = fetchList;

export { setLoading, reset, previousPage, nextPage, gotoPage, setPageSize, setSort, watchPagination };
