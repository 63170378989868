import React from "react";

import { formatDate, SHORT_DATE_FORMAT } from "$utils/date-helpers";
import { abbrNum } from "$utils/number-helper";
import classNames from "classnames";

const SinglePayload = ({ data }) => {
    const colorClass = data.value < 0 ? "-unhealthy" : "-healthy";
    return (
        <span className={classNames("txt -center -bold -condensed -healthy -xxlarge", colorClass)} style={{ color: data.stroke }}>
            <sup>$</sup>
            {abbrNum(data.value)}
        </span>
    );
};

const MultiPayload = ({ data, keysToLabel = {} }) => {
    const colorClass = data.value < 0 ? "-unhealthy" : "-healthy";
    return (
        <div className="list">
            <span style={{ color: data.stroke }}>{keysToLabel[data.name] || data.name}: </span>
            <span className={classNames("txt -center -bold -condensed -healthy -xxlarge", colorClass)} style={{ color: data.stroke }}>
                <sup>$</sup>
                {abbrNum(data.value)}
            </span>
        </div>
    );
};

export const DateCurrencyTooltip = ({ active, payload, xKey = "date", yKey = "value" }) => {
    if (payload && active) {
        const filteredPayload = payload.filter((p) => p.type !== "none");
        const isMultiPayload = filteredPayload.length > 1;
        return (
            <div className="custom-tooltip">
                <h5 className="txt -center">{payload[0] ? formatDate(payload[0].payload[xKey], SHORT_DATE_FORMAT) : "-"}</h5>
                {!isMultiPayload && <SinglePayload data={payload[0]} key={yKey} />}
                {isMultiPayload && payload.map((p, i) => <MultiPayload key={`date-currency-tooltip_${i}`} data={p} />)}
            </div>
        );
    }

    return null;
};

export const LabelCurrencyTooltip = ({ active, payload, yKey = "value", keysToLabel = {} }) => {
    if (payload && active) {
        const filteredPayload = payload.filter((p) => p.type !== "none");
        const activePayload = filteredPayload[0] || {};
        const isMultiPayload = filteredPayload.length > 1;
        return (
            <div className="chart-tooltip -dark -potential">
                {!isMultiPayload && <h5 className="txt -center">{activePayload.label || keysToLabel[activePayload.name] || activePayload.name}</h5>}
                {!isMultiPayload && <SinglePayload data={payload[0]} key={yKey} />}
                {isMultiPayload && payload.map((p, i) => <MultiPayload key={`date-currency-tooltip_${i}`} data={p} keysToLabel={keysToLabel} />)}
            </div>
        );
    }
    return null;
};
