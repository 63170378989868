import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import EngineLoader from "../../loaders/engine-loader";
import FinancialPanel from "./panels/financials";
import InsightPanel from "./panels/insights";
import ScoresPanel from "./panels/scores";
import Tabs from "../../tabs";

import { fetchPanelData, OfficeStore } from "$stores/offices";
import { AppContextStore } from "$stores/app-context";
import { PanelContext } from "$contexts/panels";
import { checkIsHealthy } from "$utils/health-helper";

import "./office-panel.scss";

const OfficePanel = () => {
    const { panels, togglePanel } = useContext(PanelContext);
    const [isOpen, setIsOpen] = useState(false);
    const panelData = OfficeStore.useState((o) => o.panels);
    const offices = OfficeStore.useState((o) => o.offices);
    const appContext = AppContextStore.useState((u) => u);

    useEffect(() => {
        if (panels.officeDetails && appContext.hasLoadedFilters) {
            setIsOpen(true);
            fetchPanelData(window.location.hash.replace("#office_", ""), offices, appContext.currentFilters);
        } else {
            setIsOpen(false);
        }
    }, [panels.officeDetails, offices, appContext.currentFilters, appContext.hasLoadedFilters]);

    return ReactDOM.createPortal(
        <>
            {panelData && !panelData.isLoading && (
                <div id="office-panel" className={classnames("panel-contents", { "-active": panels.officeDetails })}>
                    <button
                        className="button -text btn-close"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.hash = "";
                            togglePanel("officeDetails", false);
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div className="office-panel">
                        <Tabs tabs={[{ label: "Scores" }, { label: "Financials" }, { label: "Insights" }]} defaultIndex={isOpen ? 0 : -1}>
                            <div className="panel-content">
                                {panelData.isLoading_scores && <EngineLoader rotatingText={["Loading scores...", "Just a moment..."]} rotationTimer={4500} />}
                                {!panelData.isLoading_scores && panelData.scores && <ScoresPanel office={panelData.office} data={panelData.scores} />}
                            </div>
                            <div className="panel-content">
                                {" "}
                                {panelData.isLoading_financials && <EngineLoader rotatingText={["Loading financials...", "Just a moment..."]} rotationTimer={4500} />}
                                {panelData.financials && <FinancialPanel data={panelData.financials} scores={panelData?.scores} />}
                            </div>
                            <div className="panel-content">
                                {panelData.isLoading_insights && <EngineLoader rotatingText={["Loading insights...", "Just a moment..."]} rotationTimer={4500} />}
                                {panelData.insights && <InsightPanel office={panelData.office} data={panelData.insights} />}
                            </div>
                        </Tabs>
                    </div>
                </div>
            )}
        </>,
        document.getElementById("panel-root")
    );
};

export default OfficePanel;
