import logger from "../services/logger";
import api from "../services/api";
import { Store } from "pullstate";
import { uniq } from "lodash";
import { toast } from "react-toastify";

import { buildDateFromString } from "../utils/date-helpers";
import { checkIsHealthy, checkIsHealthyByChange } from "../utils/health-helper";
import { abbrNum, formatPercentage } from "../utils/number-helper";
import { buildFilters } from "../utils/api-helpers";
import { retrieveValue, persistValue, clearValue } from "../services/local";
import { TOOLTIP_12_MONTH_REVENUE } from "$utils/tooltips";

export const FILTER_SET = [
    {
        label: "Industry",
        key: "industry",
        apiKey: "industry",
        values: [],
    },
    {
        label: "Service Line",
        key: "practice",
        apiKey: "Practice",
        values: [],
    },
    {
        label: "Geography",
        key: "country",
        apiKey: "office",
        values: [],
        subKey: "office",
    },
    {
        label: "Score Range",
        key: "scoreRange",
        apiKey: "scoreRange",
        values: [],
        subKey: "scoreRange",
    },
];

export const FILTER_RESULT_SET = [
    {
        label: "Clients",
        singular: "Client",
        key: "client",
    },
    {
        label: "CRMs",
        singular: "CRM",
        key: "crm",
    },
    {
        label: "Geographies",
        singular: "Geography",
        key: "geography",
    },
    {
        label: "Offices",
        singular: "Office",
        key: "office",
    },
];

const initialState = {
    showFilter: true,
    filterMenu: FILTER_SET,
    hasLoadedFilters: false,
    selectedSavedFilter: "",
    currentFilters: {},
    currentFiltersCount: 0,
    currentPotentialRevenue: 0,

    overview: null,
    overviewLoading: true,
    globalError: null,
    isHaltAndCatchFire: false,

    versions: {},
};

const FILTER_STORAGE_KEY = "current_filters";

export const AppContextStore = new Store(initialState);

export const filtersToOptionValues = (currentSet, filter) => {
    return currentSet.map((setItem) => {
        const theSet = uniq(filter[setItem.key]);
        const options =
            !!filter[setItem.subKey] && filter[setItem.subKey].length > 0
                ? filter[setItem.subKey].map((sub) => {
                      return { value: sub.id, text: sub.text, parentMetadataId: sub.parentMetadataId };
                  })
                : null;
        return {
            ...setItem,
            metadataType: theSet && theSet.length > 0 ? theSet[0].metadataType : null,
            values: theSet
                ? theSet.map((o) => {
                      const toReturn = {
                          value: o.id,
                          text: o.text,
                          options: options
                              ? options.filter((opt) => {
                                    return opt.parentMetadataId === o.id;
                                })
                              : null,
                      };
                      return toReturn;
                  })
                : null,
        };
    });
};

const apiOverview = async (filters, apiSettings) => {
    await updateOverviewLoading(true);
    logger.log("Fetching Overview", { filters: filters });
    try {
        const result = await api.getFromCache(`/api/overview?${buildFilters(filters)}`, null, apiSettings);

        AppContextStore.update((s) => {
            if (!result.overview) {
                return s;
            }
            const o = result.overview;

            s.overview = {
                counts: result.overviewCounts,
                data: result.overview,
            };
            s.currentPotentialRevenue = o.revenuePotential;
            return s;
        });
    } catch (e) {
        if (api.requestWasCanceled(e)) {
            logger.log("Overview request was canceled");
            return;
        }
        updateGlobalError("Unable to fetch overview");
        logger.error("Unable to fetch overview", e);
    } finally {
        await updateOverviewLoading(false);
    }
};

export const fetchOverview = (filters) => {
    const abortController = new AbortController();
    apiOverview(filters, { signal: abortController.signal });
    return abortController;
};

const apiVersions = async (apiSettings) => {
    try {
        const response = await api.get("/api/versions", null, apiSettings);
        const versionInfo = {};
        if (response) {
            versionInfo.lastETLRun = buildDateFromString(response.data?.lastETLRunTime);
            versionInfo.lastModelRun = buildDateFromString(response.data?.lastModelRunTime);
            versionInfo.etlVersion = response.data?.etlVersion.simpleVersion;
            versionInfo.apiVersion = response.api.semanticVersion;
        }
        AppContextStore.update((s) => {
            s.versions = versionInfo;
            return s;
        });
    } catch (e) {
        if (!api.requestWasCanceled(e)) {
            logger.error(e);
            setHaltAndCatchFire(true);
        }
    }
};

export const fetchVersions = () => {
    const abortController = new AbortController();
    apiVersions({ signal: abortController.signal });

    return abortController;
};

const apiFilters = async (selectedSavedFilter, apiSettings) => {
    try {
        logger.log("Fetching filters");
        const filterResponse = await api.get("/api/filters", null, apiSettings);
        const userFilterResponse = await api.get("/api/users/filters", null, apiSettings);
        const currentFilters = (await retrieveValue(FILTER_STORAGE_KEY)) || {};
        const savedFilters = userFilterResponse.savedFilters.reduce((obj, f) => {
            let parsedFilters = {};
            try {
                parsedFilters = JSON.parse(f.filters);
            } catch (e) {
                console.error("Unable to parse filters", e);
            }
            obj[f.id] = { value: f.id, text: f.text, filters: f.filters };
            return obj;
        }, {});

        AppContextStore.update((s, draft) => {
            s.filterMenu = filtersToOptionValues(draft.filterMenu, filterResponse || {});
            s.currentFilters = currentFilters;
            s.savedFilters = savedFilters;
            s.hasLoadedFilters = true;
            if (selectedSavedFilter) {
                s.selectedSavedFilter = selectedSavedFilter;
            }
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch filters", e);
    }
};

export const fetchFilters = (selectedSavedFilter) => {
    const abortController = new AbortController();
    apiFilters(selectedSavedFilter, { signal: abortController.signal });
    return abortController;
};

export const reset = () => {
    AppContextStore.update((s) => {
        return initialState;
    });
};

export const changeCurrentFilters = (newFilters) => {
    return AppContextStore.update((s) => {
        s.currentFilters = newFilters;
        return s;
    });
};

export const updateCurrentFilters = (key, value, resetSavedFilter) => {
    AppContextStore.update((s) => {
        if (_.isEmpty(value)) {
            delete s.currentFilters[key];
        } else {
            s.currentFilters[key] = value;
        }

        if (resetSavedFilter) {
            s.selectedSavedFilter = "";
        }
        return s;
    });
};

export const replaceCurrentFilters = (newFilters, selectedFilter) => {
    AppContextStore.update((s) => {
        s.currentFilters = newFilters;
        s.selectedSavedFilter = selectedFilter || "";
        return s;
    });
};

export const updateOverviewLoading = (isLoading) => {
    return AppContextStore.update((s) => {
        s.overviewLoading = isLoading;
        return s;
    });
};

export const bulkUpdateCurrentFilters = (filters) => {
    AppContextStore.update((s) => {
        const existingFilters = { ...s.currentFilters, ...filters };
        s.currentFilters = existingFilters;
        return s;
    });
};

export const saveFilter = async (filter, currentFilters) => {
    var filterSaved = false;
    if (filter.value) {
        const response = await api.post(`/api/users/filters/${filter.value}`, {
            id: filter.value,
            text: filter.text,
            filters: JSON.stringify(currentFilters),
        });
        if (response) {
            filterSaved = true;
        }
    } else {
        const response = await api.put("/api/users/filters", {
            text: filter.text,
            filters: JSON.stringify(currentFilters),
        });
        if (response) {
            filterSaved = true;
        }
    }

    if (filterSaved) {
        fetchFilters(filter.text);
    } else {
        toast("Error: Failed To Save Filter");
        logger.error("Unable to save filter");
    }
};

export const deleteSavedFilter = async (id) => {
    if (id) {
        const response = await api.delete(`/api/users/filters/${id}`);
        if (response) {
            fetchFilters();
        }
    }
};

AppContextStore.createReaction(
    (s) => s.currentFilters,
    (currentFilters, draft) => {
        clearValue("/api/segments");
        clearValue("/api/clients");
        clearValue("/api/offices");
        clearValue("/api/overview");
        persistValue(FILTER_STORAGE_KEY, currentFilters || {});
        const filterCount = Object.keys(currentFilters).reduce((count, key) => {
            return count + currentFilters[key].length;
        }, 0);
        draft.currentFiltersCount = filterCount;
    }
);

export const updateGlobalError = (errorMessage) => {
    AppContextStore.update((s) => {
        s.globalError = errorMessage;
        return s;
    });
};

export const setShowFilter = (showFilter) => {
    AppContextStore.update((s) => {
        s.showFilter = showFilter;
        return s;
    });
};

export const setHaltAndCatchFire = (isCriticalError) => {
    AppContextStore.update((s) => {
        s.isHaltAndCatchFire = isCriticalError;
        return s;
    });
};
