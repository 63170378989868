export const CHART_HEALTHY_COLORS = ["#18b2dc", "#1395b9", "#0f7796"];

export const CHART_UNHEALTHY_COLORS = ["#e06537", "#b55735", "#884933"];

export const CHART_DEFAULT_COLORS = ["#18B2DC", "#6793DC", "#A367DC", "#DC67AB", "#DCAF66", "#C3DC67"];

export const CHART_PIE_COLORS = ["#18B2DC", "#DC67AB", "#A367DC", "#DCAF66", "#6793DC", "#C3DC67"];

export const hexToRgb = (hex) => {
    var arrBuff = new ArrayBuffer(4);
    var vw = new DataView(arrBuff);
    vw.setUint32(0, parseInt(hex.replace("#", ""), 16), false);
    var arrByte = new Uint8Array(arrBuff);

    return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
};
