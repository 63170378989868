import PaginatedStore from "./base/PaginatedStore";

export const DEFAULT_SORT = "name";
const CLIENT_STORAGE_KEY = "/api/projects/potential";

const { store, fetchList, setLoading, reset, previousPage, nextPage, gotoPage, setFilters, setPageSize, setSort, watchPagination } = PaginatedStore({
    key: "potentialProjects",
    label: "Potential Projects",
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    storageKey: CLIENT_STORAGE_KEY,
});

export const PotentialProjectsStore = store;

export const fetchPotentialProjects = fetchList;

export { setLoading, reset, previousPage, nextPage, gotoPage, setFilters, setPageSize, setSort, watchPagination };
