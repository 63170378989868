import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";

import { updateFn } from "../libs/helpers";

export const Checkbox = (props) => {
    const [value, setValue] = useState((props.defaultValue ? props.defaultValue : props.value) || false);

    useEffect(() => {
        setValue((props.defaultValue ? props.defaultValue : props.value) || false);
    }, [props.defaultValue, props.value]);

    const onClick = (e) => {
        setValue(!e.currentTarget.value);
    };

    const handleEnter = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            if (props.updateOnChange) {
                props.updateOnChange(props.name, !e.currentTarget.value);
            } else {
                setValue(!e.currentTarget.value);
            }
        }
    };

    const handleOnChange = (e) => {
        const newValue = !value;
        setValue(newValue);

        if (props.onChange) {
            props.onChange(e);
        } else if (props.updateOnChange) {
            props.updateOnChange(props.name, newValue);
        }
    };

    const icon = value ? faSquareCheck : faSquare;

    return (
        <div className={classNames("form-field -checkbox", { "-error": !!props.invalid })}>
            <div className={classNames("check", { "-selected": value })}>
                <input
                    type="checkbox"
                    key={`${props.id || props.name || ""}`}
                    id={`${props.id || props.name || ""}`}
                    disabled={props.disabled}
                    hidden={props.hidden}
                    required={props.required}
                    value={value}
                    onChange={handleOnChange}
                    onBlur={props.onBlur || updateFn(props.updateOnBlur)}
                    onClick={props.onClick || onClick}
                    onKeyPress={handleEnter}
                />
                <FontAwesomeIcon icon={icon} />
            </div>
            <label htmlFor={props.id || props.name}>{props.label}</label>
        </div>
    );
};

Checkbox.defaultProps = {
    value: false,
};
