import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { logout } from "../../stores/user";
import { useDocumentTitle } from "$utils/document-title";

const logoutAction = async (instance) => {
    await logout();
    instance.logoutRedirect({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
    });
};

/**
 * @description This component runs after receiving a successful logout from azure
 *
 **/
const Logout = () => {
    useDocumentTitle("Logout");
    const { instance } = useMsal();
    useEffect(() => {
        logoutAction(instance);
    }, [instance]);
    return null;
};

export default Logout;
