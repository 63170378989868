import React from "react";
import SemiCirclePie from "$components/charts/semi-circle-pie";
import ChartSummary from "./chart-summary";

import { LabelCurrencyTooltip } from "$components/charts/tooltips/currency";
import { TOOLTIP_12_MONTH_REVENUE, TOOLTIP_GROWTH_POTENTIAL } from "$utils/tooltips";
import { DashboardStore } from "$stores/dashboard";
import { abbrNum, formatUIValue } from "$utils/number-helper";

const GrowthPotential = () => {
    const myGrowthPotential = DashboardStore.useState((s) => s.myGrowthPotential);
    const firmGrowthPotential = DashboardStore.useState((s) => s.firmGrowthPotential);

    return (
        <div className="growth-potential">
            {myGrowthPotential && !!myGrowthPotential.growthPotential && (
                <div>
                    <h4 data-tooltip={TOOLTIP_GROWTH_POTENTIAL} className="tooltip--up">
                        <em>My</em> Growth Potential
                    </h4>
                    <SemiCirclePie TooltipComponent={LabelCurrencyTooltip} data={myGrowthPotential.serviceLines} totalValue={formatUIValue("$" + abbrNum(myGrowthPotential.growthPotential))} />
                    <ChartSummary data={myGrowthPotential.serviceLines} />
                    <h3 className="txt -healthy -compressed -black">
                        <sup>$</sup>
                        {abbrNum(myGrowthPotential.revenue)}
                        <span data-tooltip={TOOLTIP_12_MONTH_REVENUE} className="tooltip--up">
                            12-Month Revenue of my Clients
                        </span>
                    </h3>
                </div>
            )}
            {firmGrowthPotential && !!firmGrowthPotential.growthPotential && (
                <div>
                    <h4 data-tooltip={TOOLTIP_GROWTH_POTENTIAL} className="tooltip--up">
                        <em>Firm</em> Growth Potential
                    </h4>
                    <SemiCirclePie TooltipComponent={LabelCurrencyTooltip} data={firmGrowthPotential.serviceLines} totalValue={formatUIValue("$" + abbrNum(firmGrowthPotential.growthPotential))} />
                    <ChartSummary data={firmGrowthPotential.serviceLines} />
                    <h3 className="txt -healthy -compressed -black">
                        <sup>$</sup>
                        {abbrNum(firmGrowthPotential.revenue)}
                        <span data-tooltip={TOOLTIP_12_MONTH_REVENUE} className="tooltip--up">
                            12-Month Revenue of the firm
                        </span>
                    </h3>
                </div>
            )}
        </div>
    );
};

export default GrowthPotential;
