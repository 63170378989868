import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ChartBarBubble from "$components/charts/bar-bubble";
import ExistingProjects from "./components/existing-projects";
import TwelveMonthRevenue from "./components/twelve-month-revenue";

import { ClientExistingProjectsStore, fetchClientExistingProjects, setLoading, reset } from "$stores/clientExistingProjects";
import { ClientDetailsStore } from "$stores/client-details";
import { useDocumentTitle } from "$utils/document-title";
import { ChartsStore } from "$stores/charts";

const ClientExistingProjects = () => {
    const { clientId } = useParams();
    useDocumentTitle("Client Details");
    const clientDetails = ClientDetailsStore.useState((s) => s.clientDetails);
    const { existingProjectCountByProjectType } = ChartsStore.useState((c) => c.charts);

    const pagingInfo = ClientExistingProjectsStore.useState((f) => {
        return { page: f.pageIndex, limit: f.pageSize, sort: f.sort, isSortAsc: f.isSortAsc };
    });

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (pagingInfo) {
            setLoading();
            fetchClientExistingProjects({ client: [clientId] }, pagingInfo);
        }
    }, [pagingInfo]);

    return (
        <div>
            <h2>
                <em>Client</em> Detail Information
            </h2>
            <TwelveMonthRevenue clientId={clientDetails.id} />
            <h3>
                <em>Existing Project Counts</em> By Project Type
            </h3>
            <ChartBarBubble data={existingProjectCountByProjectType} />
            <h3>Existing Projects</h3>
            <ExistingProjects />
        </div>
    );
};

export default ClientExistingProjects;
