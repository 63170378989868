import { Store } from "pullstate";
import debounce from "lodash.debounce";
import api from "../services/api";

const initialState = {
    searchTerm: "",
    results: [],
    isLoading: false,
};

export const EmployeeStore = new Store(initialState);

export const updateSearchTerm = (term) => {
    EmployeeStore.update((s) => {
        s.searchTerm = term;
        s.results = [];
        s.isLoading = true;
    });
};

export const searchApi = async (term) => {
    const response = await api.get(`/api/employees?query=${term}`);

    EmployeeStore.update((s) => {
        s.results =
            response && response.employees && response.employees.length > 0
                ? response.employees.map((e) => {
                      return {
                          value: e.employeeId,
                          text: e.fullName,
                          email: e.email,
                      };
                  })
                : null;
        s.isLoading = false;
    });
};

const debouncedSearchApi = debounce(searchApi, 1000);

EmployeeStore.createReaction(
    (s) => s.searchTerm,
    (searchTerm, draft) => {
        debouncedSearchApi(searchTerm);
    }
);
