import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { getInitials } from "../../utils/initials";

import "./profile-icon.scss";

const ProfileIcon = ({ imageUrl, name, modifierClassNames }) => {
    const initials = getInitials(name) || <FontAwesomeIcon icon={faUser} />;
    return (
        <div className={classnames("profile-icon", modifierClassNames)} title={name}>
            {initials}
            {imageUrl && <div className="image-override" style={{ backgroundImage: `url(${imageUrl})` }}></div>}
        </div>
    );
};

ProfileIcon.defaultProps = {
    modifierClassNames: [],
};

export default ProfileIcon;
