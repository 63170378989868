import React from "react";
import classNames from "classnames";

import { dealProbabilityText } from "$utils/probability-text";

import "./probability.scss";

const ProbabilityBubble = ({ label, value, cssClasses = [] }) => {
    const probabilityText = dealProbabilityText(value);
    return (
        <div className="probability-bubble">
            <div className="probability-circle-wrapper">
                <div className={classNames("probability-circle", `-${probabilityText.toLowerCase()}`, cssClasses)}>
                    <div>{probabilityText}</div>
                </div>
            </div>
            {label && <h5>{label}</h5>}
        </div>
    );
};

export default ProbabilityBubble;
