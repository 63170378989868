import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import FilterStats from "$components/filter-stats";
import GlobalDashboard from "$components/dashboard";
import StackedBarChart from "$components/charts/stacked-bar";
//import ToggleSwitch from "$components/toggle-switch";
import Top5 from "$components/top5";

import { AppContextStore, fetchOverview } from "$stores/app-context";
import { fetchClients } from "$stores/clients";
import { fetchOffices } from "$stores/offices";
import { useDocumentTitle } from "$utils/document-title";
import { fetchSegmentChart, fetchSegmentGroups, fetchTop5, SegmentStore } from "$stores/segments";
import { TOP5_TITLES } from "./lib/constants";
import { handleAbort } from "$services/api";
import { debounce } from "lodash";

import "./segments.scss";

let controllerTop5;

const SegmentsIndex = () => {
    const title = useDocumentTitle("Segments");
    const { segmentGroups, top5, chart, chartYDomain } = SegmentStore.useState((s) => {
        return { segmentGroups: s.segmentGroups, top5: s.top5, chart: s.chart, chartYDomain: s.chartYDomain };
    });
    const [segmentGroup, setSegmentGroup] = useState("1");
    const [segment, setSegment] = useState("");
    const filters = AppContextStore.useState((s) => s.currentFilters);

    useEffect(() => {
        const controllerSegmentChart = fetchSegmentChart(segmentGroup, filters);

        return () => {
            handleAbort([controllerSegmentChart]);
        };
    }, [segmentGroup, filters]);

    useEffect(() => {
        const controllerClients = fetchClients(filters);
        const controllerOverview = fetchOverview(filters);
        const controllerOffices = fetchOffices(filters);
        fetchSegmentGroups();

        return () => {
            handleAbort([controllerClients, controllerOverview, controllerOffices]);
        };
    }, [filters]);

    const changeSection = (key) => {
        setSegment(-1);
        setSegmentGroup(key);
    };

    const debounceChangeSegment = useRef(
        debounce((segmentGroup, segmentId, filters) => {
            controllerTop5 = fetchTop5(segmentGroup, segmentId, filters);
        }, 500)
    ).current;

    const changeSegment = useCallback(
        (index) => {
            handleAbort([controllerTop5]);
            setSegment(index);
            debounceChangeSegment(segmentGroup, chart[index]?.id, filters);
        },
        [chart, filters]
    );

    return (
        <div id="segments" className="page">
            <GlobalDashboard>
                <FilterStats />
            </GlobalDashboard>
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <div className="button-list -underline">
                            {segmentGroups.map((s) => (
                                <button key={s.key} className={classNames("button -text", { "-active": segmentGroup === s.key })} onClick={() => changeSection(s.key)}>
                                    {s.label}
                                </button>
                            ))}
                        </div>
                        <div className="chart-container">
                            {chart && <StackedBarChart data={chart} selectedIndex={segment} onSelect={changeSegment} keysToLabel={{ growth: "Growth", revenueAtRisk: "Revenue At Risk" }} yDomain={chartYDomain} />}
                        </div>
                        <br />
                        <div className="flex-header">
                            <h1>
                                <em>Top</em> Segment Summary
                            </h1>
                        </div>
                        <div className="segment-summary">
                            {Object.keys(TOP5_TITLES).map((key) => {
                                return <Top5 key={key} data={top5[key]} type={TOP5_TITLES[key]?.type} title={TOP5_TITLES[key]?.header} />;
                            })}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default SegmentsIndex;
