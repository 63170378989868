import React from "react";

const ManagerBox = ({ imageUrl, name, title }) => {
    return (
        <div className="manager-box">
            <div className="manager">
                <img src={imageUrl} alt={name} />
                <div>
                    <h4>{name}</h4>
                    <h5>{title}</h5>
                </div>
            </div>
        </div>
    );
};

export default ManagerBox;
