import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";

import GrowthPotential from "./components/growth-potential";
import ProfileIcon from "$components/profile-icon";

import { AppContextStore, fetchOverview } from "$stores/app-context";
import { fetchDashboard } from "$stores/dashboard";
import { UserStore } from "$stores/user";
import { useDocumentTitle } from "$utils/document-title";
import { handleAbort } from "$services/api";

import "./welcome.scss";

const WelcomeIndex = () => {
    useDocumentTitle("");
    const navigate = useNavigate();
    const user = UserStore.useState((s) => s);

    const currentFilters = AppContextStore.useState((f) => f.currentFilters || false);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectPath = urlParams.get("path");
        if (redirectPath) {
            navigate(redirectPath);
        }
    });

    useEffect(() => {
        const controllerDashboard = fetchDashboard(user.id);

        return () => {
            handleAbort([controllerDashboard]);
        };
    }, [user]);

    useEffect(() => {
        let controllerOverview;
        if (currentFilters) {
            controllerOverview = fetchOverview(currentFilters);
        }

        return () => {
            handleAbort([controllerOverview]);
        };
    }, [currentFilters]);

    return (
        <div id="welcome" className="page">
            <div className="welcome-content">
                <div className="welcome-user">
                    <div className="user-info">
                        <ProfileIcon imageUrl={user.headshotUri} name={user.displayName} />
                    </div>
                    <div className="user-data">
                        <div className="txt -large">{user.displayName}</div>
                        <div className="txt -condensed -healthy -darker">{user.jobTitle}</div>
                    </div>
                    <h1>
                        <span className="txt-uppercase">
                            Welcome Back
                            {user?.firstName ? (
                                <>
                                    , <em>{user.firstName}!</em>
                                </>
                            ) : (
                                <em>!</em>
                            )}
                        </span>
                    </h1>
                    {!!user.lastLogin && (
                        <div className="background-line -darker -center -gray">
                            <span>Last Login: {formatDistance(new Date(user.lastLogin), new Date())} ago</span>
                        </div>
                    )}
                </div>

                <GrowthPotential />
            </div>
        </div>
    );
};

export default WelcomeIndex;
