import React, { useEffect } from "react";
import debounce from "debounce";

import ExistingProjectList from "./list";

import { ExistingProjectsStore, fetchExistingProjects, setLoading, reset } from "../../../stores/existingProjects";
import { AppContextStore, fetchOverview } from "../../../stores/app-context";
import { useDocumentTitle } from "$utils/document-title";

const debouncedFetchProjects = debounce(async (updatedFilters, pagingInfo) => {
    fetchExistingProjects(updatedFilters, { ...pagingInfo });
    fetchOverview(updatedFilters);
}, 800);

const ExistingProjects = () => {
    useDocumentTitle("Existing Project List");
    const { hasLoadedFilters, currentFilters } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, currentFilters: f.currentFilters || {} };
    });

    const pagingInfo = ExistingProjectsStore.useState((f) => {
        return { page: f.pageIndex, limit: f.pageSize, sort: f.sort, isSortAsc: f.isSortAsc };
    });

    useEffect(() => {
        return () => {
            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (hasLoadedFilters && currentFilters && pagingInfo) {
            setLoading();
            debouncedFetchProjects(currentFilters, pagingInfo);
        }
    }, [hasLoadedFilters, currentFilters, pagingInfo]);

    return (
        <div>
            <ExistingProjectList />
        </div>
    );
};

export default ExistingProjects;
