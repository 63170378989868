import React from "react";

import CornerRibbon from "../corner-ribbon";

import { ClientDetailsStore } from "$stores/client-details";
import { generateDisplayWrappedData, stringTemplateReplace } from "$utils/template-replace";

import "./recommendation-panel.scss";
import TeamMember from "$components/profile-icon/team-member";

const ActionItems = ({ actions, featureText }) => {
    if (!actions || actions.length <= 0) {
        return null;
    }
    return (
        <div className="recommendation-bullets">
            <div>
                <h4>What does this mean?</h4>
                {featureText && <div>{featureText}</div>}
            </div>
            {actions.map((item, i) => {
                const template = stringTemplateReplace(item.actionText, generateDisplayWrappedData(item.data), { pre: "{", post: "}" });
                return (
                    <div key={`item_${i}`}>
                        <h4>Prove It</h4>
                        <div dangerouslySetInnerHTML={{ __html: template }}></div>
                    </div>
                );
            })}
        </div>
    );
};

const RecommendationPanel = ({ data, index }) => {
    const { actionDirection, actions, clientName, featureTitle, featureText, team, theme, crmName, crmEmail } = data;

    return (
        <div className="recommendation-panel">
            <CornerRibbon text={index} />
            <header>
                <div>
                    <h3>{clientName}</h3>
                    <br />
                    <div className="contacts">
                        <div className="team-members">
                            <h4>CRM</h4>
                            <TeamMember data={{ name: crmName, email: crmEmail }} />
                        </div>
                        <div className="team-members">
                            {team?.length > 0 && (
                                <>
                                    <h4>Project CRMs</h4>
                                    {team.map((m, i) => (
                                        <TeamMember key={`member-${i}`} data={m} />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <div className="content">
                <h2>
                    <span className="txt -small -gray">Theme: {theme}</span>
                    <br />
                    {actionDirection && <em>{`${actionDirection} `}</em>}
                    {featureTitle}
                </h2>
                <ActionItems actions={actions} featureText={featureText} />
            </div>
        </div>
    );
};

export default RecommendationPanel;
