import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";

import Modal from "../../../components/modal";
import ShareRecommendations from "../../../components/modal/share-recommendations";

import { NavLink } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ClientDetailsStore } from "$stores/client-details";

const ClientNav = ({ clientId }) => {
    const appInsights = useAppInsightsContext();
    const [showModal, setShowModal] = useState(false);
    const hasSubClients = ClientDetailsStore.useState((s) => s.hasSubClients);
    const basepath = `/client/${clientId}`;

    const openModal = () => {
        setShowModal(true);

        appInsights.trackEvent(
            { name: "Modal" },
            {
                label: "Client Share",
                action: "open",
            }
        );
    };

    const closeModal = () => {
        setShowModal(false);

        appInsights.trackEvent(
            { name: "Modal" },
            {
                label: "Client Share",
                action: "close",
            }
        );
    };

    const trackEvent = (label) => {
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: label,
                section: "Client Menu",
            }
        );
    };

    return (
        <div className="client-nav">
            <div className="button-list -underline">
                <NavLink
                    end
                    className={({ isActive }) => (isActive ? "-active" : undefined)}
                    to={basepath}
                    onClick={() => {
                        trackEvent("Dashboard");
                    }}
                >
                    Dashboard
                </NavLink>
                {hasSubClients && (
                    <NavLink
                        className={({ isActive }) => (isActive ? "-active" : undefined)}
                        to={`${basepath}/sub-clients`}
                        onClick={() => {
                            trackEvent("Sub Clients");
                        }}
                    >
                        Sub Clients
                    </NavLink>
                )}
                <NavLink
                    className={({ isActive }) => (isActive ? "-active" : undefined)}
                    to={`${basepath}/growth-potential`}
                    onClick={() => {
                        trackEvent("Growth Potential");
                    }}
                >
                    Growth Potential
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? "-active" : undefined)}
                    to={`${basepath}/recommendations`}
                    onClick={() => {
                        trackEvent("Recommendations");
                    }}
                >
                    Recommendations
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? "-active" : undefined)}
                    to={`${basepath}/scores`}
                    onClick={() => {
                        trackEvent("Scores");
                    }}
                >
                    Scores
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? "-active" : undefined)}
                    to={`${basepath}/existing-projects`}
                    onClick={() => {
                        trackEvent("Existing Projects");
                    }}
                >
                    Existing Projects
                </NavLink>
            </div>
            <button className="button -text" onClick={openModal}>
                Share <FontAwesomeIcon icon={faShareNodes} />
            </button>
            <Modal show={showModal} onClose={closeModal}>
                <ShareRecommendations onClose={closeModal} />
            </Modal>
        </div>
    );
};

export default ClientNav;
