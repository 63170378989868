import React, { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";

import { abbrNum } from "$utils/number-helper";

import "./multi-range-slider.scss";

let updateRangeTimer = null;

const MultiRangeSlider = ({ name, defaultValue, min, max, updateOnChange, format }) => {
    const [minVal, setMinVal] = useState(Number(defaultValue?.min || min));
    const [maxVal, setMaxVal] = useState(Number(defaultValue?.max || max));
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);

    // Convert to percentage
    const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

    useEffect(() => {
        if ((defaultValue?.min || min) !== minVal || (defaultValue?.max || max) !== maxVal) {
            setMinVal(Number(defaultValue?.min || min));
            setMaxVal(Number(defaultValue?.max || max));
        }
    }, [defaultValue]);

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        clearTimeout(updateRangeTimer);
        if (updateOnChange) {
            const newValue = {};
            if (min !== minVal) {
                newValue.min = minVal;
            }
            if (max !== maxVal) {
                newValue.max = maxVal;
            }

            updateRangeTimer = setTimeout(() => {
                updateOnChange(name, _.isEmpty(newValue) ? "" : newValue);
            }, 600);
        }
    }, [minVal, maxVal]);

    return (
        <div className="multi-range-slider">
            <div className="slider">
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={minVal}
                    ref={minValRef}
                    onChange={(event) => {
                        clearTimeout(updateRangeTimer);
                        const value = Math.min(+event.target.value, maxVal - 1);
                        setMinVal(value);
                        event.target.value = value.toString();
                    }}
                    className={classnames("thumb thumb--zindex-3", {
                        "thumb--zindex-5": minVal > max - 100,
                    })}
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={maxVal}
                    ref={maxValRef}
                    onChange={(event) => {
                        clearTimeout(updateRangeTimer);
                        const value = Math.max(+event.target.value, minVal + 1);
                        setMaxVal(value);
                        event.target.value = value.toString();
                    }}
                    className="thumb thumb--zindex-4"
                />

                <div className="slider-track" />
                <div ref={range} className="slider-range" />
                <div className="slider-value">
                    <div className="slider-min-value">
                        {format?.isCurrency ? <sup>$</sup> : ""}
                        {abbrNum(minVal)}
                    </div>
                    &nbsp;-&nbsp;
                    <div className="slider-max-value">
                        {abbrNum(maxVal)}
                        {format?.isPercent ? <sup>%</sup> : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

MultiRangeSlider.defaultProps = {
    name: "",
    min: 0,
    max: 100,
    format: {
        isCurrency: false,
        isPercent: false,
    },
};

export default MultiRangeSlider;
