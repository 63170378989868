import PaginatedStore from "./base/PaginatedStore";

export const DEFAULT_SORT = "name";
const CLIENT_STORAGE_KEY = "/api/projects/existing";

const { store, fetchList, setLoading, reset, previousPage, nextPage, gotoPage, setPageSize, setSort, watchPagination } = PaginatedStore({
    key: "clientExistingProjects",
    label: "Client Existing Projects",
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    storageKey: "client_" + CLIENT_STORAGE_KEY,
});

export const ClientExistingProjectsStore = store;

export const fetchClientExistingProjects = fetchList;

export { setLoading, reset, previousPage, nextPage, gotoPage, setPageSize, setSort, watchPagination };
