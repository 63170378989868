import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import Pagination from "./components/pagination";

import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { DefaultFilterForColumn } from "./components/filters/filter";

import "./table.scss";

const Table = ({
    columns,
    data,
    manualFilters,
    manualPagination,
    manualSortBy,
    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    pageSize,
    pageIndex,
    setFilters,
    setPageSize,
    setSort,
    showPagination,
    sort,
    isSortAsc,
    onRowClick,
    allowFilter,
}) => {
    const tableOptions = {
        columns,
        data,
        defaultColumn: {
            Filter: DefaultFilterForColumn,
        },
        manualFilters,
        manualPagination,
        manualSortBy,
        pageCount: pageCount,
        initialState: {
            pageSize: pageSize,
            pageIndex,
            sortBy: sort
                ? [
                      {
                          id: sort,
                          desc: !isSortAsc,
                      },
                  ]
                : [],
        },
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state: { sortBy, filters },
    } = useTable(tableOptions, useFilters, useSortBy, usePagination);

    useEffect(() => {
        if (setSort && sortBy.length > 0 && sortBy[0].id) {
            setSort(sortBy[0].id, !sortBy[0].desc);
        }
    }, [setSort, sortBy]);

    useEffect(() => {
        if (setFilters) {
            setFilters(filters);
        }
    }, [filters]);

    var totalWidth = 0;
    const HeaderGroups = headerGroups.map((headerGroup) => {
        const headers = headerGroup.headers.map((column) => {
            totalWidth = totalWidth + column.width;
            let sortArrow = null;
            if (column.isSorted) {
                sortArrow = column.isSortedDesc ? (
                    <div className="desc">
                        <FontAwesomeIcon icon={faSortDown} />
                    </div>
                ) : (
                    <div className="asc">
                        <FontAwesomeIcon icon={faSortUp} />
                    </div>
                );
            }

            return (
                <div key={`header_${column.id}`} className={classNames("cell", { [column.className]: true, "-sortable": !column.disableSortBy })} style={{ flexBasis: `${column.width}px` }}>
                    <div className="header-label" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render("Header")}
                        {!column.disableSortBy && (
                            <span className="sort">
                                <FontAwesomeIcon icon={faSort} />
                                {sortArrow}
                            </span>
                        )}
                    </div>
                    <div className="column-filter">{allowFilter && column.canFilter ? column.render("Filter") : null}</div>
                </div>
            );
        });
        return (
            <div {...headerGroup.getHeaderGroupProps()} className="row -header -sticky" style={{ minWidth: `${totalWidth}px` }}>
                {headers}
            </div>
        );
    });

    const Rows = rows.map((row) => {
        prepareRow(row);
        const Cells = row.cells.map((cell) => {
            return (
                <div {...cell.getCellProps()} className={classNames("cell", { [cell.column.className]: cell.column.className })} style={{ flexBasis: `${cell.column.width}px` }}>
                    {" "}
                    {cell.render("Cell")}
                </div>
            );
        });

        return (
            <div
                {...row.getRowProps()}
                className={classNames("row data", { "-fade": row.original.userFlagged })}
                style={{ minWidth: `${totalWidth}px` }}
                onClick={
                    onRowClick
                        ? () => {
                              onRowClick(row);
                          }
                        : null
                }
            >
                {Cells}
            </div>
        );
    });

    return (
        <>
            <div className="overflow-hider"></div>

            <div className={classNames("table", { "-row-clickable": !!onRowClick })} {...getTableProps()}>
                {HeaderGroups}
                <div {...getTableBodyProps()}>{Rows}</div>
            </div>
            {showPagination && rows && rows.length > 0 && (
                <Pagination
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                    gotoPage={gotoPage}
                />
            )}
        </>
    );
};

Table.defaultProps = {
    allowFilter: false,
    manualPagination: true,
    manualSortBy: true,
    pageCount: 1,
    pageIndex: 0,
    showPagination: true,
};

export default Table;
