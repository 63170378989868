import React from "react";

import { Area, ComposedChart, Line, CartesianGrid, ResponsiveContainer } from "recharts";
import { useChartColor, useChartUv, GRID_STROKE } from "./svg-definitions";

const SimpleLineChart = ({ width, height, data, xKey, yKey, hasFill, isHealthy }) => {
    const chartColor = useChartColor(isHealthy);
    const chartUv = hasFill ? useChartUv(isHealthy) : "";

    return (
        <div className="chart-line-simple" style={{ width, height }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={width}
                    height={height}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid stroke={GRID_STROKE} />
                    <Line type="monotone" dataKey={xKey} stroke={chartColor} dot={false} strokeLinecap="round" strokeWidth={1} />
                    <Area type="monotone" dataKey={xKey} stroke="false" strokeWidth={1} fillOpacity={1} fill={chartUv} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

SimpleLineChart.defaultProps = {
    hasFill: false,
    width: "100%",
    height: "100%",
    xKey: "name",
    yKey: "value",
};

export default SimpleLineChart;
