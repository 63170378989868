export const checkSingleMatch = (v1, v2) => {
    // Check values for actual comparison, any poor values default to a match
    const value1 = v1 && v1.hasOwnProperty("value") ? v1.value : typeof v1 === "string" ? v1 : v1 || "";
    const value2 = v2 && v2.hasOwnProperty("value") ? v2.value : typeof v2 === "string" ? v2 : v2 || "";

    return value1 === value2;
};

export const checkMultiMatch = (v1 = [], v2 = []) => {
    // If both are not arrays match
    if (!Array.isArray(v1) && !Array.isArray(v2)) {
        return true;
    }
    // If one is not an array but other is no match
    if (!Array.isArray(v1) || !Array.isArray(v2)) {
        return false;
    }
    // Quick check length
    if (v1.length !== v2.length) {
        return false;
    }

    //Check all matches
    const v2ValueStrings = v2.reduce((arr, v) => {
        const value = v && v.hasOwnProperty("value") ? v.value : v;
        if (typeof value === "string" || typeof value === "number") {
            arr.push(value);
        }
        return arr;
    }, []);
    let hasMistmatch = false;
    v1.forEach((v) => {
        const value = v && v.hasOwnProperty("value") ? v.value : v;
        if (!v2ValueStrings.includes(value)) {
            hasMistmatch = true;
            return false;
        }
    });
    if (hasMistmatch) {
        return false;
    }

    return true;
};
