import React from "react";

import FilterItem from "./components/filter-item";

import { AppContextStore } from "$stores/app-context";
import { useDocumentTitle } from "$utils/document-title";

import "./filter-lists.scss";

const FilterListIndex = () => {
    useDocumentTitle("Saved Filters - Admin");
    const savedFilters = AppContextStore.useState((f) => f.savedFilters || {});

    return (
        <div>
            <h1>
                <em>Favorite</em> Filters
            </h1>

            <div className="filter-list">
                {Object.keys(savedFilters).map((key) => {
                    const filter = savedFilters[key];
                    return <FilterItem key={key} id={key} name={filter.text} filters={filter.filters} />;
                })}
            </div>
        </div>
    );
};

export default FilterListIndex;
