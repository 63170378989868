import React from "react";
import { PieChart, Pie, Cell, Tooltip, XAxis, YAxis } from "recharts";
import { CHART_UNHEALTHY_COLORS } from "../lib/constants";
import { LabelValueTooltip } from "../tooltips/label-value";

import "./semi-circle-pie.scss";

const SemiCirclePie = ({ data, colors, innerRadius, outerRadius, startAngle, endAngle, showTooltip, totalValue, TooltipComponent }) => {
    return (
        <div className="semi-circle-pie">
            <PieChart width={outerRadius * 2 + 2} height={outerRadius + 2} margin={{ top: 1, left: 1, right: 1, bottom: 1 }}>
                {showTooltip && <Tooltip content={<TooltipComponent />} />}
                <Pie
                    data={data}
                    nameKey={"text"}
                    dataKey="value"
                    cx={outerRadius}
                    cy={outerRadius}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    paddingAngle={0}
                    blendStroke="#82ca9d"
                >
                    {data.map((entry, index) => {
                        return <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />;
                    })}
                </Pie>
            </PieChart>
            {totalValue && <h2>{totalValue}</h2>}
        </div>
    );
};

SemiCirclePie.defaultProps = {
    colors: CHART_UNHEALTHY_COLORS,
    outerRadius: 106,
    innerRadius: 80,
    startAngle: 180,
    endAngle: 0,
    TooltipComponent: LabelValueTooltip,
    totalValue: null,
    showTooltip: true,
};

export default SemiCirclePie;
