import React, { useState } from "react";

import RevenueChart from "./chart";
import ToggleSwitch from "$components/toggle-switch";

import { ClientDetailsStore } from "$stores/client-details";
import { TOOLTIP_12_MONTH_REVENUE } from "$utils/tooltips";

import "./twelve-month-revenue.scss";

const toggleOptions = [
    { label: "Service Line", value: 0 },
    { label: "Like Clients", value: 1 },
];

const TwelveMonthRevenue = ({ clientId }) => {
    const chartData = ClientDetailsStore.useState((s) => s.clientDetailsCharts);
    const [tab, setTab] = useState(0);

    const tabChange = (value, index) => {
        setTab(index);
    };

    const highlightCurrentClient = (line) => {
        return line.key.toString() === clientId.toString() ? 4 : 1;
    };

    return (
        <div>
            <div className="flex-header">
                <h3>
                    <span data-tooltip={TOOLTIP_12_MONTH_REVENUE}>12-Month Revenue</span> By Service Line {chartData?.likeClientData && <>&amp; Comparison To Like Clients</>}
                </h3>
                {chartData?.likeClientData && <ToggleSwitch options={toggleOptions} updateField={tabChange} selectedIndex={tab} />}
            </div>
            <div className="twelve-month-reveue">
                {tab === 0 && chartData.serviceLineData && <RevenueChart data={chartData.serviceLineData} />}
                {tab === 1 && chartData.likeClientData && <RevenueChart data={chartData.likeClientData} highlightFn={highlightCurrentClient} />}
            </div>
        </div>
    );
};

export default TwelveMonthRevenue;
