import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ canPreviousPage, canNextPage, pageIndex, pageSize, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize }) => {
    return (
        <div className="pagination">
            {pageIndex > 0 && (
                <div className="previous" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
            )}
            <div className="page-of">
                <span>
                    <strong>
                        {pageIndex + 1} of {pageOptions.length || 1}
                    </strong>{" "}
                </span>
            </div>
            {pageIndex + 1 < pageOptions.length && (
                <div
                    className="next"
                    onClick={() => {
                        nextPage();
                    }}
                    disabled={!canNextPage}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            )}
        </div>
    );
};

export default Pagination;
