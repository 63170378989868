import React from "react";

import ExternalLegend from "$components/charts/legend/external";
import ChartScatter from "$components/charts/scatter";
import HealthContent from "./components/subclients";
import ChartPie from "$components/charts/pie";
import Top5 from "$components/top5";

import { TOP5_SUBCLIENT_TITLES } from "./lib/constants";
import { ClientDetailsStore } from "$stores/client-details";
import { SVIScoreRevenueLabelTooltip } from "$components/charts/tooltips/svi-score-revenue-label";
import { ChartsStore } from "$stores/charts";
import { limitChartData } from "./lib/chart-helper";
import { setSelectedSubclient } from "$stores/subclients";

const ClientSubClients = () => {
    const { chartScoreSviReveune, subClients, totalPercentOfRevenue } = ClientDetailsStore.useState((s) => {
        return { subClients: s.subClients, totalPercentOfRevenue: s.totalPercentOfRevenue, chartScoreSviReveune: s.chartScoreSviReveune };
    });
    const top5 = ClientDetailsStore.useState((s) => s.top5SubClients);
    const { t12TotalRevenueByClient, growthByClient } = ChartsStore.useState((c) => {
        return {
            t12TotalRevenueByClient: limitChartData(c.charts.t12TotalRevenueByClient, 9),
            growthByClient: limitChartData(c.charts.growthByClient, 9),
        };
    });

    const handleClick = (item) => {
        if (item.id) {
            window.location.hash = `#client_${item.id}`;
            setSelectedSubclient(item.id);
        }
    };

    return (
        <div>
            <div className="flex-header">
                <h2 className="txt -uppercase -no-margin -tablet-center">
                    <em>Sub</em> <span>Clients</span>
                </h2>
            </div>
            <div className="dashboard-cards">
                <div className="card -span2 -center">
                    <h4 className="txt -center -white">12-Month Revenue Sub Client</h4>
                    <div>
                        <ChartPie data={t12TotalRevenueByClient} innerRadius={30} outerRadius={98} />
                        <br />
                        <ExternalLegend data={t12TotalRevenueByClient} />
                    </div>
                </div>
                <div className="card -span2 -center">
                    <h4 className="txt -center -white">Growth Potential Sub Client</h4>
                    <div>
                        <ChartPie data={growthByClient} innerRadius={30} outerRadius={98} />
                        <br />
                        <ExternalLegend data={growthByClient} />
                    </div>
                </div>
            </div>
            <br />
            <div className="dashboard-cards">
                <div className="card -header">
                    <h2>
                        <em>Top</em> Sub Clients
                    </h2>
                </div>
                {Object.keys(TOP5_SUBCLIENT_TITLES).map((key) => {
                    return (
                        <div key={key} className="card -span2">
                            {TOP5_SUBCLIENT_TITLES[key]?.header}
                            <Top5 data={top5[key]} type={TOP5_SUBCLIENT_TITLES[key]?.type} />
                        </div>
                    );
                })}
            </div>
            <br />
            <br />
            <h3>Health Score vs Strategic Importance</h3>
            <div className="chart-scatter">
                <ChartScatter
                    data={chartScoreSviReveune}
                    yRange={[0, 10]}
                    xRange={[100, 700]}
                    dataKeyX="healthScore"
                    dataKeyY="svi"
                    dataKeyZ="revenue"
                    TooltipComponent={SVIScoreRevenueLabelTooltip}
                    handleClick={handleClick}
                />
            </div>
            <div></div>

            <HealthContent subClients={subClients} totalPercentOfRevenue={totalPercentOfRevenue} />
        </div>
    );
};

export default ClientSubClients;
