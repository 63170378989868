import React from "react";

import GenericInfoCell from "./cells/generic-info-cell";

import { getColumnInfo } from "./lib/helpers";
import { buildObjectTemplateReplace } from "$utils/template-replace";

import "./info-table.scss";

const InfoCell = ({ Component = GenericInfoCell, label, value, style = {}, additionalProps }) => {
    return (
        <div className="info-cell" style={style}>
            <Component label={label} value={value} {...additionalProps} />
        </div>
    );
};

const InfoTable = ({ colCount, data, dataMap }) => {
    const total = Object.keys(dataMap).length;
    var currentColumn = 0;
    const cells = Object.keys(dataMap).map((key, index) => {
        const columnInfo = getColumnInfo(currentColumn, dataMap[key].colSpan, colCount, index === total - 1);
        currentColumn = columnInfo.nextColumn;
        const mappedProps = buildObjectTemplateReplace(dataMap[key].additionalProps, data);
        return <InfoCell key={key} label={dataMap[key].label} Component={dataMap[key].Component} value={data[key]} style={columnInfo.style} additionalProps={mappedProps} />;
    });
    return (
        <div className="info-table" style={{ gridTemplateColumns: `repeat(${colCount}, 1fr)` }}>
            {cells}
        </div>
    );
};

InfoTable.defaultProps = {
    colCount: 1,
};

export default InfoTable;
