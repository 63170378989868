import React from "react";
import { PieChart, Pie, Cell, Sector, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { CHART_PIE_COLORS } from "../lib/constants";
import { LabelCurrencyTooltip } from "../tooltips/currency";
import { LabelValueTooltip } from "../tooltips/label-value";

import EngineLoader from "$components/loaders/engine-loader";

import "./pie.scss";
import NotificationBar from "$components/notification-bar";
import NoData from "../no-content";

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
            <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const ChartPie = ({ data, colors, innerRadius, outerRadius, data2, innerRadius2, outerRadius2, data3, innerRadius3, outerRadius3, data4, innerRadius4, outerRadius4, totalValue, TooltipComponent }) => {
    const onPieEnter = (_, index) => {
        //this.setState({
        //    activeIndex: index,
        //});
    };

    if (!data) {
        return <EngineLoader />;
    }
    if (data.length === 0) {
        return <NoData />;
    }

    const allNegatives = data.every((v) => v.value <= 0);

    if (allNegatives) {
        return (
            <>
                <NotificationBar message="Can not display chart due to all negative numbers" type="error" />
            </>
        );
    }
    const largestDiameter = Math.max(data ? outerRadius : 0, data2 ? outerRadius2 : 0, data3 ? outerRadius3 : 0, data4 ? outerRadius4 : 0) * 2 + 50;

    return (
        <div className="chart-pie" style={{ width: largestDiameter + "px", height: largestDiameter + "px" }}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={largestDiameter} height={largestDiameter}>
                    <Tooltip content={<TooltipComponent />} />
                    {data && (
                        <Pie data={data} cx="50%" cy="50%" innerRadius={innerRadius} outerRadius={outerRadius} fill="#8884d8" nameKey={"text"} dataKey="value" onMouseEnter={onPieEnter}>
                            {data.map((entry, index) => {
                                return <Cell key={`cell-${index}`} fill={entry.color || colors[index % colors.length]} />;
                            })}
                        </Pie>
                    )}
                    {data2 && (
                        <Pie data={data2} cx="50%" cy="50%" innerRadius={innerRadius2} outerRadius={outerRadius2} fill="#8884d8" nameKey={"text"} dataKey="value" onMouseEnter={onPieEnter}>
                            {data2.map((entry, index) => {
                                return <Cell key={`cell-${index}`} fill={entry.color || colors[index % colors.length]} />;
                            })}
                        </Pie>
                    )}
                    {data3 && (
                        <Pie data={data3} cx="50%" cy="50%" innerRadius={innerRadius3} outerRadius={outerRadius3} fill="#8884d8" nameKey={"text"} dataKey="value" onMouseEnter={onPieEnter}>
                            {data3.map((entry, index) => {
                                return <Cell key={`cell-${index}`} fill={entry.color || colors[index % colors.length]} />;
                            })}
                        </Pie>
                    )}
                    {data4 && (
                        <Pie data={data4} cx="50%" cy="50%" innerRadius={innerRadius4} outerRadius={outerRadius4} fill="#8884d8" nameKey={"text"} dataKey="value" onMouseEnter={onPieEnter}>
                            {data3.map((entry, index) => {
                                return <Cell key={`cell-${index}`} fill={entry.color || colors[index % colors.length]} />;
                            })}
                        </Pie>
                    )}
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

ChartPie.defaultProps = {
    data: null,
    colors: CHART_PIE_COLORS,
    innerRadius: 40,
    outerRadius: 60,
    innerRadius2: 70,
    outerRadius2: 90,
    innerRadius3: 100,
    outerRadius3: 120,
    innerRadius4: 120,
    outerRadius4: 140,
    TooltipComponent: LabelCurrencyTooltip,
    totalValue: null,
};

export default ChartPie;
