import React from "react";
import LargeNumber from "$components/info-list/content/large-number";
import ManagerBox from "$components/filter-stats/components/manager-box";
import ScoreBubble from "$components/score-bubble";
import ChartPie from "$components/charts/pie";
import ExternalLegend from "$components/charts/legend/external";
import BarComparison from "$components/charts/bar-comparison";
import TemperatureBar from "$components/charts/temperature-bar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import { calculateScoreMark, checkIsHealthy, getHealthColorClass } from "$utils/health-helper";
import { useDocumentTitle } from "$utils/document-title";
import { abbrNum, getNumberUnitPlace } from "$utils/number-helper";
import { ChartsStore } from "$stores/charts";
import { ClientDetailsStore } from "$stores/client-details";
import { dealProbabilityText } from "$utils/probability-text";

import "./dashboard.scss";
import classNames from "classnames";
import { aggregateChildren, limitChartData } from "./lib/chart-helper";

const ClientDashboard = () => {
    useDocumentTitle("Client Dashboard");
    const { hasSubClients, clientDetails } = ClientDetailsStore.useState((s) => {
        return { hasSubClients: s.hasSubClients, clientDetails: s.clientDetails };
    });

    const twelveMonthRevenueChangeObj = getNumberUnitPlace(clientDetails?.twelveMonthRevenueChange);
    const growthPotentialChangeObj = getNumberUnitPlace(clientDetails?.growthPotentialChange);
    const { t12TotalRevenueByClient, t12TotalRevenueByIndustry, t12TotalRevenueByServiceLine, t12TotalRevenueByProjectType, growthByProjectType, growthByIndustry, growthByServiceLine } = ChartsStore.useState((s) => {
        return {
            t12TotalRevenueByClient: limitChartData(s.charts["t12TotalRevenueByClient"], 4),
            t12TotalRevenueByIndustry: limitChartData(s.charts["t12TotalRevenueByIndustry"], 4),
            t12TotalRevenueByServiceLine: limitChartData(s.charts["t12TotalRevenueByServiceLine"], 4),
            t12TotalRevenueByProjectType: limitChartData(s.charts["t12TotalRevenueByProjectType"], 4),
            growthByProjectType: limitChartData(s.charts["growthByProjectType"], 9),
            growthByServiceLine: limitChartData(s.charts["growthByServiceLine"], 9),
            growthByIndustry: limitChartData(s.charts["growthByIndustry"], 9),
        };
    });

    return (
        <div className="dashboard-cards">
            <div className="card -crm">
                <ManagerBox imageUrl="/images/icon-ai.png" name={clientDetails?.crmFullName} title="Customer Relations Manager" />
            </div>

            <div className="card -office">
                <div className="icon">
                    <FontAwesomeIcon icon={faLandmark} />
                </div>
                <h4>Primary Office</h4>

                <h3>{clientDetails?.primaryOfficeName}</h3>
            </div>

            <div className="card -office">
                <div className="txt-left">
                    <h4>Service Line</h4>
                    <h3>{(clientDetails?.practices || []).map((p) => p.text).join(", ")}</h3>
                    <h4>Industry</h4>
                    <h3>{(clientDetails?.industries || []).map((i) => i.text).join(", ")}</h3>
                </div>
            </div>

            <div className="card -office" style={{ textAlign: "left" }}>
                <TemperatureBar percent={clientDetails?.sviScore * 10} hasDot={true} isHealthy={false} cssClass={["-importance"]}>
                    <span>Strategic Importance: </span>
                    <span className="txt -importance -bold">{dealProbabilityText(clientDetails?.sviScore * 10)}</span>
                </TemperatureBar>
                <br />
                <br />
                <TemperatureBar percent={(clientDetails?.healthScore / 700) * 100} hasDot={true} isHealthy={checkIsHealthy(clientDetails?.healthScore)}>
                    <span>Health Score: </span>
                    <span className={classNames("txt -bold", getHealthColorClass(clientDetails?.healthScore))}>{calculateScoreMark(clientDetails?.healthScore).label}</span>
                </TemperatureBar>
            </div>

            <div className="card -header">
                <h2>
                    <em>12-Month</em> Revenue
                </h2>
                <LargeNumber value={`$${abbrNum(clientDetails?.twelveMonthRevenue)}`} isHealthy={true} />
                <div className="bubbles">
                    <ScoreBubble arrow={true} postSup={twelveMonthRevenueChangeObj.unitPlace} value={abbrNum(clientDetails?.twelveMonthRevenueChange, false)} modifierClassNames={["-small"]} />
                    <ScoreBubble arrow={true} postSup="%" value={abbrNum(clientDetails?.twelveMonthRevenueChangePercent, false)} modifierClassNames={["-small"]} />
                </div>
            </div>
            <div className="card">
                <h4 className="txt -center -white">12-Month Revenue By Sub Client</h4>
                <div className="chart-legend">
                    <BarComparison data={t12TotalRevenueByClient} />
                </div>
            </div>
            <div className="card">
                <h4 className="txt -center -white">12-Month Revenue By Service Line</h4>
                <div className="chart-legend">
                    <BarComparison data={t12TotalRevenueByServiceLine} />
                </div>
            </div>
            <div className="card">
                <h4 className="txt -center -white">12-Month Revenue By Industry</h4>
                <div className="chart-legend">
                    <BarComparison data={t12TotalRevenueByIndustry} />
                </div>
            </div>
            <div className="card">
                <h4 className="txt -center -white">12-Month Revenue By Project Types</h4>
                <div className="chart-legend">
                    <BarComparison data={t12TotalRevenueByProjectType} />
                </div>
            </div>

            <div className="card -header">
                <h2>
                    <em>Growth</em> Potential
                </h2>
                <LargeNumber value={`$${abbrNum(clientDetails?.growthPotential)}`} />
                <div className="bubbles">
                    <ScoreBubble arrow={true} postSup={growthPotentialChangeObj.unitPlace} value={abbrNum(clientDetails?.growthPotentialChange, false)} modifierClassNames={["-small"]} />
                    <ScoreBubble arrow={true} postSup="%" value={abbrNum(clientDetails?.growthPotentialChangePercent, false)} modifierClassNames={["-small"]} />
                </div>
            </div>
            {hasSubClients && (
                <>
                    {growthByIndustry && (
                        <div className="card -span2">
                            <h4 className="txt -center -white">Growth Potential By Industry & Sub Client</h4>
                            <div className="chart-legend">
                                <ChartPie data={growthByIndustry} outerRadius={105} innerRadius={50} data2={aggregateChildren(growthByIndustry)} outerRadius2={170} innerRadius2={115} />
                                <ExternalLegend data={growthByIndustry} />
                            </div>
                        </div>
                    )}
                    {growthByServiceLine && (
                        <div className="card -span2">
                            <h4 className="txt -center -white">Growth Potential By Service Line & Project Type</h4>
                            <div className="chart-legend">
                                <ChartPie data={growthByServiceLine} outerRadius={105} innerRadius={50} data2={aggregateChildren(growthByServiceLine)} outerRadius2={170} innerRadius2={115} />
                                <ExternalLegend data={growthByServiceLine} />
                            </div>
                        </div>
                    )}
                </>
            )}
            {!hasSubClients && (
                <>
                    <div className="card -span2">
                        <h4 className="txt -center -white">Growth Potential By Service Line</h4>
                        <div className="chart-legend">
                            <ChartPie data={growthByServiceLine} outerRadius={105} innerRadius={50} />
                            <ExternalLegend data={growthByServiceLine} />
                        </div>
                    </div>

                    <div className="card -span2">
                        <h4 className="txt -center -white">Growth Potential By Project Type</h4>
                        <div className="chart-legend">
                            <ChartPie data={growthByProjectType} outerRadius={105} innerRadius={50} />
                            <ExternalLegend data={growthByProjectType} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ClientDashboard;
