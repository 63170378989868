import React from "react";

import CollapsingPills from "$components/table/cells/collapsing-pills";
import Flags from "$components/flags";
import LargeNumberRange from "$components/table/cells/large-number-range";
import LogoCell from "$components/table/cells/logo";
import PredefinedNumberRange from "$components/table/cells/predefined-number-range";
import ProbabilityBubble from "$components/probability";
import { DefaultFilterForColumn, MultiRangeFilter, SelectColumnFilter } from "$components/table/components/filters/filter";

export const defaultColumns = [
    {
        Header: "Project Type",
        accessor: "name", // accessor is the "key" in the data
        id: "name", // id is used as a sort key (i.e., actual api name)
        width: 120,
        Filter: DefaultFilterForColumn,
        Cell: (data) => (
            <div>
                <Flags data={[{ classnames: { "-independence": data.row?.original?.requiresIndependence }, label: "Check Independence" }]} />
                {data.value}
            </div>
        ),
    },
    {
        Header: "Client",
        accessor: "clientGroupName",
        id: "clientGroupName",
        Cell: (data) => <LogoCell data={data} />,
    },
    {
        Header: "Sub Client",
        accessor: "clientName",
        id: "clientName",
        className: "",
    },
    {
        Header: "Service Line",
        accessor: "serviceLine",
        width: 120,
        Filter: SelectColumnFilter,
        disableSortBy: true,
        Cell: (data) => {
            const newData = { value: [{ id: data.value, text: data.value }] };
            return <CollapsingPills data={newData} maxVisible={2} keyPrefix="serviceLine" />;
        },
    },
    {
        Header: "Client Size",
        accessor: "clientSize",
        id: "clientSize",
        sortDescFirst: true,
        Filter: MultiRangeFilter,
        filters: {
            min: 0,
            max: 1000000,
            format: { isCurrency: true },
        },
        Cell: (data) => {
            return <PredefinedNumberRange value={data.value} isHealthy={true} />;
        },
    },
    {
        Header: "Average Revenue",
        accessor: "averageRevenue",
        id: "averageRevenue",
        sortDescFirst: true,
        Filter: MultiRangeFilter,
        filters: {
            min: 0,
            max: 50000000,
            format: { isCurrency: true },
        },
        Cell: (data) => {
            return <LargeNumberRange value={data.value} />;
        },
    },
    {
        Header: "Probability",
        accessor: "probability",
        id: "probability",
        sortDescFirst: true,
        width: 100,
        Cell: (data) => <ProbabilityBubble value={data.value} />,
    },
];
