import React, { useState } from "react";

import Comments from "$components/comments";
import Feedback from "$components/feedback";
import FeedbackUndo from "$components/feedback/feedback-undo";
import InfoTable from "$components/info-table";
import LargeNumberRange from "$components/table/cells/large-number-range";
import LinkInfoCell from "$components/info-table/cells/link-info-cell";
import Modal from "$components/modal";
import NotificationBar from "$components/notification-bar";
import PotentialProjects from "../components/potential-projects";
import ProbabilityBubble from "$components/probability";
import SalesforceButton from "$components/button/salesforce-export";
import SalesforceOpportunityForm from "$components/salesforce/form";
import TeamInfoCell from "$components/info-table/cells/team-info-cell";

import { fetchProject, ProjectDetailsStore } from "$stores/projectDetails";
import { TOOLTIP_AVERAGE_PROJECT_REVENUE, TOOLTIP_TOP_POTENTIAL_PROJECTS, TOOLTIP_EXPANSION_PROBABILITY, TOOLTIP_EXPERIENCED_TEAM } from "$utils/tooltips";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { useDocumentTitle } from "$utils/document-title";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const infoPropertiesMap = {
    clientName: { label: "Client Name", Component: LinkInfoCell, additionalProps: { url: "/client/#{clientId}" }, colSpan: 2 },
    subClientName: { label: "Sub Client", colSpan: 2 },
    clientPIC: { label: "Client PIC", colSpan: 2 },
    serviceLine: { label: "Service Line", colSpan: 2 },
    industry: { label: "Industry", colSpan: 4 },
    averageRevenue: {
        label: <span data-tooltip={TOOLTIP_AVERAGE_PROJECT_REVENUE}>Average Revenue Size</span>,
        Component: LargeNumberRange,
        colSpan: 2,
    },
    probability: { label: <span data-tooltip={TOOLTIP_EXPANSION_PROBABILITY}>Deal Probability</span>, Component: ProbabilityBubble, colSpan: 2 },
    team: { label: <span data-tooltip={TOOLTIP_EXPERIENCED_TEAM}>Experienced Team Members</span>, Component: TeamInfoCell, colSpan: 2, additionalProps: { crmName: "#{crmName}", crmEmail: "#{crmEmail}" } },
};

const PotentialProject = () => {
    useDocumentTitle("Potential Project");
    const appInsights = useAppInsightsContext();
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showSalesforceModal, setShowSalesforceModal] = useState(false);
    const { comparableProjects, currentProject } = ProjectDetailsStore.useState((s) => {
        return { comparableProjects: s.comparables, currentProject: s.projectDetails };
    });

    const triggerModal = (type, action = false) => {
        if (type === "salesforce") {
            setShowSalesforceModal(action);
        }
        if (type === "feedback") {
            setShowFeedbackModal(action);
        }

        appInsights.trackEvent(
            { name: "Modal" },
            {
                label: type.toUpperCase(),
                action: action ? "open" : "close",
            }
        );
    };

    const resetProject = () => {
        fetchProject(currentProject.projectId, "potential");
    };

    const onSalesforceSubmit = () => {
        triggerModal("salesforce", false);
        resetProject();
    };

    return (
        <div id="potential-project" className="main-wrapper">
            <div className="page-content -shadow">
                <div className="content">
                    <div className="flex-header">
                        <h1 className="txt -uppercase -no-margin -tablet-center" style={{ position: "relative" }}>
                            <em>Potential:</em> {currentProject.name}
                        </h1>
                        <div className="project-links">
                            <SalesforceButton
                                disabled={currentProject.hasSalesforceOpportunityCreated}
                                onClick={() => {
                                    triggerModal("salesforce", true);
                                }}
                            />
                            {!currentProject.userFlagged && (
                                <button
                                    className="button -text"
                                    onClick={() => {
                                        triggerModal("feedback", true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFlag} />
                                </button>
                            )}
                        </div>
                    </div>
                    {currentProject.userFlagged && (
                        <NotificationBar type="warning">
                            <FeedbackUndo id={currentProject.projectId} projectKey={currentProject.projectKey} onSubmit={resetProject} />
                        </NotificationBar>
                    )}
                    {currentProject.requiresIndependence && <NotificationBar message="Check Independence." type="warning" />}
                    <InfoTable data={currentProject} dataMap={infoPropertiesMap} colCount={6} />

                    <div className="bg -dark">
                        <Comments projectKey={currentProject.projectKey} />
                    </div>

                    <Modal
                        show={showSalesforceModal}
                        onClose={() => {
                            triggerModal("salesforce", false);
                        }}
                    >
                        <SalesforceOpportunityForm clientId={currentProject.subClientId} currentProject={currentProject} projectKey={currentProject.projectKey} afterSubmit={onSalesforceSubmit} />
                    </Modal>

                    <Modal
                        show={showFeedbackModal}
                        onClose={() => {
                            triggerModal("feedback", false);
                        }}
                    >
                        <Feedback
                            id={currentProject.projectId}
                            projectKey={currentProject.projectKey}
                            onSubmit={() => {
                                triggerModal("feedback", false);
                                resetProject();
                            }}
                        />
                    </Modal>

                    {comparableProjects && comparableProjects.length > 0 && (
                        <>
                            <h3>
                                Similar <span data-tooltip={TOOLTIP_TOP_POTENTIAL_PROJECTS}>Potential Projects</span>
                            </h3>

                            <div className="similar-projects">
                                <PotentialProjects data={comparableProjects} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PotentialProject;
