import React, { useEffect, useState } from "react";

import EngineLoader from "$components/loaders/engine-loader";
import NoData from "../no-content";

import { abbrNum, formatUIValue } from "$utils/number-helper";
import { CHART_DEFAULT_COLORS } from "../lib/constants";

import "./bar-comparison.scss";

export const Bar = ({ text, value, width, color }) => {
    return (
        <div className="bar-container">
            <div>
                <h4>{text}</h4>
                <div className="bar-bg">
                    <div className="bar" style={{ width: `${width}%`, backgroundColor: color }}></div>
                </div>
            </div>
            {value && (
                <div>
                    <sup>$</sup>
                    {formatUIValue(abbrNum(value))}
                </div>
            )}
        </div>
    );
};

const BarComparison = ({ colors, data }) => {
    const [widths, setWidths] = useState([0, 0]);

    useEffect(() => {
        if (data) {
            const total = data.reduce((num, d) => {
                num = num + d.value;
                return num;
            }, 0);

            setWidths(
                data.reduce((arr, d) => {
                    arr.push((d.value / total) * 100);
                    return arr;
                }, [])
            );
        }
    }, [data]);

    if (!data) {
        return <EngineLoader />;
    }
    if (data.length === 0) {
        return <NoData />;
    }

    return (
        <div className="bar-comparison">
            {data.map((d, i) => {
                return <Bar key={`bar_${i}`} width={widths[i]} {...d} color={d.color || colors[i]} />;
            })}
        </div>
    );
};

BarComparison.defaultProps = {
    colors: CHART_DEFAULT_COLORS,
    limit: 4,
};

export default BarComparison;
