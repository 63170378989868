import React from "react";

import { scrollToName } from "$utils/scroll-to";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const HelpNavigation = () => {
    const appInsights = useAppInsightsContext();

    const handleClick = (e) => {
        e.preventDefault();
        const name = e.currentTarget.getAttribute("href");
        scrollToName(name.replace("#", ""));

        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Help Menu",
                section: "Potential Project Info",
            }
        );
    };

    return (
        <div className="docs-navigation">
            <ul>
                <li>
                    <a href="#overview" onClick={handleClick}>
                        Overview of Orgaimi
                    </a>
                </li>
                <li>
                    <a href="#use-cases" onClick={handleClick}>
                        Primary Use Cases
                    </a>
                </li>
                <li>
                    <a href="#potential-projects-core" onClick={handleClick}>
                        Potential Projects: Core Concepts
                    </a>
                </li>
                <li>
                    <a href="#client-health-scores-core" onClick={handleClick}>
                        Client Health Scores: Core Concepts
                    </a>
                </li>
                <li>
                    <a href="#sitemap" onClick={handleClick}>
                        Sitemap
                    </a>
                    <ul>
                        <li>
                            <a href="#login" onClick={handleClick}>
                                Getting Logged In
                            </a>
                        </li>
                        <li>
                            <a href="#navigation" onClick={handleClick}>
                                Navigation
                            </a>
                        </li>
                        <li>
                            <a href="#dashboard" onClick={handleClick}>
                                Dashboard
                            </a>
                        </li>
                        <li>
                            <a href="#growth-potential-panel" onClick={handleClick}>
                                Growth Potential Panel
                            </a>
                        </li>
                        {/*<li>
                            <a href="#segments" onClick={handleClick}>
                                Segments
                            </a>
                        </li>*/}
                        <li>
                            <a href="#offices-map" onClick={handleClick}>
                                Offices Map
                            </a>
                        </li>
                        <li>
                            <a href="#office-details" onClick={handleClick}>
                                Office Details
                            </a>
                        </li>
                        <li>
                            <a href="#client-list" onClick={handleClick}>
                                Client List
                            </a>
                        </li>
                        <li>
                            <a href="#client-page" onClick={handleClick}>
                                Client Page
                            </a>
                            <ul>
                                <li>
                                    <a href="#potential-projects" onClick={handleClick}>
                                        Potential Projects
                                    </a>
                                </li>
                                <li>
                                    <a href="#recommendations" onClick={handleClick}>
                                        Recommendations
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-details" onClick={handleClick}>
                                        Client Details
                                    </a>
                                </li>
                                <li>
                                    <a href="#scores" onClick={handleClick}>
                                        Scores
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#potential-project-list" onClick={handleClick}>
                                Potential Project List
                            </a>
                        </li>
                        <li>
                            <a href="#existing-project-list" onClick={handleClick}>
                                Existing Project List
                            </a>
                        </li>
                        <li>
                            <a href="#potential-project-detail" onClick={handleClick}>
                                Potential Project Detail
                            </a>
                        </li>
                        <li>
                            <a href="#existing-project-detail" onClick={handleClick}>
                                Existing Project Detail
                            </a>
                        </li>
                        <li>
                            <a href="#overview-panel" onClick={handleClick}>
                                Overview Panel
                            </a>
                        </li>
                        <li>
                            <a href="#filters" onClick={handleClick}>
                                Filters
                            </a>
                        </li>
                        {/*<li>
                            <a href="#share-modal" onClick={handleClick}>
                                Share Modal
                            </a>
                        </li>*/}
                        <li>
                            <a href="#key-terms" onClick={handleClick}>
                                Key Terms
                            </a>
                        </li>
                        <li>
                            <a href="#key-contacts" onClick={handleClick}>
                                Key Contacts
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default HelpNavigation;
