import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import Button from "../../button";
import SelectItem from "./components/multi-select-item";

import { checkMultiMatch } from "$utils/check-match";
import { KEYCODE } from "$utils/keycodes";
import { extractValue } from "../libs/helpers";

import "./select.scss";

const MultiSelect = ({ label, name, value, options, updateOnChange, cssClasses }) => {
    const appInsights = useAppInsightsContext();
    var hideTimer = null;
    const dropdownRef = useRef();
    const [values, setValues] = useState(value);
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);

    useEffect(() => {
        const isMatch = checkMultiMatch(value, values);
        if (!isMatch) {
            setValues(value);
        }
    }, [value]);

    const updateValues = (changeValues, isAdd) => {
        const changeValuesByValues = changeValues.map((v) => extractValue(v));
        var newValues = isAdd ? [...values, ...changeValues] : values.filter((v) => !changeValuesByValues.includes(extractValue(v)));
        setValues(newValues);
        if (updateOnChange) {
            updateOnChange(name, newValues);
        }
    };

    const selectAll = () => {
        const newValues = options.reduce((arr, o) => {
            if (o.options) {
                const subOptions = o.options.map((so) => so.value);
                return [...arr, ...subOptions];
            } else {
                arr.push(o.value);
                return arr;
            }
        }, []);
        setValues(newValues);
        if (updateOnChange) {
            updateOnChange(name, newValues);
        }

        appInsights.trackEvent(
            { name: "Picklist" },
            {
                label: label,
                action: "Select All",
            }
        );
    };

    const deselectAll = () => {
        setValues([]);
        updateOnChange(name, []);

        appInsights.trackEvent(
            { name: "Picklist" },
            {
                label: label,
                action: "Deselect All",
            }
        );
    };

    const mouseEnter = (e) => {
        clearTimeout(hideTimer);
    };

    const mouseLeave = (e) => {
        clearTimeout(hideTimer);
        hideTimer = setTimeout(() => {
            setIsOpen(false);
        }, 400);
    };

    const handleKeyDown = useCallback(
        (event) => {
            switch (event.keyCode) {
                case KEYCODE.TAB:
                    setIsOpen(false);
                    break;
                case KEYCODE.RETURN:
                    // TODO: Double enter doesn't work but down arrow does
                    const option = options[activeIndex];
                    if (option && dropdownRef && dropdownRef.current) {
                        var isSelected = values.some((v) => extractValue(v) === extractValue(option));
                        if (option.options) {
                            option.options.forEach((subItem) => {
                                if (values.some((v) => extractValue(v) === extractValue(subItem))) {
                                    isSelected = true;
                                }
                            });
                        }
                        updateValues(option.options || [option], !isSelected);
                    }
                    break;
                case KEYCODE.ESC:
                    setIsOpen(false);
                    setActiveIndex(-1);
                    break;
                case KEYCODE.UP:
                    setIsOpen(true);
                    if (activeIndex > 0) {
                        setActiveIndex(activeIndex - 1);
                    } else {
                        setActiveIndex(options.length - 1);
                    }
                    break;
                case KEYCODE.DOWN:
                    event.preventDefault();
                    setIsOpen(true);

                    if (activeIndex < options.length - 1) {
                        setActiveIndex(activeIndex + 1);
                    } else {
                        setActiveIndex(0);
                    }
                    break;
                default:
                    break;
            }
        },
        [options, activeIndex]
    );

    var totalOptions = 0;
    const items = options.map((item, index) => {
        const isActiveIndex = activeIndex === index ? true : false;
        const itemCount = item.options ? item.options.length : 1;
        totalOptions = totalOptions + itemCount;
        return <SelectItem key={`multi-select_${item.value}`} value={item.value} isActiveIndex={isActiveIndex} item={item} values={values} updateValues={updateValues} />;
    });

    const selectedText = values.length === totalOptions ? "All" : `${values.length} Items`;
    const icon = isOpen ? faCaretUp : faCaretDown;

    return (
        <div id={`multi-select_${name}`} className={classNames("multi-select", cssClasses)} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onKeyDown={handleKeyDown} tabIndex="0">
            <div
                className="label"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <label>{label}:</label> {selectedText}
                <div className="arrow">
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
            <div ref={dropdownRef} className={classNames("dropdown", { "-open": isOpen })}>
                <ul>{items}</ul>
                <div className="button-group">
                    <Button className="button -small -dark" onClick={selectAll}>
                        Select All
                    </Button>
                    <Button className="button -small -dark" onClick={deselectAll}>
                        Deselect All
                    </Button>
                </div>
            </div>
        </div>
    );
};

MultiSelect.defaultProps = {
    options: [],
    sendFullValue: false,
    value: [],
    cssClasses: [],
};

export default MultiSelect;
