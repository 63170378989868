import React from "react";

const NoData = () => {
    return (
        <div className="no-data">
            <h4 className="txt -center">No Data Available</h4>
        </div>
    );
};

export default NoData;
