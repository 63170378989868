import React, { useEffect, useState } from "react";
import classNames from "classnames";

import RecommendationPanel from "$components/recommendation-panel";

import { ScoreCardStore } from "$stores/scorecard";
import { ClientDetailsStore } from "$stores/client-details";
import { calculateScoreMark, getHealthColorClassByRange } from "$utils/health-helper";
import { TOOLTIP_RECOMMENDATIONS } from "$utils/tooltips";
import { useDocumentTitle } from "$utils/document-title";

const ClientRecommendations = () => {
    useDocumentTitle("Client Recommendations");
    const healthScore = ClientDetailsStore.useState((s) => s.clientDetails?.healthScore);
    const recommendationData = ScoreCardStore.useState((s) => s.recommendations);

    const [scoreInfo, setScoreInfo] = useState({
        healthRange: "",
        healthClass: "",
        tooltip: "",
    });

    useEffect(() => {
        if (healthScore) {
            const scoreMark = calculateScoreMark(healthScore);
            setScoreInfo({
                healthRange: scoreMark.label,
                healthClass: getHealthColorClassByRange(scoreMark.label),
                tooltip: scoreMark.tooltip,
            });
        }
    }, [healthScore]);

    const recommendations = recommendationData.map((d, i) => {
        return <RecommendationPanel key={`recommendation_${i}`} index={i + 1} data={d} />;
    });

    return (
        <div id="recommendations">
            <h2>
                <span data-tooltip={TOOLTIP_RECOMMENDATIONS}>
                    <em>Orgaimi</em> Recommendations
                </span>
            </h2>
            <p>
                Your client is in the{" "}
                <span className={classNames("txt -uppercase -bold", scoreInfo.healthClass)} data-tooltip={scoreInfo.tooltip}>
                    {scoreInfo.healthRange}
                </span>{" "}
                range of health scores. To improve your health score, consider the following trends:
            </p>
            {recommendations}
            {recommendations.length === 0 && (
                <>
                    <br />
                    <br />
                    <h4 className="txt -center">No Recommendations Available</h4>
                </>
            )}
        </div>
    );
};

export default ClientRecommendations;
