import React from "react";
import { Link } from "react-router-dom";

const AdminNavigation = () => {
    return (
        <div className="side-navigation">
            <ul>
                <li>
                    <Link to="/admin">Dashboard Control</Link>
                </li>
                <li>
                    <Link to="/admin/filter-lists">Filter Lists</Link>
                </li>
            </ul>
        </div>
    );
};

export default AdminNavigation;
