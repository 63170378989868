import { useEffect, useState } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

import { abbrNum } from "$utils/number-helper";
import { LabelCurrencyTooltip } from "../tooltips/currency";
import { GRID_STROKE } from "../svg-definitions";
import { capitalizeFirstLetter } from "$utils/text-helpers";

const StackedBarChart = ({ data, selectedIndex, onSelect, keysToLabel, yDomain }) => {
    const [focusBarIndex, setFocusBarIndex] = useState(-1);

    useEffect(() => {
        if ((selectedIndex || selectedIndex === 0) && selectedIndex != focusBarIndex) {
            setFocusBarIndex(selectedIndex);
        }
    }, [selectedIndex]);

    const handleSelect = (state) => {
        if (data.length <= 1) {
            return;
        }
        const newSelected = !state || isNaN(state.activeTooltipIndex) || state.activeTooltipIndex === focusBarIndex ? -1 : state.activeTooltipIndex;

        setFocusBarIndex(newSelected);
        if (onSelect) {
            onSelect(newSelected);
        }
    };

    return (
        <ResponsiveContainer width="99%">
            <BarChart data={data} stackOffset="sign" onClick={handleSelect} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} barSize={15} barGap={50}>
                <XAxis dataKey="name" angle={55} height={130} textAnchor="start" interval={0} padding={{ right: 50 }} />
                <YAxis tickFormatter={(label) => `$${abbrNum(label)}`} />
                <CartesianGrid stroke={GRID_STROKE} strokeDasharray="3 3" />
                <Tooltip cursor={{ fill: "rgba(28, 54, 66, 0.8)" }} content={LabelCurrencyTooltip} keysToLabel={keysToLabel} />
                <Legend formatter={(value, entry, index) => entry?.payload?.label || capitalizeFirstLetter(value)} />
                <Bar label="Growth" dataKey="growth" stackId="a" fill="#18b2dc">
                    {data.map((entry, index) => (
                        <Cell key={`growth_${index}`} fill={focusBarIndex < 0 || focusBarIndex === index ? "#18b2dc" : "rgba(24, 178, 220, 0.3)"} />
                    ))}
                </Bar>

                <Bar label="Revenue At Risk" dataKey="revenueAtRisk" stackId="a" fill="#fd9332">
                    {data.map((entry, index) => (
                        <Cell key={`churn_${index}`} fill={focusBarIndex < 0 || focusBarIndex === index ? "#fd9332" : "rgba(253, 147, 50, 0.3"} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

StackedBarChart.defaultProps = {
    yDomain: [(dataMin) => 0, (dataMax) => Math.floor(dataMax * 1.5)],
};

export default StackedBarChart;
