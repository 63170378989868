import React, { useState } from "react";
import classnames from "classnames";
import ProfileIcon from "../../../profile-icon";

import "./name.scss";

const FULL_NAME_BREAKPOINT = 0;

const InitialsCell = ({ data }) => {
    const showInitials = data.column.totalWidth <= FULL_NAME_BREAKPOINT;
    const [hover, setHover] = useState(false);
    const onHover = () => {
        if (showInitials) {
            setHover(true);
        }
    };

    const onLeave = () => {
        setHover(false);
    };
    return (
        <div onMouseEnter={onHover} onMouseLeave={onLeave} className={classnames("name-container", { "-hover": hover })}>
            <ProfileIcon name={data.value} modifierClassNames={["-small"]}></ProfileIcon>
            {!showInitials && <p>{data.value}</p>}
            <div className="tooltip">{data.value}</div>
        </div>
    );
};

export default InitialsCell;
