import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppContextStore } from "../stores/app-context";
import { isBoolean } from "../utils/form-helper";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const PanelContext = createContext();
export const PanelProvider = PanelContext.Provider;
export const PanelConsumer = PanelContext.Consumer;

const DEFAULT_PANELS = {
    growthPotential: false,
    filters: false,
    officeDetails: false,
};

const setGPPanelClass = (bool) => {
    if (bool) {
        document.getElementById("root").classList.add("gp-panel-open");
    } else {
        document.getElementById("root").classList.remove("gp-panel-open");
    }
};

export const PanelState = (props) => {
    const appInsights = useAppInsightsContext();
    const location = useLocation();
    const showFilter = AppContextStore.useState((s) => s.showFilter);
    const [panels, setPanels] = useState({
        ...DEFAULT_PANELS,
    });

    useEffect(() => {
        setPanels({ ...DEFAULT_PANELS, growthPotential: location.pathname === "/" });
        setGPPanelClass(location.pathname === "/" || DEFAULT_PANELS.growthPotential);
    }, [location.pathname, showFilter]);

    const togglePanel = (key, value) => {
        const useValue = isBoolean(value) ? value : !panels[key];

        const closePanels = Object.keys(panels).reduce((obj, k) => {
            if (k !== key) {
                obj[key] = false;
            }
            return obj;
        }, {});

        setGPPanelClass(key === "growthPotential" && useValue);

        appInsights.trackEvent(
            { name: "Panels" },
            {
                label: key,
                action: useValue ? "open" : "close",
            }
        );

        return setPanels({
            ...closePanels,
            [key]: useValue,
        });
    };

    return <PanelProvider value={{ panels, togglePanel }}>{props.children}</PanelProvider>;
};
