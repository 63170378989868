import React from "react";

import GenericLegend from "./components/generic-legend";

import { CHART_DEFAULT_COLORS } from "../lib/constants";
import classNames from "classnames";

const ExternalLegend = ({ cssClasses, data, colors, title, Component }) => {
    if (!data) {
        return null;
    }
    return (
        <div className={classNames("external-legend", cssClasses)}>
            {title && <h4>{title}</h4>}
            {data.map((entry, index) => {
                return <Component key={`color-swatch_${index}`} color={entry.color || colors[index]} data={entry} />;
            })}
        </div>
    );
};

ExternalLegend.defaultProps = {
    data: [],
    colors: CHART_DEFAULT_COLORS,
    Component: GenericLegend,
};

export default ExternalLegend;
