import React, { useContext } from "react";
import classnames from "classnames";

import { AppContextStore, bulkUpdateCurrentFilters, updateCurrentFilters } from "$stores/app-context";
import { CrmStore, search } from "$stores/crm-filter";
import { ClientFilterStore, search as clientSearch } from "$stores/client-filter";
import { ProjectPicFilterStore, search as projectPicSearch } from "$stores/project-pic-filter";
import { PanelContext } from "$contexts/panels";

import MultiRangeSliderDropdown from "$components/form-inputs/multi-range-slider/dropdown";
import MultiSelect from "../form-inputs/select/multi";
import SavedFilters from "./components/saved-filters";
import Typeahead from "../form-inputs/typeahead";

import "./filter-menu.scss";

var filterMenuTimeout = null;

const FilterMenu = ({ isActive }) => {
    const { togglePanel } = useContext(PanelContext);
    const filters = AppContextStore.useState((f) => f.filterMenu || {});
    const currentFilters = AppContextStore.useState((f) => f.currentFilters || {});
    const crmOptions = CrmStore.useState((f) => f.results);

    const clientOptions = ClientFilterStore.useState((f) => f.results);

    const projectPicOptions = ProjectPicFilterStore.useState((f) => f.results);

    const closeMenu = () => {
        filterMenuTimeout = setTimeout(() => {
            clearTimeout(filterMenuTimeout);
            togglePanel("filters", false);
        }, 1500);
    };

    return (
        <div
            className={classnames("filter-menu", { "-active": isActive })}
            onMouseLeave={closeMenu}
            onMouseEnter={() => {
                clearTimeout(filterMenuTimeout);
            }}
        >
            <div className="input-group">
                <h2>
                    <em>Client</em> Filters
                </h2>
                <Typeahead
                    name="filterMenuCRM"
                    placeholder="Search..."
                    label="Client"
                    options={clientOptions}
                    value={currentFilters.client}
                    optionLookup={clientSearch}
                    updateOnChange={(name, newValues) => {
                        updateCurrentFilters("client", newValues);
                    }}
                />
                <Typeahead
                    name="filterMenuCRM"
                    placeholder="Search..."
                    label="Client PIC"
                    options={crmOptions}
                    value={currentFilters.crm}
                    optionLookup={search}
                    updateOnChange={(name, newValues) => {
                        updateCurrentFilters("crm", newValues);
                    }}
                />
                <Typeahead
                    name="filterMenuCRM"
                    placeholder="Search..."
                    label="Project PIC"
                    options={projectPicOptions}
                    value={currentFilters.projectPic}
                    optionLookup={projectPicSearch}
                    updateOnChange={(name, newValues) => {
                        updateCurrentFilters("projectPic", newValues);
                    }}
                />
                {filters.map((filterItem) => {
                    return (
                        <MultiSelect
                            key={`filtermenu_${filterItem.key}`}
                            name={filterItem.key}
                            multiple={false}
                            label={filterItem.label}
                            options={[...(filterItem.values || [])]}
                            updateOnChange={((key) => (name, value) => {
                                updateCurrentFilters(key, value);
                            })(filterItem.key)}
                            value={currentFilters[filterItem.key]}
                        />
                    );
                })}
                <MultiRangeSliderDropdown
                    name="filterMenuSVIRange"
                    label="SVI Range"
                    min={1}
                    max={10}
                    defaultValue={{ min: currentFilters["sviRangeMin"], max: currentFilters["sviRangeMax"] }}
                    updateOnChange={(name, newValues) => {
                        bulkUpdateCurrentFilters({
                            sviRangeMin: newValues.min ? newValues.min.toString() : "",
                            sviRangeMax: newValues.max ? newValues.max.toString() : "",
                        });
                    }}
                />
                <SavedFilters />
            </div>
        </div>
    );
};

export default FilterMenu;
