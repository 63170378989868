import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const NotificationBar = (props) => {
    let cssClass = "notification-bar";
    let icon;
    switch (props.type) {
        case "error":
            cssClass += " -error";
            icon = <FontAwesomeIcon icon={faExclamationTriangle} />;
            break;
        case "warning":
            cssClass += " -warning";
            icon = <FontAwesomeIcon icon={faExclamationTriangle} />;
            break;
        case "success":
            cssClass += " -success";
            icon = <FontAwesomeIcon icon={faCheckCircle} />;
            break;
        default:
            cssClass += " -info";
            icon = <FontAwesomeIcon icon={faInfoCircle} />;
    }
    const link = props.link ? (
        <a href={props.link} target="_blank" rel="noopener noreferrer">
            {props.linkText}
        </a>
    ) : null;

    const body = props.children ? (
        props.children
    ) : (
        <strong>
            {props.message} {link}
        </strong>
    );
    return (
        <div className={cssClass}>
            {body}

            <div className="icon">{icon}</div>
        </div>
    );
};

NotificationBar.defaultProps = {
    message: "",
    type: "info",
};

export default NotificationBar;
