import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Breadcrumb from "../../components/breadcrumb";
import ExistingProject from "./existing/detail";
import PotentialProject from "./potential/detail";
import ShadowTable from "../../components/loaders/shadow-table";
import ViewingFor from "../../components/filter-stats/components/viewing-for";

import { fetchOverview } from "../../stores/app-context";
import { ProjectDetailsStore, fetchProject, reset } from "../../stores/projectDetails";
import { handleAbort } from "$services/api";
import { scrollToPos } from "$utils/scroll-to";

import "./project.scss";
import GlobalDashboard from "$components/dashboard";

const ProjectDetail = () => {
    const { projectId, projectType } = useParams();
    const { currentProject, isLoading } = ProjectDetailsStore.useState((s) => {
        return { currentProject: s.projectDetails, isLoading: s.isLoading };
    });

    useEffect(() => {
        scrollToPos(0);

        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        const controllerProject = fetchProject(projectId, projectType);

        return () => {
            handleAbort([controllerProject]);
        };
    }, [projectId, projectType]);

    useEffect(() => {
        const clientId = currentProject?.clientId;
        if (clientId) {
            fetchOverview({ client: [clientId] });
        }
    }, [currentProject]);

    const breadcrumbLinks = [
        { text: "Projects", url: currentProject?.type ? `/projects/${currentProject?.type}` : "/projects" },
        { text: currentProject?.name, url: `/project/${projectId}/${currentProject?.type}` },
    ];

    return (
        <div id="project" className="page">
            <GlobalDashboard>
                <div className="filter-stats">
                    <ViewingFor label={currentProject?.name} hideFilters={true} />
                    {/*<ManagerBox imageUrl="/images/icon-ai.png" name={currentProject?.crmFullName} title="Customer Relations Manager" />*/}
                </div>
            </GlobalDashboard>
            <Breadcrumb links={breadcrumbLinks} />
            <div className="main">
                {isLoading && <ShadowTable />}
                {!isLoading && (
                    <main>
                        {currentProject.type !== "potential" && <ExistingProject />}
                        {currentProject.type === "potential" && <PotentialProject />}
                    </main>
                )}
            </div>
        </div>
    );
};

export default ProjectDetail;
