import React from "react";

import LargeNumber from "$components/info-list/content/large-number";

import { abbrNum } from "$utils/number-helper";

import "./top5.scss";

const Top5 = ({ data = [], title, type }) => {
    return (
        <div className="top5">
            {title && <header>{title}</header>}
            <ul className="top5-items">
                {data.map((item, i) => {
                    const value = type === "currency" ? `$${abbrNum(item.value)}` : abbrNum(item.value);
                    return (
                        <li key={`top5-item_${i}`} data-number={i + 1}>
                            <span>{item.name}</span>
                            <LargeNumber value={value} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Top5;
