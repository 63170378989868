import React from "react";

import LargeNumber from "$components/info-list/content/large-number";

/* CELLS */
import CollapsingPills from "$components/table/cells/collapsing-pills";
import Flags from "$components/flags";
import InitialsCell from "$components/table/cells/initials";
import LogoCell from "$components/table/cells/logo";
import RevenueCell from "./cells/Revenue";
import ScoreBubble from "$components/score-bubble";

import { abbrNum } from "$utils/number-helper";
import { TOOLTIP_GROWTH_POTENTIAL } from "$utils/tooltips";
import { random32 } from "@microsoft/applicationinsights-core-js";
import { checkIsHealthy } from "$utils/health-helper";

export const defaultColumns = [
    {
        Header: "Client",
        accessor: "client", // accessor is the "key" in the data
        id: "name", // id is used as a sort key (i.e., actual api name)
        width: 210,
        className: "-sticky",
        Cell: (data) => (
            <div>
                <Flags data={[{ classnames: { "tooltip--center": true, "-independence": data.row?.original?.requiresIndependence }, label: "Check Independence" }]} />
                <LogoCell data={data} />
            </div>
        ),
    },
    {
        Header: "Client PIC",
        accessor: "crmFullName",
        id: "CrmName",
        width: 100,
        Cell: (data) => <InitialsCell data={data} />,
    },
    {
        Header: "Industry",
        accessor: "industries",
        width: 120,
        disableSortBy: true,
        Cell: (data) => <CollapsingPills data={data} maxVisible={2} keyPrefix="industry" />,
    },
    {
        Header: "Service Line",
        accessor: "practices",
        width: 120,
        disableSortBy: true,
        Cell: (data) => <CollapsingPills data={data} maxVisible={2} keyPrefix="practice" />,
    },
    {
        Header: "Health Score",
        accessor: "healthScore",
        sortDescFirst: true,
        width: 100,
        Cell: (data) => <LargeNumber value={`${data.value}`} isHealthy={checkIsHealthy(data.value)} />,
    },
    {
        Header: "SVI Score",
        accessor: "sviScore",
        sortDescFirst: true,
        width: 75,
        Cell: (data) => {
            return (
                <div style={{ alignSelf: "center" }}>
                    <ScoreBubble value={data.value} percent={(data.value / 10) * 100} postSup={""} graph={true} modifierClassNames={["-small", "-svi"]} />
                </div>
            );
        },
    },
    {
        Header: "12-Month Revenue",
        accessor: "revenueHistoryTotal",
        id: "revenueHistoryTotal",
        disableSortBy: false,
        sortDescFirst: true,
        width: 170,
        Cell: (data) => <RevenueCell data={data} />,
    },
    {
        Header: "Revenue At Risk",
        accessor: "revenueAtRisk",
        id: "revenueAtRisk",
        disableSortBy: false,
        sortDescFirst: true,
        width: 170,
        Cell: (data) => <LargeNumber value={`$${abbrNum(data.value)}`} isHealthy={false} />,
    },
    {
        Header: () => <span data-tooltip={TOOLTIP_GROWTH_POTENTIAL}>Growth Potential</span>,
        accessor: "growthPotential",
        sortDescFirst: true,
        Cell: (data) => {
            return <LargeNumber value={`$${abbrNum(data.value)}`} />;
        },
    },
    {
        Header: "Top Potential",
        accessor: "topProjectAmount",
        id: "topProjectAmount",
        sortDescFirst: true,
        width: 120,
        Cell: (data) => {
            return (
                <div className="top-potential">
                    {data.value > 0 ? (
                        <LargeNumber value={`$${abbrNum(data.value)}`} label={data.row.original.topProjectType} />
                    ) : (
                        <div className="large-number">
                            <h5>No Potential Projects</h5>
                        </div>
                    )}
                </div>
            );
        },
    },
];
