import React from "react";

const GenericInfoCell = ({ value, label }) => {
    return (
        <>
            <h5>{label}</h5>
            <h4>{value || "-"}</h4>
        </>
    );
};

export default GenericInfoCell;
