import React, { useState, useEffect } from "react";
import { uniqBy } from "lodash";
import Typeahead from "../form-inputs/typeahead";

import { EmployeeStore, updateSearchTerm } from "../../stores/employees";

const EmployeeLookup = ({ onUpdate }) => {
    const results = EmployeeStore.useState((s) => s.results);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        setOptions(results);
    }, [results]);

    useEffect(() => {
        onUpdate(selectedOptions);
    }, [selectedOptions, onUpdate]);

    return (
        <div>
            <Typeahead
                name="searchEmployee"
                cssClass="-full"
                options={options}
                value={selectedOptions}
                optionLookup={updateSearchTerm}
                showSelectAndDeselectAll={false}
                updateOnChange={(name, newValues) => {
                    setSelectedOptions(newValues);
                }}
            />
        </div>
    );
};

export default EmployeeLookup;
