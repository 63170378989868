import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import "./toggle-switch.scss";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const ToggleItem = ({ handleClick, index, isSelected, label, value }) => {
    const appInsights = useAppInsightsContext();

    const onClick = (e) => {
        const target = e.currentTarget;
        handleClick({
            value,
            index,
            styles: {
                left: target.offsetLeft,
                width: target.offsetWidth,
            },
        });

        // Track Event
        appInsights.trackEvent(
            { name: "Toggle Switch" },
            {
                label: label,
            }
        );
    };

    return (
        <div className={classNames("item", { "-active": isSelected })} onClick={onClick}>
            {label}
        </div>
    );
};

const ToggleSwitch = ({ options, selectedIndex, updateField, children }) => {
    const $toggleRef = useRef();
    const [selected, setSelected] = useState({
        value: null,
        index: selectedIndex,
        styles: {},
    });

    useEffect(() => {
        if (updateField && selected.index !== selectedIndex) {
            updateField(selected.value, selected.index);
        }
    }, [selected]);

    useEffect(() => {
        if ($toggleRef.current && (!selected.value || selectedIndex != selected.index)) {
            const parent = $toggleRef.current;
            const child = parent.getElementsByClassName("item")[selectedIndex];
            if (child) {
                setSelected({
                    value: options[0].value,
                    index: selectedIndex,
                    styles: {
                        left: child.offsetLeft,
                        width: child.offsetWidth,
                    },
                });
            }
        }
    }, [selectedIndex]);

    const handleClick = (obj) => {
        setSelected(obj);
    };

    const items = children
        ? children
        : options.map((o, i) => {
              return <ToggleItem {...o} key={`option_${o.value}_${i}`} index={i} isSelected={o.value === selected.value} handleClick={handleClick} />;
          });

    return (
        <div ref={$toggleRef} className="toggle-switch">
            {items}
            {selected.styles.width && <div className="selection" style={selected.styles}></div>}
        </div>
    );
};

ToggleSwitch.defaultProps = {
    selectedIndex: 0,
    options: [],
    updateField: () => {},
};

export default ToggleSwitch;
