import React from "react";

import ThemeScore from "./theme-score";

import { ScoreCardStore } from "$stores/scorecard";

import "./theme-score.scss";

const ThemesScores = ({ hasSubClients }) => {
    const topClients = ScoreCardStore.useState((s) => s.topClients);

    if (!topClients || topClients.length <= 0) {
        return null;
    }

    return (
        <div>
            <h3>{hasSubClients && <em>Sub Client </em>}Themes & Scores</h3>
            <div className="subclient-themes">
                {topClients.map((subClient, i) => {
                    return <ThemeScore key={`subclient_themes_${i}`} subClient={subClient} hasSubClients={hasSubClients} />;
                })}
            </div>
        </div>
    );
};

export default ThemesScores;
