import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import MainNavItem from "./main-nav-item";

import { mainLinks } from "../lib/constants";

const checkPathMatch = (basePath, pathname) => {
    return (!basePath && pathname === "/") || (basePath && pathname.startsWith(`/${basePath}`));
};

const MainNav = () => {
    const appInsights = useAppInsightsContext();
    const location = useLocation();
    const [styles, setStyles] = useState({});
    const [stylesAfterLoad, setStylesAfterLoad] = useState({});

    useEffect(() => {
        const firstPath = location.pathname.split("/")[1];
        const $links = document.querySelectorAll("#main-nav-items a");
        var $selected = null;

        $links.forEach(($a) => {
            if (checkPathMatch(firstPath, $a.pathname)) {
                $selected = $a;
            }
        });

        if (!$selected) {
            setStyles({
                opacity: 0,
            });
            return;
        }
        const x = $selected.offsetLeft;
        const w = $selected.offsetWidth;

        if (styles.left !== x) {
            setStyles({
                left: x,
                width: w,
                opacity: 1,
                ...stylesAfterLoad,
            });
        }

        if (!stylesAfterLoad.hasOwnProperty("transition")) {
            setStylesAfterLoad({
                transition: "width ease-in-out 700ms, left ease-in-out 700ms",
            });
        }
    }, [location.pathname, styles.left, stylesAfterLoad]);

    const trackEvent = (label) => {
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: label,
                section: "Main Menu",
            }
        );
    };

    const items = mainLinks.map((l, i) => {
        return <MainNavItem key={`mainNav_${i}`} hasActivePath={checkPathMatch(location.pathname.split("/")[1], l.path)} {...l} trackEvent={trackEvent} />;
    });

    return (
        <div className="main-nav">
            <ul id="main-nav-items">
                {items}
                <li className="underline" style={{ ...styles }}></li>
            </ul>
        </div>
    );
};

export default MainNav;
