export const clientDetailCharts = [
    { key: "t12TotalRevenueByClient" },
    { key: "t12TotalRevenueByIndustry" },
    { key: "t12TotalRevenueByServiceLine" },
    { key: "t12TotalRevenueByProjectType" },
    { key: "growthByServiceLine", groupChildrenFrom: "growthByProjectType" },
    { key: "growthByProjectType" },
    { key: "growthByIndustry", groupChildrenFrom: "growthByClient" },
    { key: "growthByClient" },
    { key: "existingProjectCountByProjectType" },
    { key: "clientGroupThemeScore", sortBy: "labelId" },
];

export const limitChartData = (data, limit, settings = {}) => {
    if (!data) {
        return null;
    }

    const otherData = {
        label: "Other",
        labelId: 5,
        labelType: 0,
        parentId: 0,
        value: 0,
        childCount: 0,
        color: "rgba(160,160,160, 1)",
    };
    const otherSection = settings.otherLabel ? `${settings?.otherLabel} ` : "";
    let otherCount = 0;

    if (settings.parentPortion && settings.parentTotal) {
        var limitPortion = settings.parentPortion;
        var childTotal = 0;
        const limitedData = data.reduce((arr, d, i) => {
            if (i === 0 || limitPortion > 1 || (otherData.value === 0 && data.length === i + 1)) {
                limitPortion = limitPortion - (d.value / settings.parentTotal) * settings.parentPortion;
                arr.push(d);
            } else {
                otherCount++;
                otherData.value = d.value + otherData.value;
            }
            childTotal = childTotal + d.value;
            return arr;
        }, []);
        const otherText = otherCount ? `Other ${otherSection}(${otherCount})` : settings?.otherLabel || "Other";
        limitedData.push({ ...otherData, text: otherText });

        return limitedData;
    } else if (data.length > limit) {
        const limitedData = data.reduce((arr, d, i) => {
            if (i < limit) {
                arr.push(d);
            } else {
                otherCount++;
                otherData.value = d.value + otherData.value;
                otherData.childCount = otherData.childCount + (d.children ? d.children.length : 0);
            }
            return arr;
        }, []);
        const otherText = otherCount ? `Other ${otherSection}(${otherCount})` : settings?.otherLabel || "Other";
        limitedData.push({ ...otherData, text: otherText });

        return limitedData;
    } else {
        return data;
    }
};

export const aggregateChildren = (parentData, limit = true, parentLimit = 9) => {
    if (!parentData) {
        return [];
    }
    const totalValue = parentData.reduce((t, p) => t + p.value, 0);
    return parentData.reduce((arr, c, i) => {
        if (c.childCount) {
            // Handle Other
            arr.push({ text: `Other (${c.childCount})`, labelId: 5, labelType: 0, parentId: 0, value: c.value, color: "rgba(160,160,160, 1)" });
        } else if (c.children && c.children.length > 0) {
            const parentPortion = Math.round((c.value / totalValue) * 100);
            const aggregatedChildren = limit ? limitChartData(c.children, parentPortion > 3 ? parentPortion * 2 : 3, { parentTotal: c.value, parentPortion, otherLabel: c.label }) : c.children;
            arr.push(...aggregatedChildren);
        }
        return arr;
    }, []);
};
