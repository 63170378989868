import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import classnames from "classnames";
import logger from "./services/logger";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "$utils/app-insights";
import { ToastContainer } from "react-toastify";

import AdminIndex from "./pages/admin";
import AdminLayout from "./pages/admin/layout";
import AppLoader from "./components/loaders/app-loader";
import AuthRoute from "./routes/AuthRoute";
import ClientDetailLayout from "./pages/clients/detail";
import ClientExistingProjects from "./pages/clients/existing-projects";
import ClientIndex from "./pages/clients";
import ClientDashboard from "./pages/clients/dashboard";
import ClientGrowthPotential from "./pages/clients/growth-potential";
import ClientRecommendations from "./pages/clients/recommendations";
import ClientScores from "./pages/clients/scores";
import ClientSubClients from "./pages/clients/sub-clients";
import EmailIndex from "./emails";
import ExistingProjects from "./pages/projects/existing";
import FilterListIndex from "./pages/admin/pages/filter-lists/filter-lists";
import GlobalError from "./components/global-error";
import HaltAndCatchFire from "./components/critical-error";
import Header from "./components/header";
import HelpIndex from "./pages/help";
import LoginIndex from "./components/login";
import Logout from "./components/login/logout";
import Reset from "./components/login/reset";
import OfficesIndex from "./pages/offices";
import PotentialProjects from "./pages/projects/potential";
import ProjectDetail from "./pages/projects/detail";
import ProjectListLayout from "./pages/projects";
import SegmentsIndex from "./pages/segments";
import WelcomeIndex from "./pages/welcome";

import { UserStore, retrieveExistingUser } from "./stores/user";
import { AppContextStore, fetchFilters, fetchVersions, setHaltAndCatchFire } from "./stores/app-context";
import { PanelState } from "./contexts/panels";
import { useMsal } from "@azure/msal-react";
import { useIdleTimer } from "react-idle-timer";
import { LayoutStore } from "./stores/layout";
import { SVGDefinitions } from "$components/charts/svg-definitions";
import { handleAbort } from "$services/api";

import "./styles/main.scss";

const TIMEOUT = 3600000; // 1 hour
const TIMEOUT_DEBOUNCE = 10000; // 10 seconds

function App() {
    const { inProgress, accounts, instance } = useMsal();
    const user = UserStore.useState((s) => s);
    const [isLoading, setIsLoading] = useState(true);
    const layoutProperties = LayoutStore.useState((s) => s);
    const isCriticalError = AppContextStore.useState((s) => s.isHaltAndCatchFire);

    const { start } = useIdleTimer({
        timeout: TIMEOUT,
        onIdle: () => {
            window.location = "/reset";
        },
        debounce: TIMEOUT_DEBOUNCE,
        startManually: true,
    });
    // only start the timer when we're logged in
    // this prevents the timer from running on the login page
    if (user && user.isAuthed) {
        start();
    }

    useEffect(() => {
        const versionsController = fetchVersions();

        return () => {
            handleAbort([versionsController]);
        };
    }, []);

    useEffect(() => {
        retrieveExistingUser(inProgress, accounts, instance);
    }, [inProgress, accounts, instance]);

    useEffect(() => {
        let filtersController;
        if (user.email) {
            appInsights.setAuthenticatedUserContext(user.email, null, true);
        }
        if (user.hasLoaded && user.isAuthed) {
            try {
                filtersController = fetchFilters();
            } catch (e) {
                logger.error(e);
                setHaltAndCatchFire(true);
            }
        }
        setIsLoading(!user.hasLoaded);

        return () => {
            handleAbort([filtersController]);
        };
    }, [user]);

    if (isCriticalError) {
        return <HaltAndCatchFire />;
    }

    return (
        <div className={classnames("App", { "-stowed": layoutProperties.isLeftPanelStowed })}>
            <AppInsightsContext.Provider value={reactPlugin}>
                <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                <SVGDefinitions />
                <div id="panel-root"></div>
                {isLoading && <AppLoader />}
                {!isLoading && (
                    <Router>
                        <PanelState>
                            {user.isAuthed && <Header />}
                            {user.isAuthed && <GlobalError />}
                            <Routes>
                                {!user.isAuthed && <Route exact path="/" element={<LoginIndex />} />}
                                <Route path="/email/:email" element={<EmailIndex />} />
                                <Route path="/logout" element={<Logout />} />
                                <Route path="/reset" element={<Reset />} />
                                <Route element={<AuthRoute />}>
                                    <Route exact path="/" element={<WelcomeIndex />} />
                                    <Route path="/segments" element={<SegmentsIndex />} />
                                    <Route path="/offices" element={<OfficesIndex />} />
                                    <Route path="/clients" element={<ClientIndex />} />
                                    <Route element={<ClientDetailLayout />}>
                                        <Route path="/client/:clientId/growth-potential" element={<ClientGrowthPotential />} />
                                        <Route path="/client/:clientId/sub-clients" element={<ClientSubClients />} />
                                        <Route path="/client/:clientId/scores" element={<ClientScores />} />
                                        <Route path="/client/:clientId/details" element={<ClientExistingProjects />} />
                                        <Route path="/client/:clientId/existing-projects" element={<ClientExistingProjects />} />
                                        <Route path="/client/:clientId/recommendations" element={<ClientRecommendations />} />
                                        <Route exact path="/client/:clientId" element={<ClientDashboard />} />
                                    </Route>
                                    <Route element={<ProjectListLayout />}>
                                        <Route exact path="/projects" element={<Navigate to="/projects/potential" />}></Route>
                                        <Route path="/projects/potential" element={<PotentialProjects />} />
                                        <Route path="/projects/existing" element={<ExistingProjects />} />
                                    </Route>
                                    <Route path="/project/:projectId/:projectType" element={<ProjectDetail />} />
                                    <Route path="/help" element={<HelpIndex />} />
                                </Route>
                                <Route element={<AdminLayout />}>
                                    <Route path="/admin" element={<AdminIndex />} />
                                    <Route path="/admin/filter-lists" element={<FilterListIndex />} />
                                </Route>
                            </Routes>
                        </PanelState>
                    </Router>
                )}
            </AppInsightsContext.Provider>
        </div>
    );
}

export default App;
