export const scrollToName = (name) => {
    const el = document.getElementsByName(name)[0];
    if (el) {
        window.scrollTo({
            top: el.offsetTop || 0,
            behavior: "smooth",
        });
    }
};

export const scrollToPos = (num) => {
    window.scrollTo({
        top: num || 0,
        behavior: "smooth",
    });
};
