import React, { useEffect, useState } from "react";
import _ from "lodash";

import Select from "$components/form-inputs/select";
import { Input } from "$components/form-inputs/inputs";
import { useInput } from "$utils/hooks/use-input";
import { dialogConfirm } from "$components/dialog/confirm";
import { retrieveValue } from "$services/local";
import { AppContextStore, saveFilter, updateCurrentFilters, replaceCurrentFilters } from "$stores/app-context";

var errorTimer = null;

const SavedFilters = () => {
    const { currentFilters, selectedSavedFilter } = AppContextStore.useState((f) => {
        return { currentFilters: f.currentFilters || {}, selectedSavedFilter: f.selectedSavedFilter };
    });
    const savedFilters = AppContextStore.useState((f) => f.savedFilters || {});
    const selectFilter = useInput("");
    const inputFilterName = useInput("");
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchFilters = async () => {
            const response = await retrieveValue("saved-filters");
            if (response) {
                setSavedFilters(response);
            }
        };

        fetchFilters();
    }, []);

    useEffect(() => {
        clearTimeout(errorTimer);
        if (error) {
            errorTimer = setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    useEffect(() => {
        if (selectFilter.value?.value) {
            const currentFilters = savedFilters[selectFilter.value.value] ? JSON.parse(savedFilters[selectFilter.value.value].filters) || {} : {};
            replaceCurrentFilters(currentFilters, selectFilter.value?.text);
        }
    }, [selectFilter.value]);

    useEffect(() => {
        if (!selectedSavedFilter && selectFilter) {
            selectFilter.setValue("");
        }
    }, [selectedSavedFilter]);

    const handleSelectChange = (name, option) => {
        selectFilter.setValue(option);
        if (!option || !option?.value) {
            // Clear Fitlers
            replaceCurrentFilters({}, "");
        }
    };

    const handleSave = async () => {
        if (_.isEmpty(currentFilters)) {
            setError("No filters are currently set");
            return false;
        }

        if (inputFilterName.value) {
            // If input value, save new
            const newOption = { text: inputFilterName.value };
            saveFilter(
                {
                    ...newOption,
                },
                currentFilters
            );

            inputFilterName.reset();
            selectFilter.setValue(newOption);
        } else if (selectFilter.value?.value) {
            // Else if select value override
            const confirmSave = await dialogConfirm({
                message: (
                    <p className="txt -center">
                        Are you sure you want to overwrite the filter:
                        <br />
                        <br />
                        <span className="txt -white">{selectFilter.value?.text}</span>
                    </p>
                ),
                cssClass: "-confirm",
            });
            if (!confirmSave) {
                return false;
            }

            saveFilter(selectFilter.value, currentFilters);
        } else if (!inputFilterName.value) {
            setError("Filter name required to save!");
        }
    };

    return (
        <div className="saved-filters">
            <hr className="-dark" />
            {!_.isEmpty(savedFilters) && <Select label="Saved Filters" options={Object.keys(savedFilters).map((f) => savedFilters[f])} defaultValue={selectFilter.value} updateOnChange={handleSelectChange} />}
            <div className="button-group -full">
                <Input placeholder="New Filter Name" {...inputFilterName.bind} />
                <button className="button -primary -small -right" onClick={handleSave}>
                    Save
                </button>
            </div>
            {Object.keys(currentFilters).length > 0 && (
                <div className="button-group -left">
                    <p>
                        <button
                            className="button -text txt -gray"
                            onClick={() => {
                                replaceCurrentFilters({}, "");
                            }}
                        >
                            Clear Filters
                        </button>
                    </p>
                </div>
            )}
            {error && <p className="save-error txt -small -error">{error}</p>}
        </div>
    );
};

export default SavedFilters;
