import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faExclamation } from "@fortawesome/free-solid-svg-icons";

import "./confirm.scss";

function DefaultConfirm({ message, action }) {
    const onConfirm = () => {
        action.remove();
        action.resolve(true);
    };

    const onCancel = () => {
        action.remove();
        action.resolve(false);
    };

    return (
        <div>
            <h4 className="txt-center">{message}</h4>
            <div className="button-group -center">
                <button className="button -small" onClick={onCancel}>
                    Cancel
                </button>
                <button className="button -primary -small" onClick={onConfirm}>
                    Confirm
                </button>
            </div>
        </div>
    );
}

DefaultConfirm.defaultProps = {
    message: "Are you sure?",
};

export const dialogConfirm = (settings, message) => {
    return new Promise((resolve, reject) => {
        addDialog(settings, resolve, reject);
    });
};

function addDialog(settings, resolve, reject) {
    const UseComponent = settings.Component || DefaultConfirm;
    const body = document.getElementsByTagName("body")[0];
    const div = document.createElement("div");

    const onClose = (e) => {
        removeDialog();
        resolve(false);
    };

    const action = {
        resolve,
        reject,
        remove: removeDialog,
    };

    const modalClass = classnames("modal", settings.cssClass || {});

    div.setAttribute("id", "dialog-modal");
    body.appendChild(div);
    ReactDOM.render(
        <div className="modal-overlay">
            <div className={modalClass}>
                <div className="display-icon">
                    <FontAwesomeIcon icon={settings.icon || faExclamation} />
                </div>
                <UseComponent message={settings.message} data={settings.data} action={action} />
                {settings.showClose && (
                    <div className="modal-close" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                )}
            </div>
        </div>,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("dialog-modal");
    const body = document.getElementsByTagName("body")[0];
    body.removeChild(div);
}
