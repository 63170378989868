import _ from "lodash";
import { Store } from "pullstate";
import api from "../services/api";

const initialState = {
    opportunityFormSchema: null,
    opportunityFormData: null,
    opportunityFormErrors: null,
    opportunityFormLoading: false,
    opportunityFormSubmitting: false,
};

export const SalesforceStore = new Store(initialState);

export const setOpportunityFormLoading = (loading) => {
    return SalesforceStore.update((s) => {
        s.opportunityFormLoading = loading;
        return s;
    });
};

export const setOpportunityFormSubmitting = (loading) => {
    return SalesforceStore.update((s) => {
        s.opportunityFormSubmitting = loading;
        return s;
    });
};

const apiOpportunityFomrSchema = async (apiSettings) => {
    await setOpportunityFormLoading(true);
    const mainResult = await api.get("/api/salesforce/opportunity", null, apiSettings);
    if (!mainResult) {
        return setOpportunityFormLoading(false);
    }
    const result = mainResult.opportunitySchema;
    return await SalesforceStore.update((s) => {
        s.opportunityFormSchema = {
            ...result,
            groups: result.fields.reduce((obj, f) => {
                if (!obj.hasOwnProperty(f.sectionId)) {
                    obj[f.sectionId] = [];
                }

                const newField = { ...f };
                if (newField.options) {
                    newField.options = newField.options.map((o) => {
                        return { value: o.optionValue, text: o.optionText };
                    });
                }
                obj[f.sectionId].push(newField);
                return obj;
            }, {}),
        };
        s.prePopulateMap =
            mainResult.prePopulateMap && mainResult.prePopulateMap.map
                ? mainResult.prePopulateMap.map
                      .filter((p) => !!p.orgaimiField)
                      .reduce((memo, p) => {
                          if (p.orgaimiField) {
                              memo[p.salesforceField] = p.orgaimiField;
                          }
                          return memo;
                      }, {})
                : {};

        s.defaultValues =
            mainResult.prePopulateMap && mainResult.prePopulateMap.map
                ? mainResult.prePopulateMap.map
                      .filter((p) => !!p.defaultValue)
                      .reduce((memo, p) => {
                          if (p.defaultValue) {
                              memo[p.salesforceField] = p.defaultValue;
                          }
                          return memo;
                      }, {})
                : {};
        s.opportunityFormData = result.fields.reduce((acc, field) => {
            acc[field.name] = field.defaultValue || "";
            return acc;
        }, {});

        s.opportunityFormLoading = false;
        return s;
    });
};

export const fetchOpportunityFormSchema = () => {
    const abortController = new AbortController();
    apiOpportunityFomrSchema({ signal: abortController.signal });

    return abortController;
};

export const updateFormValue = async (key, value) => {
    SalesforceStore.update((s) => {
        s.opportunityFormData[key] = value;
        return s;
    });
};

export const submitOpportunityToSalesforce = async (clientId, projectKey, formValues) => {
    setOpportunityFormSubmitting(true);
    return api.put(`/api/salesforce/opportunity?clientId=${clientId}&projectKey=${projectKey}`, formValues);
};
