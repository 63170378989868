import PaginatedStore from "./base/PaginatedStore";

export const DEFAULT_SORT = "name";
const CLIENT_STORAGE_KEY = "/api/projects/existing";

const { store, fetchList, setLoading, reset, previousPage, nextPage, gotoPage, setPageSize, setSort, watchPagination } = PaginatedStore({
    key: "existingProjects",
    label: "Existing Projects",
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    storageKey: CLIENT_STORAGE_KEY,
});

export const ExistingProjectsStore = store;

export const fetchExistingProjects = fetchList;

export { setLoading, reset, previousPage, nextPage, gotoPage, setPageSize, setSort, watchPagination };
