import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

var throttled = false;
var delay = 500;

const throttleSetModalCenter = () => {
    if (!throttled) {
        // we're throttled!
        throttled = true;
        // set a timeout to un-throttle
        setTimeout(function () {
            // actual callback action
            setModalCenter();
            throttled = false;
        }, delay);
    }
};

const setModalCenter = () => {
    const el = document.getElementById("modal");
    if (!el) {
        return;
    }

    const modalHeight = el.offsetHeight;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if (modalHeight < windowHeight) {
        el.classList.add("-center");
    } else {
        el.classList.remove("-center");
    }
};

const body = document.getElementsByTagName("body")[0];

const Modal = ({ children, cssClass, hideClose, onClose, show }) => {
    return show ? (
        <ModalBuilder cssClass={cssClass}>
            {children}
            {!hideClose && (
                <div className="modal-close" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            )}
        </ModalBuilder>
    ) : null;
};

const ModalBuilder = ({ children, cssClass }) => {
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.
    var el = document.createElement("div");
    el.classList.add("modal-overlay");
    var resizeObserver = new ResizeObserver(throttleSetModalCenter);
    useEffect(() => {
        // Append the element into the DOM on mount. We'll render
        // into the modal container element (see the HTML tab).
        body.appendChild(el);
        document.body.classList.add("-modal-showing");
        setModalCenter(document.getElementById("modal"));

        resizeObserver.observe(document.getElementById("modal"));
        window.addEventListener("resize", throttleSetModalCenter);

        return () => {
            if (document.getElementById("modal")) {
                // TODO: This doesn't feel right, react is killing the modal before it can unobserve
                resizeObserver.unobserve(document.getElementById("modal"));
            }
            window.removeEventListener("resize", throttleSetModalCenter);
            // Remove the element from the DOM when we unmount
            body.removeChild(el);
            document.body.classList.remove("-modal-showing");
        };
    }, [el, resizeObserver]);
    const modalContainer = (
        <div id="modal" className={classnames("modal", { [cssClass]: cssClass })}>
            {children}
        </div>
    );
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(
        // Any valid React child: JSX, strings, arrays, etc.
        modalContainer,
        // A DOM element
        el
    );
};

export default Modal;
