import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import Button from "$components/button";
import FeatureScores from "./feature-scores";
import RadarPolarChart from "$components/charts/radar-chart";
import TileBar from "$components/charts/tile-bar";

import { convertThemeToTooltip } from "$utils/tooltips";
import { COLOR_FONT, COLOR_POTENTIAL } from "$utils/colors";
import { ChartsStore } from "$stores/charts";
import ExternalLegend from "$components/charts/legend/external";
import ThemeDefinitions from "./definitions";
import { appInsights } from "$utils/app-insights";

const ThemeItem = ({ theme }) => {
    return (
        <>
            <h4 className="theme-name">
                <em>
                    <span data-tooltip={convertThemeToTooltip(theme.name)}>{theme.name}</span>
                </em>
            </h4>
            <div className="theme-chart">
                <TileBar value={theme.value} cssClass={["-primary", "-large"]} />
            </div>
            <FeatureScores data={theme.features} />
            <div className="title-bar">
                <div className="title">Review</div>
                <div className="title">Excellent</div>
            </div>
        </>
    );
};

const ThemeScore = ({ hasSubClients, subClient }) => {
    const [showHidden, setShowHidden] = useState(false);
    const { clientGroupThemeScore } = ChartsStore.useState((c) => {
        return {
            clientGroupThemeScore: (c.charts["clientGroupThemeScore"] || []).map((t, i) => {
                return {
                    label: t.label,
                    clientGroup: t.value,
                    client: subClient.valueByThemeId[t.labelId] || 0,
                };
            }),
        };
    });

    const toggleHidden = async () => {
        setShowHidden(!showHidden);

        appInsights.trackEvent(
            { name: "Show More" },
            {
                label: subClient?.name,
                action: !showHidden ? "close" : "open",
            }
        );
    };

    return (
        <div name={`client_${subClient.id}`} className="theme-score">
            <img className="theme-image" src="/images/theme-external-market.svg" alt="" />
            <div className="theme-header">
                <h2>{subClient.clientName}</h2>
                <div className="theme-preview">
                    {subClient.themes.map((t, i) => {
                        return <TileBar key={`preview_${i}`} value={t.value} cssClass={["-primary", "-micro"]} />;
                    })}
                </div>
            </div>
            {hasSubClients && (
                <Button className={classNames("button -text", { "-active": showHidden })} onClick={toggleHidden}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </Button>
            )}

            <div className={classNames("theme-content", { "hidden-content": hasSubClients, "-active": showHidden })}>
                {hasSubClients && (
                    <div className="theme-summary">
                        <ThemeDefinitions
                            data={[
                                { name: "Communications", text: "Team-level signals including communication frequency, patterns and networks" },
                                { name: "Client Demographics", text: "Client-level signals including the mix of services, practices, and administrative characteristics" },
                                { name: "Delivery Process", text: "Project-level signals including project management, processes, focus, and mechanics" },
                            ]}
                        />
                        <div className="chart-theme-performance">
                            {showHidden && (
                                <RadarPolarChart
                                    data={clientGroupThemeScore}
                                    radarKeys={[
                                        { dataKey: "clientGroup", color: COLOR_FONT },
                                        { dataKey: "client", color: COLOR_POTENTIAL },
                                    ]}
                                />
                            )}
                            <ExternalLegend
                                data={[
                                    { text: "Parent Client", color: COLOR_FONT },
                                    { text: "Sub Client", color: COLOR_POTENTIAL },
                                ]}
                            />
                        </div>
                        <ThemeDefinitions
                            data={[
                                { name: "Feedback", text: "Team-level signals including degree of feedback and support for team member development" },
                                { name: "CRM", text: "Client-level signals including opportunities and lead-gen behaviors" },
                                { name: "Team Composition", text: "Client & project-level signals including roles, responsibilities, relationships and experiences" },
                            ]}
                            cssClasses={["txt -right"]}
                        />
                    </div>
                )}
                <div className="theme-list">
                    {subClient.themes.map((t, i) => (
                        <ThemeItem key={`theme-${i}`} theme={t} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ThemeScore;
