import React, { useEffect, useState } from "react";

import ChartPie from "$components/charts/pie";
import ExternalLegend from "$components/charts/legend/external";
import PotentialProjects from "./components/potential-projects";
import PotentialProjectsTable from "../projects/potential";
import ToggleSwitch from "$components/toggle-switch";

import { ClientDetailsStore } from "$stores/client-details";
import { TOOLTIP_TOP_POTENTIAL_PROJECTS } from "$utils/tooltips";
import { useDocumentTitle } from "$utils/document-title";
import { clientProjectsColumns, subclientProjectsColumns } from "./lib/columns";
import { ChartsStore } from "$stores/charts";
import { aggregateChildren, limitChartData } from "./lib/chart-helper";

const toggleOptions = [
    { label: "Average Revenue", value: 0 },
    { label: "Expansion Probability", value: 1 },
];

const ClientPotentialRevenue = () => {
    useDocumentTitle("Client Potential Revenue");
    const { hasSubClients, clientDetails } = ClientDetailsStore.useState((s) => {
        return { hasSubClients: s.hasSubClients, clientDetails: s.clientDetails };
    });
    const { averageRevenueProjects, expansionProbabilityProjects } = ClientDetailsStore.useState((s) => s.projects);
    const { growthByIndustry, growthByServiceLine, growthByClient, growthByProjectType } = ChartsStore.useState((s) => {
        return {
            growthByProjectType: limitChartData(s.charts["growthByProjectType"], 9),
            growthByServiceLine: limitChartData(s.charts["growthByServiceLine"], 9),
            growthByIndustry: limitChartData(s.charts["growthByIndustry"], 9),
            growthByClient: limitChartData(s.charts["growthByClient"], 9),
        };
    });
    const [tab, setTab] = useState(0);

    const tabChange = (value, index) => {
        setTab(index);
    };

    return (
        <div>
            <h2>
                <em>Growth</em> Potential
            </h2>
            {hasSubClients && (
                <div className="dashboard-cards">
                    <div className="card -span2">
                        <h4 className="txt -center -white">Growth Potential By Industry & Sub Client</h4>
                        <div className="chart-legend">
                            <ChartPie data={growthByIndustry} outerRadius={105} innerRadius={50} data2={aggregateChildren(growthByIndustry)} outerRadius2={170} innerRadius2={115} />
                            <ExternalLegend data={growthByIndustry} />
                        </div>
                    </div>

                    <div className="card -span2">
                        <h4 className="txt -center -white">Growth Potential By Service Line & Project Type</h4>
                        <div className="chart-legend">
                            <ChartPie data={growthByServiceLine} outerRadius={105} innerRadius={50} data2={aggregateChildren(growthByServiceLine)} outerRadius2={170} innerRadius2={115} />
                            <ExternalLegend data={growthByServiceLine} />
                        </div>
                    </div>
                </div>
            )}
            {!hasSubClients && (
                <div className="dashboard-cards">
                    <div className="card -span2">
                        <h4 className="txt -center -white">Growth Potential By Service Line</h4>
                        <div className="chart-legend">
                            <ChartPie data={growthByServiceLine} outerRadius={105} innerRadius={50} />
                            <ExternalLegend data={growthByServiceLine} />
                        </div>
                    </div>

                    <div className="card -span2">
                        <h4 className="txt -center -white">Growth Potential By Project Type</h4>
                        <div className="chart-legend">
                            <ChartPie data={growthByProjectType} outerRadius={105} innerRadius={50} />
                            <ExternalLegend data={growthByProjectType} />
                        </div>
                    </div>
                </div>
            )}
            <br />
            <br />
            <br />
            <div className="flex-header">
                <h3 className="txt -uppercase -no-margin -tablet-center">
                    <em>Top 3</em> <span data-tooltip={TOOLTIP_TOP_POTENTIAL_PROJECTS}>Potential Projects</span>
                </h3>
                <ToggleSwitch options={toggleOptions} updateField={tabChange} selectedIndex={tab} />
            </div>
            <PotentialProjects projects={tab === 0 ? averageRevenueProjects : expansionProbabilityProjects} type={tab === 0 ? "revenue" : "probability"} />
            <br />
            <br />
            <br />
            <h3 className="txt -uppercase -tablet-center">
                <em>All Client</em> <span data-tooltip={TOOLTIP_TOP_POTENTIAL_PROJECTS}>Potential Projects</span>
            </h3>
            <br />
            {clientDetails?.id && (
                <PotentialProjectsTable columns={hasSubClients ? subclientProjectsColumns : clientProjectsColumns} overrideFilters={{ client: [{ value: clientDetails.id, text: clientDetails.name }] }} />
            )}
        </div>
    );
};

export default ClientPotentialRevenue;
