import React from "react";
import { Cell, ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";
import { CHART_DEFAULT_COLORS } from "../lib/constants";

import { COLOR_BASE, COLOR_FONT, COLOR_GRAY } from "$utils/colors";

const ChartScatter = ({ data, dataKeyX, dataKeyY, dataKeyZ, xRange, yRange, colors, TooltipComponent, handleClick }) => {
    return (
        <div className="chart-container">
            <ResponsiveContainer width="100%" height="100%">
                <ScatterChart
                    width={400}
                    height={400}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke={COLOR_BASE} />
                    <ReferenceLine y={0} stroke={COLOR_FONT} />
                    <ReferenceLine x={0} stroke={COLOR_FONT} />
                    <XAxis type="number" dataKey={dataKeyX} domain={[0, 700]} label={{ value: "Health Score", position: "outside", offset: "12", fill: "#fff" }} range={xRange} />
                    <YAxis type="number" dataKey={dataKeyY} domain={[0, 10]} label={{ value: "SVI Score", angle: -90, position: "center", fill: "#fff" }} range={yRange} />
                    <ZAxis type="number" dataKey={dataKeyZ} range={[200, 10000]} name="Revenue" />
                    <ReferenceLine x="350" stroke={COLOR_GRAY} />
                    <ReferenceLine y="5" stroke={COLOR_GRAY} />
                    <Tooltip cursor={{ strokeDasharray: "3 3" }} content={<TooltipComponent />} />
                    <Scatter name="Client A" data={data} fill="#8884d8">
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color || colors[index % colors.length]} onClick={() => handleClick(entry)} />
                        ))}
                    </Scatter>
                </ScatterChart>
            </ResponsiveContainer>
        </div>
    );
};

ChartScatter.defaultProps = {
    dataKeyX: "x",
    dataKeyY: "y",
    dataKeyZ: "z",
    xRange: "",
    yRange: "",
    colors: CHART_DEFAULT_COLORS,
    TooltipComponent: null,
    handleClick: () => {},
};

export default ChartScatter;
