import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";

import "./tabs.scss";

const Tabs = ({ children, tabs, defaultIndex, cssClass }) => {
    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    useEffect(() => {
        if (defaultIndex > -1) {
            setSelectedIndex(defaultIndex);
        }
    }, [defaultIndex]);

    const tabButtons = useMemo(() => {
        return tabs.map((t, index) => {
            const tabClass = index === selectedIndex ? "tab -active" : "tab";
            return (
                <button
                    key={`tab_${index}`}
                    data-index={index}
                    className={tabClass}
                    onClick={() => {
                        setSelectedIndex(index);
                    }}
                >
                    {t.label}
                </button>
            );
        });
    }, [selectedIndex, tabs]);

    const tabChildren = useMemo(() => {
        return children && children.length > 0
            ? children.map((c, i) => {
                  return (
                      <div key={`tab_${i}`} className={classnames("tab-child", { "-active": selectedIndex === i })}>
                          {c}
                      </div>
                  );
              })
            : null;
    }, [children, selectedIndex]);

    return (
        <div className="tab-container">
            <div className={classnames("tab-list", { [cssClass]: cssClass })}>{tabButtons}</div>
            <div className="tab-content">{tabChildren}</div>
        </div>
    );
};

Tabs.defaultProps = {
    defaultIndex: 0,
    tabs: [],
    cssClass: "-underline",
};

export default Tabs;
