import React from "react";

import HealthHistory from "./components/health-history";
import RadarPolarChart from "$components/charts/radar-chart";
import ThemesTrends from "./components/themes-scores";

import { TOOLTIP_HEALTH_SCORE } from "$utils/tooltips";
import { useDocumentTitle } from "$utils/document-title";
import { COLOR_FONT } from "$utils/colors";
import { ClientDetailsStore } from "$stores/client-details";
import { ChartsStore } from "$stores/charts";

const ClientScores = () => {
    useDocumentTitle("Client Scores");
    const { hasSubClients } = ClientDetailsStore.useState((s) => {
        return { hasSubClients: s.hasSubClients };
    });
    const { clientGroupThemeScore } = ChartsStore.useState((c) => {
        return {
            clientGroupThemeScore: c.charts["clientGroupThemeScore"],
        };
    });

    return (
        <div>
            <h2>
                <em>Client</em> <span data-tooltip={TOOLTIP_HEALTH_SCORE}>Health Scores</span>
            </h2>
            <div className="score-theme-charts">
                <div className="client-scores">
                    <HealthHistory />
                </div>
                <div>
                    <h3 className="txt -center">
                        <em>Overall</em> Theme Performance
                    </h3>
                    <div className="chart-theme-performance">
                        <RadarPolarChart data={clientGroupThemeScore} radarKeys={[{ name: "Theme Scores", dataKey: "value", color: COLOR_FONT }]} />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <ThemesTrends hasSubClients={hasSubClients} />
        </div>
    );
};

export default ClientScores;
