import React from "react";
import classnames from "classnames";

import ScoreBubble from "../../score-bubble";
import { checkIsHealthy, checkIsHealthyByChange } from "../../../utils/health-helper";

const MapTooltip = ({ id, scoreChange, currentAverageScore, officeLabel, officeManagingPartner = () => {} }) => {
    const isHealthy = checkIsHealthy(currentAverageScore);

    return (
        <div className={classnames("map-tooltip", { "-healthy": isHealthy })}>
            <h1>{currentAverageScore}</h1>
            <h5>Current Average Score</h5>
            <div>
                <ScoreBubble arrow={true} healthy={checkIsHealthyByChange(scoreChange)} value={scoreChange} postSup={"%"} />
            </div>
            <div>{officeLabel}</div>
            <div>{officeManagingPartner}</div>
            <div>
                <a href={`#office_${id}`} className="button -primary -small">
                    View Details
                </a>
            </div>
        </div>
    );
};

export default MapTooltip;
