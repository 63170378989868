import React from "react";
import { useNavigate } from "react-router-dom";

import TableLoader from "../../../components/table/table-loader.js";

import { ExistingProjectsStore, nextPage, previousPage, gotoPage, setPageSize, setSort } from "../../../stores/existingProjects.js";
import { defaultColumns } from "./libs/columns.js";

export const mapToTableData = (c) => {
    return {
        key: c.id,
        ...c,
    };
};

const ExistingProjectList = () => {
    const navigate = useNavigate();
    const { projects, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = ExistingProjectsStore.useState((c) => {
        return { projects: c.existingProjects || [], pageSize: c.pageSize, pageIndex: c.pageIndex, pageCount: c.pageCount, isLoading: c.isLoading, sort: c.sort, isSortAsc: c.isSortAsc };
    });

    const rowClick = (row) => {
        navigate(`/project/${row.original.key}/existing`);
    };

    const data = projects.map(mapToTableData);
    return (
        <TableLoader
            containerClass="project-list"
            data={data}
            columns={defaultColumns}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            setSort={setSort}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={pageCount}
            isLoading={isLoading}
            sort={sort}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
        />
    );
};

export default ExistingProjectList;
