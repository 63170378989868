import React, { useEffect, useState } from "react";

import FilterItem from "./components/filter-item";

import { AppContextStore, FILTER_RESULT_SET } from "../../stores/app-context";
import "./filter-box.scss";

const FilterBox = () => {
    const overview = AppContextStore.useState((s) => s.overview);
    const [filterData, setFilterData] = useState({});

    useEffect(() => {
        if (overview && overview.counts) {
            setFilterData({
                client: overview.counts.clientCount,
                crm: overview.counts.crmCount,
                geography: overview.counts.geographyCount,
                office: overview.counts.officeCount,
            });
        }
    }, [overview]);

    const filterCounts = FILTER_RESULT_SET.map((f) => {
        return <FilterItem key={`filter_${f.key}`} item={f} count={filterData[f.key]} />;
    });

    return <div className="filter-box">{filterCounts}</div>;
};

export default FilterBox;
