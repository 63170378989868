import React from "react";

import BillingByTeam from "../components/billingByTeam";
import InfoTable from "$components/info-table";
import DateInfoCell from "$components/info-table/cells/date-info-cell";
import LinkInfoCell from "$components/info-table/cells/link-info-cell";
import ProjectBilling from "../components/project-billing";

import { ProjectDetailsStore } from "$stores/projectDetails";
import { useDocumentTitle } from "$utils/document-title";

const infoPropertiesMap = {
    clientName: { label: "Client Name", colSpan: 2, Component: LinkInfoCell, additionalProps: { url: "/client/#{clientId}" } },
    clientPIC: { label: "Client PIC", colSpan: 2 },
    startDate: { label: "Project Start", Component: DateInfoCell },
    endDate: { label: "Last Date", Component: DateInfoCell, additionalProps: { noValue: "Ongoing" } },
    subClientName: { label: "Sub Client", colSpan: 2 },
    projectPIC: { label: "Project PIC", colSpan: 2 },
    status: { label: "Project Status", colSpan: 2 },
    serviceLine: { label: "Service Line", colSpan: 2 },
    industry: { label: "Industry", colSpan: 4 },
};

const ExistingProject = () => {
    useDocumentTitle("Existing Project");
    const { billingHistory, currentProject } = ProjectDetailsStore.useState((s) => {
        return { billingHistory: s.billingHistory, currentProject: s.projectDetails };
    });
    return (
        <div className="main-wrapper">
            <div className="page-content -shadow">
                <div className="content">
                    <h1 className="txt -uppercase -no-margin -tablet-center">
                        <em>Existing:</em> {currentProject.name}
                    </h1>

                    <InfoTable data={currentProject} dataMap={infoPropertiesMap} colCount={6} />

                    <BillingByTeam data={currentProject.team} revenue={currentProject.revenueToDate} hours={currentProject.totalHoursBilled} />
                    {billingHistory && billingHistory.length > 0 && (
                        <>
                            <h3>Project Billing Over {billingHistory.length}-Months</h3>
                            <ProjectBilling data={billingHistory} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExistingProject;
