import React, { useEffect } from "react";

import NoContent from "../no-content";
import ShadowTable from "../loaders/shadow-table";
import Table from ".";
import { scrollToName } from "$utils/scroll-to";

const TableLoader = ({
    allowFilter,
    columns,
    containerClass,
    data,
    filters,
    manualFilters,
    manualPagination,
    manualSortBy,
    pageIndex,
    pageSize,
    pageCount,
    isLoading,
    onRowClick,
    sort,
    isSortAsc,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    setFilters,
    setSort,
    showPagnitaion,
    noContentText,
}) => {
    const scrollNextPage = () => {
        if (nextPage) {
            scrollToName(containerClass);
            nextPage();
        }
    };

    const scrollPreviousPage = () => {
        if (previousPage) {
            scrollToName(containerClass);
            previousPage();
        }
    };

    return (
        <div className={containerClass} name={containerClass}>
            {!isLoading ? (
                data.length > 0 ? (
                    <Table
                        allowFilter={allowFilter}
                        columns={columns}
                        data={data}
                        filters={filters}
                        manualFilters={manualFilters}
                        manualPagination={manualPagination}
                        manualSortBy={manualSortBy}
                        nextPage={scrollNextPage}
                        previousPage={scrollPreviousPage}
                        gotoPage={gotoPage}
                        setPageSize={setPageSize}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        setFilters={setFilters}
                        setSort={setSort}
                        showPagination={showPagnitaion}
                        sort={sort}
                        isSortAsc={isSortAsc}
                        onRowClick={onRowClick}
                    />
                ) : (
                    <div className="no-data">
                        <NoContent text={noContentText} />
                    </div>
                )
            ) : (
                <ShadowTable />
            )}
        </div>
    );
};

TableLoader.defaultProps = {
    manualFilters: true,
    manualPagination: true,
    manualSortBy: true,
    noContentText: "No Data to Display With Current Filter",
    showPagnitaion: true,
};

export default TableLoader;
