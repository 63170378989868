import { React, useCallback, useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";

import { Input, Select } from "$components/form-inputs/inputs";
import MultiRangeSlider from "$components/form-inputs/multi-range-slider";

import "./filters.scss";
import classNames from "classnames";
import { abbrNum } from "$utils/number-helper";
import MultiSelect from "$components/form-inputs/select/multi";

// Component for Default Column Filter
export function DefaultFilterForColumn({
    column: {
        filterValue,
        preFilteredRows: { length },
        setFilter,
    },
}) {
    return (
        <div className="table-filter">
            <Input
                value={filterValue || ""}
                updateOnChange={(name, value) => {
                    // Set undefined to remove the filter entirely
                    setFilter(value || undefined);
                }}
                placeholder={"Filter..."}
                style={{ marginTop: "10px" }}
            />
        </div>
    );
}

// Component for Custom Select Filter
export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Use preFilteredRows to calculate the options
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // UI for Multi-Select box
    return (
        <div className="table-filter">
            <MultiSelect
                name={id}
                label="Service Line"
                value={filterValue}
                updateOnChange={(name, value) => {
                    setFilter(value || undefined);
                }}
                options={options.map((v) => {
                    return { text: v, value: v };
                })}
                cssClasses={["-small"]}
            />
        </div>
    );
}

export const MultiRangeFilter = ({
    column: {
        filterValue,
        preFilteredRows: { length },
        setFilter,
        filters,
    },
}) => {
    const [showHover, setShowHover] = useState(false);
    const onChange = useCallback((name, value) => {
        setFilter(value);
    });
    const buttonText =
        filterValue?.min || filterValue?.max ? (
            <span className="txt -white">
                <sup>$</sup>
                {`${abbrNum(filterValue.min)} - ${abbrNum(filterValue.max)}`}
            </span>
        ) : (
            "Set Range"
        );

    return (
        <div className="table-filter">
            <button
                className="button -micro -border -dark"
                onClick={() => {
                    setShowHover(!showHover);
                }}
            >
                {buttonText}
            </button>
            <div className={classNames("filter-tooltip", { "-active": showHover })}>
                <MultiRangeSlider defaultValue={filterValue} min={filters.min} max={filters.max} updateOnChange={onChange} format={{ isCurrency: true }} />
            </div>
        </div>
    );
};
