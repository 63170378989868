import { Store } from "pullstate";
import api from "../services/api";
import logger from "$services/logger";

import { buildFilters } from "$utils/api-helpers";

const initialState = {
    recommendations: [],
    scores: null,
    summary: null,
    topClients: [],
    trends: null,
};

export const ScoreCardStore = new Store(initialState);

/*export const fetchScoreCardSummary = async ({ client, industry, practice, office, geography }) => {
    const result = await api.get(`/api/score-cards/summary?${buildFilters({ Client: client, Industry: industry, Practice: practice, Office: office, Geography: geography })}`);
    ScoreCardStore.update((s) => {
        s.summary = result;
        return s;
    });
};*/
//const themes = ["Delivery Process", "Team Composition", "Cross Collaboration", "Business Administration", "Client Demographics", "External Market"];

const apiScoreCardScores = async ({ client, industry, practice, office, geography }, apiSettings) => {
    try {
        const result = await api.get(`/api/score-cards/scores?${buildFilters({ Client: client, Industry: industry, Practice: practice, Office: office, Geography: geography })}`, null, apiSettings);
        ScoreCardStore.update((s) => {
            s.summary = result;
            s.topClients = result.scores.map((s) => {
                return {
                    ...s,
                    valueByThemeId: s.themes.reduce((obj, t, i) => {
                        obj[t.id] = t.value;
                        return obj;
                    }, {}),
                };
            });
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch scorecard scores", e);
    }
};

export const fetchScoreCardScores = (params) => {
    const abortController = new AbortController();
    apiScoreCardScores(params, { signal: abortController.signal });
    return abortController;
};

// Unused
/*export const fetchScoreCardTrends = async () => {
    const result = await api.get("/api/score-cards/trends");
    ScoreCardStore.update((s) => {
        s.trends = result.trends;
        return s;
    });
};*/

const apiRecommendations = async ({ client, industry, scoreRange, geography, practice, office }, apiSettings) => {
    try {
        const result = await api.get(`/api/recommendations?${buildFilters({ Client: client, Industry: industry, ScoreRange: scoreRange, Geography: geography, Practice: practice, Office: office })}`, null, apiSettings);
        ScoreCardStore.update((s) => {
            s.recommendations = result.recommendations || [];
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch recommendations", e);
    }
};

export const fetchRecommendations = (params) => {
    const abortController = new AbortController();
    apiRecommendations(params, { signal: abortController.signal });
    return abortController;
};

export const resetScoreCard = () => {
    ScoreCardStore.update((s) => {
        return initialState;
    });
};
