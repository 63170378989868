import React from "react";
import { useNavigate } from "react-router-dom";

import TableLoader from "$components/table/table-loader";

import { ClientStore, nextPage, previousPage, gotoPage, setPageSize, setSort } from "$stores/clients";
import { defaultColumns } from "./columns";
import { mapClientToTableData } from "./mapper";

const ClientList = () => {
    const navigate = useNavigate();
    const { clients, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = ClientStore.useState((c) => {
        return { clients: c.clients, pageSize: c.pageSize, pageIndex: c.pageIndex, pageCount: c.pageCount, isLoading: c.isLoading, sort: c.sort, isSortAsc: c.isSortAsc };
    });

    const rowClick = (row) => {
        navigate(`/client/${row.original.key}`);
    };

    const data = clients.map(mapClientToTableData);

    return (
        <TableLoader
            containerClass="client-list"
            data={data}
            columns={defaultColumns}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            setSort={setSort}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={pageCount}
            isLoading={isLoading}
            sort={sort}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
        />
    );
};

export default ClientList;
