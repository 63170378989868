import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

import { useTable, useSortBy, usePagination } from "react-table";

import "./table-grid.scss";

const Table = ({ columns, data, nextPage, previousPage, gotoPage, pageCount, pageSize, pageIndex, setPageSize, setSort, showPagination, sort, isSortAsc }) => {
    const tableOptions = {
        columns,
        data,
        manualPagination: false,
        manualSortBy: false,
        pageCount: pageCount,
        initialState: {
            pageSize: pageSize,
            pageIndex,
            sortBy: [
                {
                    id: sort,
                    desc: !isSortAsc,
                },
            ],
        },
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state: { sortBy },
    } = useTable(tableOptions, useSortBy, usePagination);

    var totalWidth = 0;
    const HeaderGroups = headerGroups.map((headerGroup) => {
        const headers = headerGroup.headers.map((column) => {
            totalWidth = totalWidth + column.width;
            let sortArrow = null;
            if (column.isSorted) {
                sortArrow = column.isSortedDesc ? (
                    <div className="desc">
                        <FontAwesomeIcon icon={faSortDown} />
                    </div>
                ) : (
                    <div className="asc">
                        <FontAwesomeIcon icon={faSortUp} />
                    </div>
                );
            }
            return (
                <div {...column.getHeaderProps(column.getSortByToggleProps())} className={classnames("cell", { [column.className]: true, "-sortable": !column.disableSortBy })} style={{ flexBasis: `${column.width}px` }}>
                    {column.render("Header")}
                    {!column.disableSortBy && (
                        <span className="sort">
                            <FontAwesomeIcon icon={faSort} />
                            {sortArrow}
                        </span>
                    )}
                </div>
            );
        });
        return (
            <div {...headerGroup.getHeaderGroupProps()} className="row -header -sticky" style={{ minWidth: `${totalWidth}px` }}>
                {headers}
            </div>
        );
    });

    const Rows = rows.map((row, rowIndex) => {
        prepareRow(row);
        const Cells = row.cells.map((cell, cIndex) => {
            if (cell.column.enableRowSpan && rowIndex > 0) return null;
            const spannedStyle = cell.column.enableRowSpan && rowIndex === 0 ? { gridColumn: cIndex + 1, gridRow: `1 / span ${rows.length}` } : {};
            return (
                <div {...cell.getCellProps()} className={classnames("cell", { [cell.column.className]: cell.column.className })} style={{ flexBasis: `${cell.column.width}px`, ...spannedStyle }}>
                    {cell.render("Cell")}
                </div>
            );
        });
        return Cells;
    });

    return (
        <div className="table-grid" {...getTableProps()}>
            {HeaderGroups}
            <div {...getTableBodyProps()} className="row">
                {Rows}
            </div>
        </div>
    );
};

Table.defaultProps = {
    showPagination: true,
};

export default Table;
