import { Store } from "pullstate";
import api from "../services/api";
import logger from "../services/logger";

const initialState = {
    myGrowthPotential: null,
    firmGrowthPotential: null,
};

const apiDashboard = async (crmId, apiSettings) => {
    try {
        const response = await api.get(`/api/dashboard?crm=${crmId || 0}`, null, apiSettings);
        DashboardStore.update((s) => {
            s.myGrowthPotential = response?.growthPotential?.myGrowthPotential;
            s.firmGrowthPotential = response?.growthPotential?.firmGrowthPotential;
        });
    } catch (err) {
        logger.error("Unable to fetch dashboard data", err);
    }
};

export const fetchDashboard = (crmId) => {
    const abortController = new AbortController();
    apiDashboard(crmId, { signal: abortController.signal });

    return abortController;
};

export const DashboardStore = new Store(initialState);
