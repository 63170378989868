import React from "react";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from "recharts";

import EngineLoader from "$components/loaders/engine-loader";
import NoData from "./no-content";

import { COLOR_BASE, COLOR_POTENTIAL } from "$utils/colors";

const RadarPolarChart = ({ domain, data, gridStroke, labelKey, radarKeys }) => {
    if (!data) {
        return <EngineLoader />;
    }
    if (data.length === 0) {
        return <NoData />;
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="50%" cy="50%" outerRadius="60%" data={data} style={{ fill: "rgba(255, 255,255, 0.87)" }}>
                <PolarGrid stroke={gridStroke} />
                <PolarAngleAxis dataKey={labelKey} />
                <PolarRadiusAxis
                    domain={domain}
                    tickCount={6}
                    axisLine={false}
                    tickFormatter={(val) => {
                        return null;
                    }}
                />
                {radarKeys.map((r, i) => {
                    return <Radar key={`radar_${r.id || i}`} name={r.name || "Polar Chart"} dataKey={r.dataKey || "value"} stroke={r.color} fill={r.color} fillOpacity={0.25} />;
                })}
            </RadarChart>
        </ResponsiveContainer>
    );
};

RadarPolarChart.defaultProps = {
    labelKey: "label",
    domain: [0, 5],
    gridStroke: COLOR_BASE,
    radarKeys: [{ name: "Polar Chart", dataKey: "value", color: COLOR_POTENTIAL }],
};

export default RadarPolarChart;
