import React, { useEffect, useState } from "react";
import api from "../services/api";

import { REACT_APP_DOMAIN_URL } from "../services/env";
import { useDocumentTitle } from "$utils/document-title";

const EmailIndex = ({ match }) => {
    useDocumentTitle("Email");
    const selectedEmail = match.params.email;
    const [emailTemplate, setEmailTemplate] = useState(null);

    useEffect(() => {
        const fetchEmail = async () => {
            const template = await api.get(`/emails/${selectedEmail}`);
            if (!template) {
                return;
            }

            setEmailTemplate(template.replace(/#{DOMAIN_URL}/g, REACT_APP_DOMAIN_URL));
        };

        fetchEmail();
    }, [selectedEmail]);

    return <div dangerouslySetInnerHTML={{ __html: emailTemplate }}></div>;
};

export default EmailIndex;
