import React from "react";
import { format } from "date-fns";
import { buildDateFromString, SLASH_DATE_FORMAT } from "$utils/date-helpers";

const DateCell = ({ data, noValue }) => {
    if (!data || !data.value) {
        return <div>{noValue}</div>;
    }
    return <div>{format(buildDateFromString(data.value), SLASH_DATE_FORMAT)}</div>;
};

DateCell.defaultProps = {
    noValue: "-",
};

export default DateCell;
