import { retrieveValueSync } from "$services/local";

export function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = decodeURIComponent(
        atob(base64Url)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(base64);
}

export const isAllowedRole = (allowedRoles) => {
    const token = retrieveValueSync("user-token");
    if (!token) {
        return false;
    }
    if (!allowedRoles || allowedRoles.length < 1) {
        return true;
    }
    const grantedRoles = parseJwt(token).roles || [];

    if (!grantedRoles) {
        return false;
    }

    // Check roles
    for (var i = 0; i < allowedRoles.length; i++) {
        if (grantedRoles.includes(allowedRoles[i])) {
            return true;
        }
    }

    return false;
};

export const isAllowedPermission = (allowedPermissions) => {
    const token = retrieveValueSync("user-token");
    if (!token) {
        return false;
    }
    if (!allowedPermissions || allowedPermissions.length < 1) {
        return true;
    }
    const grantedPermissions = parseJwt(token).permissions;

    // If no granted permissions, no sense in trying
    if (!grantedPermissions || grantedPermissions.length <= 0) {
        return false;
    }

    // Check permissions
    for (var i = 0; i < allowedPermissions.length; i++) {
        if (grantedPermissions.includes(allowedPermissions[i]) < 0) {
            return true;
        }
    }
    return false;
};
