import React from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";

import FilterStats from "$components/filter-stats";
import InfoList from "$components/info-list";
import LeftPanel from "$components/left-panel";
import ToggleSwitch from "$components/toggle-switch";

import { AppContextStore } from "$stores/app-context";
import { TOOLTIP_TOP_POTENTIAL_PROJECTS } from "$utils/tooltips";
import GlobalDashboard from "$components/dashboard";
import RevenueStats from "$components/dashboard/components/revenue-stats";

const ProjectListLayout = () => {
    const { overview, overviewLoading } = AppContextStore.useState((s) => {
        return { overview: s.overview, overviewLoading: s.overviewLoading };
    });
    const location = useLocation();
    const isPotential = location.pathname === "/projects/potential";
    const title = isPotential ? "Potential" : "Existing";
    const tooltip = isPotential ? TOOLTIP_TOP_POTENTIAL_PROJECTS : null;

    return (
        <div id="project-list" className="page">
            <GlobalDashboard>
                <FilterStats />
            </GlobalDashboard>
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <div className="flex-header">
                            <h1 data-tooltip={tooltip}>
                                <em>{title}</em> Projects
                            </h1>
                            <ToggleSwitch options={[{ value: 0 }, { value: 1 }]} selectedIndex={!isPotential ? 1 : 0}>
                                <NavLink className={({ isActive }) => (isActive ? "item -active" : "item")} to="/projects/potential">
                                    Potential
                                </NavLink>
                                <NavLink className={({ isActive }) => (isActive ? "item -active" : "item")} to="projects/existing">
                                    Existing
                                </NavLink>
                            </ToggleSwitch>
                        </div>
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ProjectListLayout;
