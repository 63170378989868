import React, { useEffect, useRef } from "react";
import classNames from "classnames";

import { extractValue } from "$components/form-inputs/libs/helpers";
import { scrollIntoViewIfNeeded } from "$components/form-inputs/libs/scroll-into-view";

const SelectItem = ({ isActiveIndex, item, updateValues, values }) => {
    const dropdownItem = useRef();
    const hasChildren = item.options && item.options.length > 0;
    var isSelected = values.some((v) => extractValue(v) === extractValue(item));

    useEffect(() => {
        if (isActiveIndex) {
            scrollIntoViewIfNeeded(dropdownItem.current);
        }
    }, [isActiveIndex]);

    const options = hasChildren
        ? item.options.map((subItem) => {
              if (values.some((v) => extractValue(v) === extractValue(subItem))) {
                  isSelected = true;
              }
              return <SelectItem key={`select_${subItem.value}`} item={subItem} values={values} updateValues={updateValues} />;
          })
        : null;

    const manageOptions = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (isSelected) {
            // Perform deselect on all children
            updateValues(hasChildren ? item.options : [item], false);
        } else {
            // Add all children
            updateValues(item.options, true);
        }
    };
    const updateOnChange = (e) => {
        e.stopPropagation();
        e.preventDefault();

        updateValues([item], !isSelected);
    };

    return (
        <li ref={dropdownItem}>
            <a href="#selectItem" className={classNames({ "-active": isActiveIndex })} tabIndex="-1" aria-selected={isActiveIndex} role="option" onClick={hasChildren ? manageOptions : updateOnChange}>
                {isSelected && <span className="checkmark"></span>}
                {item.text}
            </a>
            {options && <ul aria-hidden="true">{options}</ul>}
        </li>
    );
};

export default SelectItem;
