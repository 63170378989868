import React from "react";

import DateCell from "$components/table/cells/date.js";
import InitialsCell from "$components/table/cells/initials";
import LargeNumber from "$components/info-list/content/large-number";

import { abbrNum } from "$utils/number-helper";

export const clientProjectsColumns = [
    {
        Header: "Project",
        accessor: "name", // accessor is the "key" in the data
        id: "name", // id is used as a sort key (i.e., actual api name)
    },
    {
        Header: "Project PIC",
        accessor: "projectPic", // accessor is the "key" in the data
        id: "projectPicName", // id is used as a sort key (i.e., actual api name)
        Cell: (data) => <InitialsCell data={data} />,
    },
    {
        Header: "Start Date",
        accessor: "startDate", // accessor is the "key" in the data
        id: "startDate", // id is used as a sort key (i.e., actual api name)
        className: "",
        sortDescFirst: true,
        Cell: (data) => <DateCell data={data} />,
    },
    {
        Header: "Last Date",
        accessor: "endDate", // accessor is the "key" in the data
        id: "endDate", // id is used as a sort key (i.e., actual api name)
        className: "",
        sortDescFirst: true,
        Cell: (data) => <DateCell data={data} noValue="Ongoing" />,
    },
    {
        Header: "Revenue To Date",
        accessor: "revenueToDate", // accessor is the "key" in the data
        id: "revenueToDate", // id is used as a sort key (i.e., actual api name)
        sortDescFirst: true,
        Cell: (data) => {
            return <LargeNumber value={`$${abbrNum(data.value)}`} isHealthy={true} />;
        },
    },
];

export const subclientProjectsColumns = [...clientProjectsColumns];
subclientProjectsColumns.splice(1, 0, {
    Header: "Sub Client",
    accessor: "clientName",
    id: "clientName",
});
