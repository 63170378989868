import { Store } from "pullstate";
import api from "../services/api";
import logger from "../services/logger";

import { clearCache, persistValue, retrieveValue, DEFAULT_TOKEN_TTL } from "../services/local";
import { loginRequest } from "../services/azure-auth/config";
import { parseJwt } from "$services/azure-auth/security";

const initialUserState = {
    id: null,
    displayName: null,
    firstName: null,
    lastName: null,
    email: null,
    title: null,
    headshotUri: null,
    lastLogin: null,
    welcomeMessage: null,
    isAuthed: false,
    lastDataUpdate: null,
    hasLoaded: false,
    hasNoAccess: false,
    hasRequestedAccess: false,
};

export const UserStore = new Store(initialUserState);

export const postToUserProfile = async () => {
    try {
        await api.post("/api/users/profile", {});
    } catch (ex) {
        logger.error(ex);
    }
};

export const getUserProfile = async (account) => {
    let user = { lastDataUpdate: Date.now(), profile: {}, hasNoAccess: false };
    try {
        let retrievedUser = await api.getFromCache("/api/users/profile");
        if (retrievedUser) {
            user = retrievedUser;
        }
    } catch (ex) {
        logger.error(ex);
        user.hasNoAccess = true;
        api.resetAxiosClient();
        persistValue("user-token", null);
        user.profile = {
            fullName: account.name,
        };
    }
    const authedUser = { ...user.profile, lastLogin: null, lastDataUpdate: user.lastDataUpdate, hasLoaded: true, isAuthed: !user.hasNoAccess, hasNoAccess: user.hasNoAccess };
    return authedUser;
};

export const logout = async () => {
    await clearCache();
    api.resetAxiosClient();
    persistValue("user-token", null);
    UserStore.update((s) => {
        return initialUserState;
    });
};

function RequestAccessToken(accounts, instance) {
    const request = {
        ...loginRequest,
        account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return new Promise((resolve, reject) => {
        instance
            .acquireTokenSilent(request)
            .then(async (response) => {
                await persistValue("user-token", response.accessToken, DEFAULT_TOKEN_TTL);
                api.resetAxiosClient(); // reset the client to use the new token
                resolve();
            })
            .catch((e) => {
                persistValue("user-token", null);
                reject("No access");
            });
    });
}

const fetchUserProfile = async (account) => {
    let userProfile = {};
    try {
        userProfile = await getUserProfile(account);
        await UserStore.update((user) => {
            return { ...user, ...userProfile, displayName: userProfile.fullName };
        });
    } catch (ex) {
        await UserStore.update((user) => {
            return { ...user, ...userProfile, displayName: userProfile.fullName };
        });
    }

    if (!!userProfile.id || userProfile.id === 0) {
        try {
            await postToUserProfile();
        } catch {
            return {};
        }
    }
};

export const retrieveExistingUser = async (inProgress, accounts, instance, hasRequestedAccess) => {
    /**
     * This block is for using an override token from environment variables in the development environemnt.
     * This is used for automated E2E testing.
     */
    if (process.env.NODE_ENV === "development" && !!process.env.REACT_APP_OVERRIDE_API_TOKEN && !!process.env.REACT_APP_OVERRIDE_USER_ACCOUNT) {
        const account = { username: process.env.REACT_APP_OVERRIDE_USER_ACCOUNT };
        await fetchUserProfile(account);
        await persistValue("user-token", process.env.REACT_APP_OVERRIDE_API_TOKEN);
        api.resetAxiosClient();
        return;
    }
    if (inProgress === "startup" || inProgress === "handleRedirect") {
        return;
    }

    if (window.location.pathname.toLowerCase() === "/logout") {
        await UserStore.update((user) => {
            return { ...user, hasLoaded: true };
        });
        return;
    }
    const token = await retrieveValue("user-token");
    if (token) {
        const readToken = parseJwt(token);
        const emailObj = readToken?.preferred_username ? { email: readToken?.preferred_username } : {};
        const userProfile = await getUserProfile({});
        await UserStore.update((user) => {
            return { ...userProfile, displayName: userProfile.fullName, ...emailObj };
        });
        return;
    }

    const existingUser = inProgress === "none" && accounts && accounts.length > 0;
    if (existingUser) {
        try {
            await RequestAccessToken(accounts, instance);
            const account = accounts[0] || {};

            await fetchUserProfile(account);
            return;
        } catch (ex) {
            await UserStore.update((user) => {
                return { ...user, hasLoaded: true };
            });
        }
    } else {
        await UserStore.update((user) => {
            return { ...user, hasLoaded: true };
        });
    }
};
