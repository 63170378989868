export const mainLinks = [
    {
        path: "/",
        label: "Dashboard",
    },
    {
        path: "/segments",
        label: "Segments",
    },
    {
        path: "/offices",
        label: "Offices",
    },
    {
        path: "/clients",
        label: "Clients",
    },
    {
        path: "/projects",
        label: "Projects",
    },
];

export const PAGES_WITH_FILTER = ["/segments", "/offices", "/clients", "/projects/potential", "/projects/existing"];
