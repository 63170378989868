import React from "react";
import { Link } from "react-router-dom";

const LegendLabel = ({ color, label, url }) => {
    if (url) {
        return (
            <Link to={url}>
                <span className="recharts-legend-item-text" style={{ color: color }}>
                    {label}
                </span>
            </Link>
        );
    }

    return (
        <span className="recharts-legend-item-text" style={{ color: color }}>
            {label}
        </span>
    );
};

export default LegendLabel;
