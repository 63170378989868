import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Link } from "react-router-dom";

const LinkInfoCell = ({ value, label, url }) => {
    const appInsights = useAppInsightsContext(
        { name: "Navigation" },
        {
            label: label,
            section: "Table Info",
        }
    );

    return (
        <>
            <h5>{label}</h5>
            <h4>
                {(
                    <Link
                        to={url}
                        onClick={() => {
                            appInsights.trackEvent;
                        }}
                    >
                        {value}
                    </Link>
                ) || "-"}
            </h4>
        </>
    );
};

export default LinkInfoCell;
