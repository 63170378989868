import _ from "lodash";
import React from "react";

const FilterSummary = ({ filters }) => {
    return (
        <div className="filter-summary">
            {Object.keys(filters).map((key) => {
                return (
                    <div key={key} className="txt -small -gray">
                        <b>{key}</b>: {_.isArray(filters[key]) ? filters[key].map((f) => f.text).join(", ") : "Unreadable"}
                    </div>
                );
            })}
        </div>
    );
};

export default FilterSummary;
