import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import ScoreBubble from "../score-bubble";
import LargeNumber from "./content/large-number";
import NoContent from "$components/no-content";

import "./info-list.scss";

const InfoItem = ({ children, reverse, scoreBubble, url }) => {
    return (
        <div className={classnames("info-item", { "-reverse": reverse })}>
            {url && (
                <NavLink className="btn-dropdown" to={url}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
            )}
            <div className="content">{children}</div>
            {scoreBubble && <ScoreBubble {...scoreBubble} />}
            {!scoreBubble && <div className="score-bubble -na">N/A</div>}
        </div>
    );
};

const InfoList = ({ data, reverse }) => {
    if (data.length <= 0) {
        return <NoContent />;
    }
    const items = data.map((d, i) => {
        return (
            <InfoItem key={`info-item_${i}`} reverse={reverse || d.reverse} scoreBubble={d.scoreBubble} url={d.url}>
                {d.graph ? <LargeNumber value={d.graph.value} label={d.graph.label} isHealthy={d.graph.isHealthy} /> : d.content}
            </InfoItem>
        );
    });
    return <div className="info-list">{items}</div>;
};

InfoList.defaultProps = {
    data: [],
};

export default InfoList;
