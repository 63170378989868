import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import ProfileIcon from "$components/profile-icon";

import { buildChatUrl } from "$utils/chat-type";

const TeamMember = ({ data }) => {
    const appInsights = useAppInsightsContext();
    const { name, email } = data;
    return (
        <div className="team-member">
            <ProfileIcon name={name} modifierClassNames={["-small"]} />
            <h6>{name}</h6>

            {email && (
                <div className="mailto">
                    <a
                        href={buildChatUrl("email", email)}
                        target="_blank"
                        onClick={() => {
                            appInsights.trackEvent(
                                { name: "Team Contact" },
                                {
                                    label: email,
                                    action: "email",
                                }
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                </div>
            )}
            {email && (
                <div className="message">
                    <a
                        href={buildChatUrl("teams", email)}
                        target="_blank"
                        onClick={() => {
                            appInsights.trackEvent(
                                { name: "Team Contact" },
                                {
                                    label: email,
                                    action: "teams",
                                }
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faMessage} />
                    </a>
                </div>
            )}
        </div>
    );
};

export default TeamMember;
