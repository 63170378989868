import React from "react";

import NoData from "$components/charts/no-content";
import ScoreLineChart from "$components/charts/score-line";

import { checkIsHealthy } from "$utils/health-helper";
import { ClientDetailsStore } from "$stores/client-details";

import "./health-history.scss";

const HealthHistory = () => {
    const { clientScoreHistory } = ClientDetailsStore.useState((s) => s.clientDetailsCharts);

    return (
        <div className="health-history">
            <h3 className="txt -center">
                <em>Overall</em> Health Score
            </h3>
            <div className="chart">
                {clientScoreHistory && clientScoreHistory.length > 0 ? (
                    <ScoreLineChart scoreHistory={clientScoreHistory} isHealthy={checkIsHealthy(clientScoreHistory[clientScoreHistory.length - 1].score)} />
                ) : (
                    <NoData />
                )}
            </div>
            <div className="content">
                <div className="intro">
                    Your Client Health Scores indicate whether your client is healthy or at risk based on leading indicators of client relationship health. Orgaimi measures monthly behavior changes across Themes to
                    highlight trends of healthy, growing clients or at risk, declining clients.
                </div>
                <ul className="definition-list">
                    <li>
                        <b>Scores:</b> Scores are on a 100-700 scale: lower scores indicate more risk, where higher scores predict more growth. Your overall Client Health Score compiles your client's performance across
                        all Themes and considers seasonality trends to provide a score. Each Theme has a sub-score to represent your client's performance in this particular area.
                    </li>
                    <li>
                        <b>Themes:</b> Themes are groups of Features that behave similarly to each other and target a similar positive client outcome.
                    </li>
                    <li>
                        <b>Features:</b> Features are specific behavioral trends of clients that suggest growth or risk.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default HealthHistory;
