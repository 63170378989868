export const TOOLTIP_GROWTH_POTENTIAL = "Quantification of how much value has yet to be untapped within each client account";
export const TOOLTIP_12_MONTH_REVENUE = "Last 12 months of net revenue from all existing clients in the firm with at least one month of billing activity in the last 3 years";
export const TOOLTIP_TOP_POTENTIAL_PROJECTS = "Untapped service opportunities for each client based on the client's pattern of services purchased with your firm, lifetime value of the client, and industry grouping";
export const TOOLTIP_AVERAGE_PROJECT_REVENUE = "Average revenue of projects with this Project Type for similar clients";
export const TOOLTIP_EXPANSION_PROBABILITY = "How frequently similar clients successfully expanded the client account with this Project Type";
export const TOOLTIP_EXPERIENCED_TEAM = "Directors, Partners, and Business Development Resources with the most experience with this client, industry, and project type";
export const TOOLTIP_RECOMMENDATIONS = "Validated trends over time that when implemented will increase likelihood of growth";
export const TOOLTIP_SEGMENTS = "Portions of the firm based on Industry, Geography, or Service Line";

// Health Score
export const TOOLTIP_HEALTH_SCORE = "Indication of how well your historical client management actions suggest growth potential for the future";
export const TOOLTIP_EXCELLENT = "Excellent Scores (500-700) indicate high performance in comparison to similar clients and high amounts of growth";
export const TOOLTIP_GOOD = "Good Scores (300-500) indicate moderate performance in comparison to similar clients and relatively stable growth";
export const TOOLTIP_REVIEW = "Review Scores (100-300) indicate performance consistent with clients who have declined in the past and highlight decline risk";

// Themes
export const TOOLTIP_DELIVERY_PROCESS = "Indicators about project management, team processes, and how the work is being delivered";
export const TOOLTIP_TEAM_COMPOSITION = "Indicators about team size, roles, and experiences";
export const TOOLTIP_CROSS_COLLABORATION = "Indicators about relationship networks, interactions across the firm, and practice, industry, and geographic collaboration";
export const TOOLTIP_CLIENT_DEMOGRAPHICS = "Indicators about the firmographics of the client, marketing behavior, and relationship factors";
export const TOOLTIP_EXTERNAL_MARKET = "Indicators about market and industry trends that may limit or enhance growth opportunity";
export const TOOLTIP_BUSINESS_ADMINISTRATION = "Indiciators about the financial, contractual, or administrative client dynamics";
export const TOOLTIP_THEMES = {
    DELIVERY_PROCESS: TOOLTIP_DELIVERY_PROCESS,
    TEAM_COMPOSITION: TOOLTIP_TEAM_COMPOSITION,
    CROSS_COLLABORATION: TOOLTIP_CROSS_COLLABORATION,
    CLIENT_DEMOGRAPHICS: TOOLTIP_CLIENT_DEMOGRAPHICS,
    EXTERNAL_MARKET: TOOLTIP_EXTERNAL_MARKET,
    BUSINESS_ADMINISTRATION: TOOLTIP_BUSINESS_ADMINISTRATION,
};

export const convertThemeToTooltip = (theme) => {
    return TOOLTIP_THEMES[theme.toUpperCase().replace(/\s|-/g, "_")] || null;
};
