import React, { useContext } from "react";
import classnames from "classnames";

import FilterMenu from "../../filter-menu";
import MainNav from "./main-nav";
import OfficePanel from "../../map/office-panel";
import GrowthPotentialPanel from "../../growth-potential-panel";

import { PanelContext } from "../../../contexts/panels";
import { AppContextStore } from "../../../stores/app-context";

const UserNav = () => {
    const { panels, togglePanel } = useContext(PanelContext);
    const showFilter = AppContextStore.useState((s) => s.showFilter);
    const filterCount = AppContextStore.useState((s) => s.currentFiltersCount);
    const potentialCount = 0;

    return (
        <>
            <MainNav />
            <div className="utility-nav">
                <ul>
                    <li className={classnames({ "-selected": window.location.pathname.startsWith("/help") })}>
                        <a href="/help">
                            <img alt="Help" src="/images/icon-info.svg" />
                        </a>
                    </li>
                    <li className={classnames({ "-selected": panels.growthPotential })}>
                        <a
                            href="#growthPotential"
                            onClick={(e) => {
                                e.preventDefault();
                                togglePanel("growthPotential");
                            }}
                        >
                            <img alt="Priorities Option" src="/images/icon-priorities.svg" />
                            {potentialCount > 0 && <div className="pill-count">{potentialCount}</div>}
                        </a>
                    </li>
                    {showFilter && (
                        <li className={classnames({ "-selected": panels.filters })}>
                            <a
                                href="#filter-menu"
                                onClick={(e) => {
                                    e.preventDefault();
                                    togglePanel("filters");
                                }}
                            >
                                <img alt="Filter Option" src="/images/icon-filter.svg" />
                                {filterCount > 0 && <div className="pill-count">{filterCount}</div>}
                            </a>
                            <FilterMenu isActive={panels.filters} />
                        </li>
                    )}
                    {/*<li>
                        <a href="#layer-menu">
                            <img alt="Layers Option" src="/images/layers-not-selected.svg" />
                        </a>
                    </li>*/}
                </ul>
            </div>
            <GrowthPotentialPanel />
            <OfficePanel />
        </>
    );
};

export default UserNav;
