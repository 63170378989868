import TypeaheadStore from "./base/TypeaheadStore";

const { store, search, updateSelectedResults } = TypeaheadStore({
    key: "clients",
    searchApiEndpoint: "/api/clients/search",
    responseHandler: (response) => {
        return response && response["data"] && response["data"].length > 0
            ? response["data"].map((e) => {
                  return {
                      value: e.id,
                      text: e.name,
                  };
              })
            : null;
    },
});

const ClientFilterStore = store;

export { ClientFilterStore, search, updateSelectedResults };
