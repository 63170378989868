import React from "react";

import StatWithChange from "./stat";

import "./revenue-stats.scss";

const RevenueStats = ({ data }) => {
    return (
        <div className="revenue-stat-list">
            <StatWithChange label="12-Month Revenue" value={data?.twelveMonthRevenue} increase={Math.round(data?.twelveMonthRevenueChangePercent || 0)} isHealthy={true} />
            <StatWithChange label="Growth Potential" value={data?.growthPotential} increase={Math.round(data?.growthPotentialChangePercent || 0)} />
            <StatWithChange label="Revenue At Risk" value={data?.revenueAtRisk} increase={data?.revenueAtRiskChangePercent} />
        </div>
    );
};

export default RevenueStats;
