export const dealProbabilityText = (percent) => {
    if (isNaN(percent)) {
        return "N/A";
    } else if (percent <= 33) {
        return "Low";
    } else if (percent <= 66) {
        return "Medium";
    } else {
        return "High";
    }
};
