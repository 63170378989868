import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { deleteSavedFilter } from "$stores/app-context";
import { dialogConfirm } from "$components/dialog/confirm";
import FilterSummary from "./filter-summary";

const FilterItem = ({ id, name, filters }) => {
    const handleDelete = async () => {
        const confirmSave = await dialogConfirm({
            message: (
                <p className="txt -center">
                    Are you sure you want to remove the filter:
                    <br />
                    <br />
                    <span className="txt -white">{name}</span>
                </p>
            ),
            cssClass: "-confirm",
        });
        if (confirmSave) {
            await deleteSavedFilter(id);
        }
    };

    return (
        <div className="filter-item" key={id}>
            <div>
                <h3>{name}</h3>
                <FilterSummary filters={filters} />
            </div>
            <button className="button -text -right" onClick={handleDelete}>
                <FontAwesomeIcon icon={faTrash} />
            </button>
        </div>
    );
};

export default FilterItem;
