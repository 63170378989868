import RevenueStats from "$components/dashboard/components/revenue-stats";
import { object } from "@amcharts/amcharts4/core";
import React, { useState, useEffect } from "react";
import { AppContextStore } from "../../stores/app-context";
import { CrmStore } from "../../stores/crm-filter";
import FilterBox from "../filter-box";
import ViewingFor from "./components/viewing-for";

import "./filter-stats.scss";

const FilterStats = () => {
    const { currentFilters, selectedSavedFilter } = AppContextStore.useState((f) => {
        return { currentFilters: f.currentFilters || {}, selectedSavedFilter: f.selectedSavedFilter };
    });
    const { overview, overviewLoading } = AppContextStore.useState((s) => {
        return { overview: s.overview, overviewLoading: s.overviewLoading };
    });
    const currentFiltersCount = AppContextStore.useState((f) => f.currentFiltersCount || 0);
    const crmList = CrmStore.useState((f) => f.selectedResults);
    const filterMenu = AppContextStore.useState((f) => f.filterMenu || {});
    const [viewingFor, setViewingFor] = useState("");

    useEffect(() => {
        if (currentFiltersCount === 0) {
            setViewingFor("All Clients");
        } else if (selectedSavedFilter) {
            setViewingFor(`${selectedSavedFilter} Fitler`);
        } else if (currentFiltersCount === 1) {
            try {
                const filterKey = object.keys(currentFilters).filter((f) => currentFilters[f] && currentFilters[f].length > 0)[0];
                if (filterKey === "crm") {
                    setViewingFor(crmList[0].text);
                } else {
                    const foundFilter = filterMenu.find((f) => f.key === filterKey);
                    if (foundFilter) {
                        const foundFilterItem = foundFilter.values.find((v) => v.value.toString() === currentFilters[filterKey][0].toString());
                        if (foundFilterItem) {
                            setViewingFor(`${foundFilterItem.text} ${foundFilter.label}`);
                        } else {
                            setViewingFor(foundFilter.label);
                        }
                    } else {
                        setViewingFor("One Filter");
                    }
                }
            } catch {
                setViewingFor("One Filter");
            }
        } else if (currentFiltersCount > 1) {
            setViewingFor("Multiple Filters");
        }
    }, [currentFilters, currentFiltersCount, crmList, filterMenu, selectedSavedFilter]);
    return (
        <div className="filter-stats">
            <ViewingFor label={viewingFor} />
            <FilterBox />
            <RevenueStats data={overview?.data} />
        </div>
    );
};

export default FilterStats;
