import React, { useEffect, useState } from "react";
import _ from "lodash";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import BarComparison from "$components/charts/bar-comparison";
import Button from "$components/button";
import ChartPie from "$components/charts/pie";
import ExternalLegend from "$components/charts/legend/external";
import ProbabilityBubble from "$components/probability";
import RadarPolarChart from "$components/charts/radar-chart";
import SemiCirclePie from "$components/charts/semi-circle-pie";
import StatWithChange from "$components/dashboard/components/stat";
import ScoreBubble from "$components/score-bubble";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { checkIsHealthy } from "$utils/health-helper";
import { ChartsStore, fetchCharts } from "$stores/charts";
import { aggregateChildren, limitChartData } from "../../lib/chart-helper";
import { roundAboveOne } from "$utils/number-helper";
import { useLocation } from "react-router-dom";
import { COLOR_FONT, COLOR_GRAY, COLOR_POTENTIAL } from "$utils/colors";
import { LabelPercentTooltip } from "$components/charts/tooltips/label-percent";

const HealthContent = ({ id, subClient, totalPercentOfRevenue }) => {
    const location = useLocation();
    const appInsights = useAppInsightsContext();
    const [showHidden, setShowHidden] = useState(false);
    const [charts, setCharts] = useState({});
    const { clientGroupThemeScoreByThemeId } = ChartsStore.useState((c) => {
        return {
            clientGroupThemeScoreByThemeId: (c.charts["clientGroupThemeScore"] || []).reduce((obj, t, i) => {
                obj[t.labelId] = t.value;
                return obj;
            }, {}),
        };
    });

    useEffect(() => {
        const hash = location.hash;
        if (hash && hash.replace("#client_", "") === subClient.id.toString() && !showHidden) {
            toggleHidden();
        }
    }, [location]);

    const toggleHidden = async () => {
        setShowHidden(!showHidden);
        if (!showHidden && _.isEmpty(charts)) {
            const newCharts = await fetchCharts({ client: subClient.id }, [
                "t12TotalRevenueByServiceLine",
                "t12TotalRevenueByProjectType",
                { key: "growthByServiceLine", groupChildrenFrom: "growthByProjectType" },
                "growthByProjectType",
                { key: "clientThemeScore", sortBy: "labelId" },
            ]);
            setCharts({
                t12TotalRevenueByServiceLine: limitChartData(newCharts["t12TotalRevenueByServiceLine"]),
                t12TotalRevenueByProjectType: limitChartData(newCharts["t12TotalRevenueByProjectType"]),
                growthByServiceLine: limitChartData(newCharts["growthByServiceLine"]),
                growthByProjectType: limitChartData(newCharts["growthByProjectType"]),
                clientThemeScore: newCharts["clientThemeScore"].map((t, i) => {
                    return {
                        label: t.label,
                        clientGroup: clientGroupThemeScoreByThemeId[t.labelId] || 0,
                        client: t.value,
                    };
                }),
            });
        }

        appInsights.trackEvent(
            { name: "Show More" },
            {
                label: subClient?.name,
                action: !showHidden ? "close" : "open",
            }
        );
    };

    return (
        <div name={`client_${subClient.id}`} className="subclients-panel">
            <img className="theme-image" src="/images/theme-cross-collaboration.svg" alt={id} />
            <div className="title" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="subclient-dashboard">
                    <div className="client-logo">
                        <h2>{subClient.name}</h2>
                    </div>
                    {!!subClient?.revenueAtRisk && <StatWithChange label="Revenue At Risk" value={subClient.revenueAtRisk} increase={undefined} />}
                    <div className="divide"></div>
                    <div className="stat-group">
                        <div>
                            <ScoreBubble graph={true} value={subClient?.healthScore} healthy={checkIsHealthy(subClient?.healthScore)} percent={(subClient?.healthScore / 700) * 100} modifierClassNames={["-small"]} />
                            <h4 className="txt -center">Health</h4>
                        </div>
                        &nbsp; &nbsp;
                        <div>
                            <ProbabilityBubble value={subClient?.sviScore * 10} cssClasses={["-importance"]} />
                            <h4 className="txt -center">SVI</h4>
                        </div>
                    </div>
                    <div className="divide"></div>
                    <div className="percent-revenue">
                        <div>
                            <SemiCirclePie
                                outerRadius={56}
                                innerRadius={45}
                                TooltipComponent={LabelPercentTooltip}
                                data={[
                                    { id: 1, text: subClient.name, value: subClient.portionOfRevenue },
                                    { id: 0, text: "Other", value: totalPercentOfRevenue - subClient.portionOfRevenue },
                                    { id: 2, text: "Churned", value: 100 - totalPercentOfRevenue },
                                ]}
                                colors={["#de653a", "#1c3642", COLOR_GRAY]}
                                totalValue={`${roundAboveOne(subClient.portionOfRevenue)}%`}
                            />
                        </div>
                        <h4 className="txt -center">Portion of Revenue</h4>
                    </div>
                </div>
            </div>
            <br />
            <Button className={classnames("button -text", { "-active": showHidden })} onClick={toggleHidden}>
                <FontAwesomeIcon icon={faChevronDown} />
            </Button>
            <div className={classnames("hidden-content", { "-active": showHidden })}>
                <div className="dashboard-cards">
                    <div className="card -span2">
                        <h4 className="txt -center -white">12-Month Revenue By Service Line</h4>
                        <div className="chart-legend">
                            <BarComparison data={charts["t12TotalRevenueByServiceLine"]} />
                        </div>
                    </div>
                    <div className="card -span2">
                        <h4 className="txt -center -white -white">12-Month Revenue By Project Types</h4>
                        <div className="chart-legend">
                            <BarComparison data={charts["t12TotalRevenueByProjectType"]} />
                        </div>
                    </div>
                    <div className="card -span2">
                        <h4 className="txt -center -white">Growth Potential By Service Line & Project Type</h4>
                        <div className="chart-legend">
                            <ChartPie data={charts["growthByServiceLine"]} outerRadius={105} innerRadius={50} data2={aggregateChildren(charts["growthByServiceLine"])} outerRadius2={170} innerRadius2={115} />
                            {/*<ExternalLegend data={growthPotentialByProjectTypes} />*/}
                        </div>
                    </div>
                    <div className="card -span2">
                        <h4 className="txt -center">Theme Scores</h4>
                        <div className="chart-radar">
                            <RadarPolarChart
                                data={charts["clientThemeScore"]}
                                radarKeys={[
                                    { dataKey: "clientGroup", color: COLOR_FONT },
                                    { dataKey: "client", color: COLOR_POTENTIAL },
                                ]}
                            />
                            <ExternalLegend
                                cssClasses={["-center"]}
                                data={[
                                    { text: "Parent Client", color: COLOR_FONT },
                                    { text: "Sub Client", color: COLOR_POTENTIAL },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthContent;
