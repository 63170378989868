import React from "react";
import api from "$services/api";
import logger from "$services/logger";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Select from "$components/form-inputs/select";

import { Textarea } from "$components/form-inputs/inputs";
import { useInput } from "$utils/hooks/use-input";

import "./feedback.scss";

const FEEDBACK_CHARACTER_LIMIT = 250;

const Feedback = ({ id, projectKey, onSubmit }) => {
    const appInsights = useAppInsightsContext();
    const reasonSelect = useInput("");
    const additionalInput = useInput("");

    const sendFeedback = async () => {
        if (!reasonSelect.value) {
            toast("Please Select A Reason");
            return false;
        }
        if (additionalInput.value.length > FEEDBACK_CHARACTER_LIMIT) {
            toast("Additional Information Is too Long");
            return false;
        }
        const payload = {
            reason: reasonSelect.value.text,
            feedback: additionalInput.value,
        };

        try {
            const res = await api.put(`/api/projects/potential/${id}/user-flag/${projectKey}`, payload);

            if (res) {
                additionalInput.reset();
                reasonSelect.reset();

                toast("Feedback Sent Successfully!");

                if (onSubmit) {
                    onSubmit();
                }
            } else {
                toast("Error: Feed Back Not Recorded, Please Try Again");
            }
        } catch (e) {
            toast("Error: Feed Back Not Recorded, Please Try Again");
            logger.log(e);
            throw e; // throw error to be handled by feedback form
        }

        appInsights.trackEvent(
            { name: "User Feedback" },
            {
                label: type.toUpperCase(),
                action: "Sumbitted",
            }
        );
    };

    const characterCount = additionalInput.value.length;
    const charactersLeft = FEEDBACK_CHARACTER_LIMIT - characterCount;

    return (
        <div className="feedback">
            <h5>Feedback</h5>
            <p>Please let us know if there is an issue or concern with this project.</p>
            <div>
                <Select
                    label="Reason*"
                    options={[
                        { value: 1, text: "Potential Project is not applicable to this client based on entity/ownership." },
                        { value: 2, text: "Potential Project is not appropriate right now, but could be in 6-12 months." },
                        { value: 3, text: "Value of the Potential Project is too low for consideration." },
                        { value: 4, text: "This work requires independence, and the current work is more valuable than the potential opportunity." },
                        { value: 5, text: "Other" },
                    ]}
                    {...reasonSelect.bind}
                    defaultValue={reasonSelect.value}
                />
            </div>

            <Textarea placeholder="Additional Information" {...additionalInput.bind}></Textarea>
            <div className="button-group">
                <div>
                    <span className="txt -small -detail-dark">* Required</span>
                    {characterCount >= FEEDBACK_CHARACTER_LIMIT - 100 && (
                        <div className={classNames("txt -small", { "-error": charactersLeft < 0 })}>{`${FEEDBACK_CHARACTER_LIMIT - additionalInput.value.length} characters remaining`}</div>
                    )}
                </div>
                <button className="button -primary -small -right" onClick={sendFeedback}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default Feedback;
