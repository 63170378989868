import { Store } from "pullstate";

export const SelectedSubclientStore = new Store(null);

export const setSelectedSubclient = (subclient) => {
    return SelectedSubclientStore.update((s) => {
        s = subclient;
        return s;
    });
};
