import React from "react";
import FilterCount from "./filter-count";

const ViewingFor = ({ label, hideFilters }) => {
    return (
        <div className="viewing-for">
            <p className="txt -condensed -detail-dark -bold -uppercase">
                Viewing For: <span className="txt -light -white">{label}</span>
            </p>

            {!hideFilters && <FilterCount />}
        </div>
    );
};

export default ViewingFor;
