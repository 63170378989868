export const TOP5_SUBCLIENT_TITLES = {
    ClientsSVIScore: {
        header: (
            <h4 className="txt -center">
                by <em>SVI Score</em>
            </h4>
        ),
        type: "number",
    },
    ClientsRevenueAtRisk: {
        header: (
            <h4 className="txt -center">
                by <em>Revenue At Risk</em>
            </h4>
        ),
        type: "currency",
    },
};
