import { Store } from "pullstate";
import stringify from "json-stable-stringify";
import { SHA256 } from "crypto-js";

import logger from "$services/logger";
import api from "$services/api";

import { clearValue } from "../../services/local";
import { capitalizeFirstLetter } from "../../utils/text-helpers";
import { buildFilters, buildSort } from "../../utils/api-helpers";
import { updateGlobalError } from "../app-context";

const PaginatedStore = ({ label, key, defaultSort, storageKey, apiListUrl }) => {
    const initialState = {
        [key]: [],
        [`total${capitalizeFirstLetter(key)}`]: 0,
        filters: [],
        pageIndex: 0,
        pageSize: 10,
        pageCount: 1,
        isLoading: true,
        canNextPage: true,
        canPreviousPage: false,
        currentFilters: {},
        filterHash: "",
        paginationHash: "",
        sort: defaultSort,
        isSortAsc: true,
    };

    const store = new Store(initialState);

    const apiList = async (filters, { page, limit = 10, sort, isSortAsc } = {}, apiSettings = {}) => {
        let filterHash;
        let paginationHash;
        await store.update((s) => {
            filterHash = SHA256(stringify(filters)).toString();
            paginationHash = SHA256(stringify({ page, limit, sort, isSortAsc })).toString();
            const isFilterChanged = !(s.filterHash.length > 0 && filterHash === s.filterHash);
            s.isLoading = true;
            s.pageIndex = isFilterChanged ? 0 : page || 0;
            return s;
        });

        try {
            logger.log(`Fetching ${apiListUrl} list`);
            // TODO: Work out cache
            const response = await api.get(`${apiListUrl}?page=${page || 0}&limit=${limit}&sort=${buildSort(sort, isSortAsc)}&${buildFilters(filters)}`, null, apiSettings);
            //const response = await api.getFromCache(`${apiListUrl}?page=${page || 0}&limit=${limit}&sort=${buildSort(sort, isSortAsc)}&${buildFilters(filters)}`, null, cancel?.token);
            store.update((s) => {
                const list = response.data;
                const totalCount = response.totalCount;
                s[key] = list;
                s[`total${capitalizeFirstLetter(key)}`] = totalCount;
                s.isLoading = false;
                s.currentFilters = filters;
                s.filterHash = filterHash;
                s.paginationHash = paginationHash;
                s.pageCount = Math.ceil(totalCount / limit) || 1;
                return s;
            });
        } catch (e) {
            if (api.requestWasCanceled({ ...e })) {
                return;
            }
            updateGlobalError(`Unable to fetch ${label || key}`);
            logger.error(`Unable to fetch ${key}`, { ...e });
        }
    };

    const fetchList = (filters, pagingInfo = {}) => {
        const abortController = new AbortController();
        apiList(filters, pagingInfo, { signal: abortController.signal });
        return abortController;
    };

    const setLoading = () => {
        store.update((s) => {
            s.isLoading = true;
            return s;
        });
    };

    const reset = () => {
        clearValue(storageKey);
        store.update((s) => {
            return initialState;
        });
    };

    const nextPage = () => {
        clearValue(storageKey);
        store.update((s) => {
            s.pageIndex = s.pageIndex + 1;
            return s;
        });
    };

    const previousPage = () => {
        clearValue(storageKey);
        store.update((s) => {
            s.pageIndex--;
            return s;
        });
    };

    const gotoPage = (page) => {
        clearValue(storageKey);
        store.update((s) => {
            s.pageIndex = page;
            return s;
        });
    };

    const setPageSize = (size) => {
        clearValue(storageKey);
        store.update((s) => {
            s.pageSize = size;
            return s;
        });
    };

    const setFilters = (filters) => {
        store.update((s) => {
            s.filters = filters;
            return s;
        });
    };

    const setSort = (field, isSortAsc) => {
        store.update((s, org) => {
            let update = false;
            if (org.sort !== field) {
                s.sort = field;
                s.pageIndex = 0;
                update = true;
            }
            if (org.isSortAsc !== isSortAsc) {
                update = true;
                s.pageIndex = 0;
                s.isSortAsc = isSortAsc;
            }
            if (update) {
                clearValue(storageKey);
                return s;
            }
        });
    };

    const watchPagination = () => {
        const unsubs = [];

        return unsubs;
    };

    return {
        initialState,
        store,
        fetchList,
        setLoading,
        reset,
        nextPage,
        previousPage,
        gotoPage,
        setFilters,
        setPageSize,
        setSort,
        watchPagination,
    };
};

export default PaginatedStore;
