import React, { useState } from "react";
import classNames from "classnames";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";
import { faChevronRight, faComment, faFlag, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { toast } from "react-toastify";

import Comments from "$components/comments";
import CornerRibbon from "$components/corner-ribbon";
import Feedback from "$components/feedback";
import Flags from "$components/flags";
import Modal from "$components/modal";
import NoData from "$components/charts/no-content";
import ProbabilityBubble from "$components/probability";
import SalesforceOpportunityForm from "$components/salesforce/form";
import TeamMember from "$components/profile-icon/team-member";

import { dealProbabilityText } from "$utils/probability-text";
import { abbrNum, getNumberRange } from "$utils/number-helper";
import { TOOLTIP_AVERAGE_PROJECT_REVENUE, TOOLTIP_EXPANSION_PROBABILITY, TOOLTIP_EXPERIENCED_TEAM } from "$utils/tooltips";
import { fetchPotentialProjects } from "$stores/client-details";

import "./potential-projects.scss";

const ProjectPanel = ({ index, project, type }) => {
    const { clientId } = useParams();
    const appInsights = useAppInsightsContext();
    const [showComments, setShowComments] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [showSalesforce, setShowSalesforce] = useState(false);
    const [commentCountText, setCommentCountText] = useState(0);
    const revenueVariance = getNumberRange(project.averageRevenue);

    const toggleSalesforce = () => {
        if (project.hasSalesforceOpportunityCreated) {
            toast("This project has already been sent to Salesforce");
            return;
        }
        setShowSalesforce(!showSalesforce);
        if (showFeedback) {
            setShowFeedback(false);
        }
        if (showComments) {
            setShowComments(false);
        }
    };

    const toggleComments = () => {
        setShowComments(!showComments);
        if (showFeedback) {
            setShowFeedback(false);
        }
        if (showSalesforce) {
            setShowSalesforce(false);
        }
    };

    const toggleFeedback = () => {
        setShowFeedback(!showFeedback);
        if (showComments) {
            setShowComments(false);
        }
        if (showSalesforce) {
            setShowSalesforce(false);
        }
    };

    const trackProjectClick = () => {
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: `(${index}) Project`,
                section: "Potential Projects",
            }
        );
    };

    const updateCommentCount = (countText) => {
        setCommentCountText(countText);
    };

    const onSalesforceSubmit = () => {
        toggleSalesforce(false);
        fetchPotentialProjects({ client: clientId });
    };

    return (
        <div className="potential-project">
            <header>
                <CornerRibbon text={index} />
                <Flags data={[{ classnames: { "-independence": project?.requiresIndependence }, label: "Check Independence" }]} />
                <h3>
                    {project.name}
                    <span>
                        <em>Sub Client:</em> {project.clientName}
                    </span>
                </h3>
                <NavLink to={`/project/${project.id}/potential`} onClick={trackProjectClick}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
            </header>
            <div className="panel-content">
                {type === "revenue" && (
                    <>
                        <h4>
                            <em>
                                <sup>$</sup>
                                {abbrNum(revenueVariance.low, false)}&ndash;{abbrNum(revenueVariance.high)}
                                {false && (
                                    <sup>
                                        {" "}
                                        <FontAwesomeIcon icon={faExclamationTriangle} />
                                    </sup>
                                )}
                            </em>
                        </h4>
                        <h5 data-tooltip={TOOLTIP_AVERAGE_PROJECT_REVENUE}>Average Revenue</h5>
                        <hr />
                        <h5 data-tooltip={TOOLTIP_EXPANSION_PROBABILITY}>
                            Expansion Probability: <em>{dealProbabilityText(project.probability)}</em>
                        </h5>
                    </>
                )}

                {type === "probability" && (
                    <>
                        <div data-tooltip={TOOLTIP_EXPANSION_PROBABILITY}>
                            <ProbabilityBubble label="Expansion Probability" value={project.probability} />
                        </div>
                        <hr />
                        <h5 data-tooltip={TOOLTIP_AVERAGE_PROJECT_REVENUE}>
                            Average Revenue:{" "}
                            <em>
                                <sup>$</sup>
                                {abbrNum(revenueVariance.low, false)}&ndash;{abbrNum(revenueVariance.high)}
                            </em>
                        </h5>
                    </>
                )}

                <hr />
                <h5>
                    Service Line: <em className="txt -white">{project.serviceLine}</em>
                </h5>
                <hr />
                <h5>
                    Industry: <em className="txt -white">{project.industry}</em>
                </h5>
                <hr />
                <h5>Customer Relations Manager</h5>
                <div className="team-members">
                    <TeamMember data={{ name: project.crmName, email: project.crmEmail }} />
                </div>
                <h5 data-tooltip={TOOLTIP_EXPERIENCED_TEAM} style={{ marginTop: "20px" }}>
                    Experienced Team Members
                </h5>
                <div className="team-members">
                    {project.team.map((m, i) => (
                        <TeamMember key={`member-${i}`} data={m} />
                    ))}
                </div>
                <div className={classNames("comments-overlay", { "-active": showComments })}>{project?.projectKey && <Comments projectKey={project.projectKey} updateCommentCount={updateCommentCount} />}</div>
                <div className={classNames("feedback-overlay", { "-active": showFeedback })}>
                    <Feedback
                        id={project.id}
                        projectKey={project.projectKey}
                        onSubmit={() => {
                            setShowFeedback(false);
                            fetchPotentialProjects({ client: clientId });
                        }}
                    />
                </div>
                <Modal
                    show={showSalesforce}
                    onClose={() => {
                        toggleSalesforce(false);
                    }}
                >
                    <SalesforceOpportunityForm clientId={project.clientId} currentProject={project} projectKey={project.projectKey} afterSubmit={onSalesforceSubmit} />
                </Modal>
            </div>
            <footer className="dialogue-menu">
                <div className="comments">
                    <button className={classNames("button -text", { "-active": showComments })} onClick={toggleComments}>
                        <FontAwesomeIcon icon={faComment} /> {commentCountText}
                    </button>
                </div>
                <div className="button-group">
                    {!project.userFlagged && (
                        <button className={classNames("button -text", { "-active": showFeedback })} onClick={toggleFeedback}>
                            <FontAwesomeIcon icon={faFlag} />
                        </button>
                    )}
                    <button className={classNames("button -text", { "-active": showSalesforce, "-disabled": project.hasSalesforceOpportunityCreated })} onClick={toggleSalesforce}>
                        <FontAwesomeIcon icon={faSalesforce} />
                    </button>
                </div>
            </footer>
        </div>
    );
};

const PotentialProjects = ({ projects, type }) => {
    if (!projects || projects.length <= 0) {
        return <NoData />;
    }
    return (
        <div className="potential-projects">
            {!projects && <h3 className="txt -center -gray">Well done! This client has no potential projects at this time. Check back next month!</h3>}
            {projects && projects.map((p, i) => <ProjectPanel key={p.id} index={i + 1} project={p} type={type} />)}
        </div>
    );
};

export default PotentialProjects;
