import React from "react";
import { CrmStore, search, updateSelectedResults } from "../../../stores/crm-filter";
import Typeahead from "../../form-inputs/typeahead";

const PicSearch = () => {
    const options = CrmStore.useState((f) => f.results);
    const selectedOptions = CrmStore.useState((f) => f.selectedResults);
    return (
        <Typeahead
            name="revenueCRM"
            label="Filter By PIC"
            cssClass="-full"
            options={options}
            value={selectedOptions}
            optionLookup={search}
            updateOnChange={(name, newValues) => {
                updateSelectedResults(newValues);
            }}
        />
    );
};

export default PicSearch;
