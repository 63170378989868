import React from "react";
import InitialsCell from "../../../../components/table/cells/initials";
import { formatCurrency, formatNumberWithCommas } from "../../../../utils/number-helper";
import Table from "./components/table";

const defaultColumns = [
    {
        Header: "Team Member",
        accessor: "name", // accessor is the "key" in the data
        id: "name", // id is used as a sort key (i.e., actual api name)
        Cell: (row) => <InitialsCell data={row} />,
    },
    {
        Header: "Role",
        accessor: "role",
        id: "role",
        Cell: (row) => {
            return <span className="txt -xsmall -gray">{row.value}</span>;
        },
    },
    {
        Header: "Hours Billed",
        accessor: "hoursBilled",
        id: "hoursBilled",
        Cell: (row) => {
            return <h4 className="txt -light -white">{formatNumberWithCommas(row.value)}</h4>;
        },
    },
    {
        Header: "Hours Billed TD",
        accessor: "totalHoursBilled",
        id: "totalHoursBilled",
        enableRowSpan: true,
        disableSortBy: true,
        Cell: (row) => {
            return <h2 className="txt -white -no-margin">{formatNumberWithCommas(row.value)}</h2>;
        },
    },
    {
        Header: "Revenue TD",
        accessor: "revenue",
        id: "revenue",
        enableRowSpan: true,
        disableSortBy: true,
        Cell: (row) => {
            return (
                <h2 className="txt -healthy -no-margin">
                    <sup>$</sup>
                    {formatCurrency(row.value)}
                </h2>
            );
        },
    },
];

const BillingByTeam = ({ data, revenue, hours }) => {
    const billingData = data.map((i) => {
        return { ...i, revenue, totalHoursBilled: hours };
    });

    return (
        <div className="billing-by-team">
            <h4>Billing By Team Member</h4>
            <Table data={billingData} revenue hours columns={defaultColumns} showPagination={false} />
        </div>
    );
};

export default BillingByTeam;
