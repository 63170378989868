import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { clientProjectsColumns, subclientProjectsColumns } from "./lib/columns.js";
import { ClientExistingProjectsStore, nextPage, previousPage, gotoPage, setPageSize, setSort } from "$stores/clientExistingProjects.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ClientDetailsStore } from "$stores/client-details.js";

const ExistingProjects = () => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const { projects, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = ClientExistingProjectsStore.useState((c) => {
        return { projects: c.clientExistingProjects || [], pageSize: c.pageSize, pageIndex: c.pageIndex, pageCount: c.pageCount, isLoading: c.isLoading, sort: c.sort, isSortAsc: c.isSortAsc };
    });

    const hasSubClients = ClientDetailsStore.useState((s) => s.hasSubClients);

    const rowClick = (row) => {
        navigate(`/project/${row.original.id}/existing`);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Existing Project",
                section: "Client Detail",
                projectId: row.original.id,
            }
        );
    };

    const data = projects.map(mapToTableData);
    return (
        <TableLoader
            containerClass="project-list"
            data={data}
            columns={hasSubClients ? subclientProjectsColumns : clientProjectsColumns}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            setSort={setSort}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={pageCount}
            isLoading={isLoading}
            sort={sort}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
        />
    );
};

export default ExistingProjects;
