import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import classnames from "classnames";

import ExpertPanel from "./panels/expert";
import MyClientsPanel from "./panels/my-clients";
import MyProjectsPanel from "./panels/my-projects";
import AllFirmPanel from "./panels/all-firm";
import SearchPanel from "./panels/search";
import PanelNav from "./components/panel-nav";

import { fetchPotentialRevenue } from "../../stores/opportunities";
import { AppContextStore } from "../../stores/app-context";
import { UserStore } from "../../stores/user";
import { PanelContext } from "../../contexts/panels";
import { TOOLTIP_GROWTH_POTENTIAL } from "$utils/tooltips";
import { SHORT_DATE_FORMAT, STANDARD_DATE_FORMAT } from "$utils/date-helpers";
import { handleAbort } from "$services/api";

import "./growth-potential-panel.scss";

// TODO: Main date placeholder

const GrowthPotentialPanel = () => {
    const { panels, togglePanel } = useContext(PanelContext);
    const profile = UserStore.useState((u) => u);
    const lastDataUpdate = AppContextStore.useState((f) => f.versions.lastModelRun);

    const [currentHash, setCurrentHash] = useState("#myclients");
    let location = useLocation();

    useEffect(() => {
        const hash = location.hash.split("?");
        if (hash[0]) {
            setCurrentHash(hash[0]);
            // Trigger for opening the panel on page load
            if (hash[1] && hash[1].toLowerCase() === "openpanel") {
                togglePanel("growthPotential", true);
            }
        }
    }, [location]);

    useEffect(() => {
        let controllerPotentialRevenue;
        const $panelRoot = document.getElementById("panel-root");
        if ($panelRoot) {
            if (panels["growthPotential"]) {
                controllerPotentialRevenue = fetchPotentialRevenue({ crm: profile.id });
                $panelRoot.classList.add("-active");
            } else {
                $panelRoot.classList.remove("-active");
            }
        }

        return () => {
            handleAbort([controllerPotentialRevenue]);
        };
    }, [panels["growthPotential"], profile]);

    const setFirstHash = (firstHash) => {
        if (firstHash != currentHash) {
            setCurrentHash(firstHash);
        }
    };

    return ReactDOM.createPortal(
        <>
            <div id="growth-potential-panel" className={classnames("panel-contents", { "-active": panels["growthPotential"] })}>
                <div className="growth-potential-panel">
                    <header>
                        <h1 className="txt-uppercase">
                            <span data-tooltip={TOOLTIP_GROWTH_POTENTIAL}>
                                <em>Growth</em> Potential
                            </span>
                        </h1>

                        <h4>
                            {lastDataUpdate ? format(lastDataUpdate, SHORT_DATE_FORMAT) : format(new Date(), SHORT_DATE_FORMAT)} {lastDataUpdate && <span> Updated: {format(lastDataUpdate, STANDARD_DATE_FORMAT)}</span>}
                        </h4>
                        <PanelNav currentHash={currentHash} setFirstHash={setFirstHash} />
                    </header>
                    {currentHash === "#myclients" && <MyClientsPanel />}
                    {currentHash === "#myprojects" && <MyProjectsPanel />}
                    {currentHash === "#allfirm" && <AllFirmPanel />}
                    {currentHash === "#expert" && <ExpertPanel />}
                    {currentHash === "#search" && <SearchPanel />}
                </div>
            </div>
        </>,
        document.getElementById("panel-root")
    );
};

export default GrowthPotentialPanel;
