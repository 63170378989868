import React from "react";

import HealthArrow from "$components/health-arrow";
import LargeNumber from "$components/info-list/content/large-number";

import { abbrNum } from "$utils/number-helper";

const StatWithChange = ({ label, value, increase, isHealthy, tooltip }) => {
    return (
        <div className="stat">
            <h4 data-tooltip={tooltip}>{label}</h4>
            <div className="stats">
                <LargeNumber value={`$${abbrNum(value)}`} isHealthy={isHealthy} />
                {increase ? <HealthArrow isHealthy={increase >= 0} isIncrease={increase >= 0} /> : null}
                {!isNaN(increase) && <div>{Math.abs(increase)}%</div>}
            </div>
        </div>
    );
};

StatWithChange.defaultProps = {
    value: 0,
};

export default StatWithChange;
