import React from "react";
import LargeNumber from "$components/info-list/content/large-number";
import SimpleLineChart from "$components/charts/simple-line";

import { abbrNum } from "$utils/number-helper";

const RevenueCell = ({ data }) => {
    const isHealthy = true;
    return (
        <div className="chart-column-container">
            <SimpleLineChart data={data.row.original.revenueHistory} width="94px" height="62px" xKey="revenue" yKey="revenue" isHealthy={isHealthy} hasFill={true} />
            <LargeNumber value={`$${abbrNum(data.row.original.revenueHistoryTotal)}`} isHealthy={isHealthy} />
        </div>
    );
};

export default RevenueCell;
