import React from "react";

import TeamMember from "$components/profile-icon/team-member";

const TeamInfoCell = ({ value, label, crmName, crmEmail }) => {
    const teamInitials = value.map((m, i) => {
        return <TeamMember key={`member-${i}`} data={m} />;
    });

    return (
        <div className="focus-info">
            {crmName && (
                <div>
                    <h5>CRM</h5>
                    <div className="team-members">
                        <TeamMember data={{ name: crmName, email: crmEmail }} />
                    </div>
                </div>
            )}
            <h5>{label}</h5>
            <div className="team-members">{teamInitials}</div>
        </div>
    );
};

export default TeamInfoCell;
