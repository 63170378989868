import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./breadcrumb.scss";

const Breadcrumb = ({ links }) => {
    const navigate = useNavigate();
    const items = links.map((l, i) => {
        return (
            <li key={`breadcrumb_${i}`}>
                <Link to={l.url}>{l.text}</Link>
            </li>
        );
    });
    return (
        <div className="breadcrumb">
            <button
                className="back button -text"
                onClick={() => {
                    navigate(-1);
                }}
            >
                <div className="circle">↩</div>Back
            </button>
            <ul>{items}</ul>
        </div>
    );
};

Breadcrumb.defaultProps = {
    links: [],
};

export default Breadcrumb;
