import React from "react";

import { abbrNum } from "../../../utils/number-helper";

export const buildContent = (s) => {
    return {
        content: (
            <div>
                <h3>{s.companyName}</h3>
                <p>
                    Growth Potential =&nbsp;
                    <b>
                        <sup>{s.scoreBubble.preSup}</sup>
                        {s.scoreBubble.value}
                    </b>
                    <br />
                    Top Project: {s.topProject} =&nbsp;
                    <b>
                        <sup>$</sup>
                        {abbrNum(s.topProjectPotentialRevenue)}
                    </b>
                </p>
            </div>
        ),
        ...s,
    };
};

export const buildProjectContent = (s) => {
    return {
        content: (
            <div>
                <h3>{s.topProject}</h3>
                <p>
                    Growth Potential =&nbsp;
                    <b>
                        <sup>{s.scoreBubble.preSup}</sup>
                        {s.scoreBubble.value}
                    </b>
                    <br />
                    Client: {s.companyName}
                </p>
            </div>
        ),
        ...s,
    };
};
