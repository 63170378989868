import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, ResponsiveContainer } from "recharts";

import NoData from "./no-content";

import { BarBubbleTooltip } from "$components/charts/tooltips/bar-bubble";
import { GRID_STROKE } from "$components/charts/svg-definitions";

const ChartBarBubble = ({ data, yDomain }) => {
    if (!data || data.length <= 0) {
        return <NoData />;
    }
    return (
        <div className="chart-container">
            <ResponsiveContainer width="99%" height="100%">
                <BarChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 5,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid stroke={GRID_STROKE} vertical={false} />
                    <XAxis dataKey="text" />
                    <YAxis
                        allowDataOverflow={true}
                        tickCount={5}
                        domain={yDomain}
                        label={{ value: "Project Count", angle: -90, fill: "#f3f3f3", position: "insideLeft", offset: 16, style: { textAnchor: "middle" } }}
                        style={{ fill: "#f3f3f3" }}
                    />
                    <Bar dataKey="value" fill="#de653a" minPointSize={5} barSize={10}>
                        <LabelList dataKey="value" content={BarBubbleTooltip} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

ChartBarBubble.defaultProps = {
    yDomain: [(dataMin) => 0, (dataMax) => Math.floor(dataMax * 1.2)],
};

export default ChartBarBubble;
