import React, { useEffect, useRef } from "react";
import classNames from "classnames";

import { extractValue } from "$components/form-inputs/libs/helpers";
import { scrollIntoViewIfNeeded } from "$components/form-inputs/libs/scroll-into-view";

const SelectItem = ({ isActiveIndex, item, updateValues, values }) => {
    const dropdownItem = useRef();
    const isSelected = values.some((v) => extractValue(v) === extractValue(item));

    useEffect(() => {
        if (isActiveIndex) {
            scrollIntoViewIfNeeded(dropdownItem.current);
        }
    }, [isActiveIndex]);

    const updateOnChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        updateValues(item, !isSelected);
    };

    return (
        <li ref={dropdownItem}>
            <a href="#selectItem" className={classNames({ "-active": isActiveIndex })} tabIndex="-1" aria-selected={isActiveIndex} role="option" onClick={updateOnChange}>
                {isSelected && <span className="checkmark"></span>}
                {item.text}
            </a>
        </li>
    );
};

export default SelectItem;
