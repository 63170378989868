import { TOOLTIP_EXCELLENT, TOOLTIP_GOOD, TOOLTIP_REVIEW } from "$utils/tooltips";

const unhealthyThreshold = 299;

export const getHealthColorClass = (value) => {
    return value > unhealthyThreshold ? "txt -healthy" : "txt -unhealthy";
};

export const checkIsHealthy = (value) => {
    return value > unhealthyThreshold ? true : false;
};

export const checkIsHealthyByChange = (changeAmount) => {
    return changeAmount >= 0;
};

export const getVariableHealthColorClass = (value) => {
    if (!value) {
        return "-health-unknown";
    }

    if (value <= 139) {
        return "-health-10";
    } else if (value > 140 && value <= 179) {
        return "-health-20";
    } else if (value > 180 && value <= 219) {
        return "-health-30";
    } else if (value > 220 && value <= 259) {
        return "-health-40";
    } else if (value > 260 && value <= 299) {
        return "-health-50";
    } else if (value > 300 && value <= 379) {
        return "-health-60";
    } else if (value > 380 && value <= 459) {
        return "-health-70";
    } else if (value > 460 && value <= 539) {
        return "-health-80";
    } else if (value > 540 && value <= 619) {
        return "-health-90";
    } else if (value > 620) {
        return "-health-100";
    } else {
        return "-health-unknown";
    }
};

export const calculateScoreMark = (score) => {
    let scoreMark = {
        section: "",
        label: "Unkown",
        tooltip: "",
    };
    if (score <= 199) {
        scoreMark = {
            section: "100",
            label: "Review",
            tooltip: TOOLTIP_REVIEW,
        };
    } else if (score > 199 && score <= 299) {
        scoreMark = {
            section: "200",
            label: "Review",
            tooltip: TOOLTIP_REVIEW,
        };
    } else if (score > 299 && score <= 399) {
        scoreMark = {
            section: "300",
            label: "Good",
            tooltip: TOOLTIP_GOOD,
        };
    } else if (score > 399 && score <= 499) {
        scoreMark = {
            section: "400",
            label: "Good",
            tooltip: TOOLTIP_GOOD,
        };
    } else if (score > 499 && score <= 599) {
        scoreMark = {
            section: "500",
            label: "Excellent",
            tooltip: TOOLTIP_EXCELLENT,
        };
    } else if (score > 599) {
        scoreMark = {
            section: "600",
            label: "Excellent",
            tooltip: TOOLTIP_EXCELLENT,
        };
    }

    scoreMark.percentage = (score / 700) * 100;

    return scoreMark;
};

export const getScorePhrase = (score) => {
    if (score <= 299) {
        return `${score} Needs Review, Watch Out`;
    } else if (score > 300 && score <= 499) {
        return `${score} is Good, See Where You Can Accelerate Performance`;
    } else if (score > 599) {
        return `${score} is Excellent, Lookout For More Opportunities`;
    } else {
        return "";
    }
};

// TODO: Remove this when we get themeScores in recommendations
export const getHealthColorClassByRange = (range) => {
    return range.toLowerCase() === "review" ? "txt-unhealthy" : "txt-healthy";
};

export const cleanString = (str) => {
    if (!str) {
        return "";
    }
    return str.toLowerCase().replace(/ /g, "-");
};
