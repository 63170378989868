import React, { useEffect } from "react";
import debounce from "debounce";

import PotentialProjectList from "./list.js";

import { PotentialProjectsStore, fetchPotentialProjects, setLoading, reset } from "$stores/potentialProjects.js";
import { AppContextStore, fetchOverview } from "$stores/app-context";
import { useDocumentTitle } from "$utils/document-title.js";

const debouncedFetchProjects = debounce(async (updatedFilters, pagingInfo) => {
    fetchPotentialProjects(updatedFilters, { ...pagingInfo });
    //fetchOverview(updatedFilters);
}, 800);

const PotentialProjects = ({ overrideFilters, columns }) => {
    useDocumentTitle("Potential Project List");
    const { hasLoadedFilters, currentFilters } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, currentFilters: f.currentFilters || {} };
    });

    const pagingInfo = PotentialProjectsStore.useState((f) => {
        return { page: f.pageIndex, limit: f.pageSize, sort: f.sort, isSortAsc: f.isSortAsc };
    });

    useEffect(() => {
        return () => {
            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (hasLoadedFilters && currentFilters && pagingInfo) {
            setLoading();
            debouncedFetchProjects(overrideFilters || currentFilters, pagingInfo);
        }
    }, [hasLoadedFilters, currentFilters, pagingInfo, overrideFilters]);

    return (
        <div>
            <PotentialProjectList columns={columns} />
        </div>
    );
};

export default PotentialProjects;
