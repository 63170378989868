import React, { useState } from "react";
import api from "../../services/api";
import { REACT_APP_DOMAIN_URL } from "../../services/env";
import Button from "../button";
import EmployeeLookup from "./employee-lookup";
import { UserStore } from "../../stores/user";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const sendEmail = async (recipients, message, userName, pageName) => {
    const body = {
        emailRecipients: [...recipients.map((r) => r.email)], // We can also send emails to other people here
        data: {
            MC_PREVIEW_TEXT: `Orgaimi ${pageName}`,
            EMAIL_SUBJECT: `Orgaimi ${pageName}`,
            SHARE_CRITERIA: "",
            SHARE_DATE: "",
            USER_NAME: userName,
            DOMAIN_URL: REACT_APP_DOMAIN_URL,
            EMAIL_LINK: window.location.pathname,
            USER_MESSAGE: message,
        },
    };

    try {
        await api.post("/api/share", body);
        return { isSuccess: true };
    } catch (err) {
        return { isSuccess: false, error: err };
    }
};

const ShareRecommendations = ({ onClose, pageName = document.title.split(" - ")[0] }) => {
    const appInsights = useAppInsightsContext();
    const [sent, setSent] = useState(false);
    const [message, setMessage] = useState("");
    const [recipients, setRecipients] = useState([]);
    const [error, setError] = useState(null);
    const user = UserStore.useState((s) => s);

    const trackSuccess = () => {
        appInsights.trackEvent(
            { name: "Share" },
            {
                label: pageName,
            }
        );
    };

    return (
        <div className="share-recommendations txt -center -uppercase">
            <h2 className="txt-uppercase">
                <em>Share</em> {pageName}
            </h2>
            {!sent && (
                <>
                    {error && <h3 className="txt-error">{error}</h3>}
                    <h4 className="txt-uppercase">Share with:</h4>
                    <EmployeeLookup
                        onUpdate={(values) => {
                            setRecipients(values);
                        }}
                    />
                    <br />
                    <h4 className="txt-uppercase">Add Message</h4>
                    <div className="form-field -border -full">
                        <textarea
                            name="message"
                            value={message}
                            onChange={(e) => {
                                setMessage(e.currentTarget.value);
                            }}
                        ></textarea>
                    </div>
                    <div className="button-group -center">
                        <Button
                            className="button -primary"
                            onClick={async () => {
                                setError(null);
                                const result = await sendEmail(recipients, message, user.displayName, pageName);
                                if (result.isSuccess) {
                                    trackSuccess();
                                    setSent(true);
                                } else {
                                    setError("Unable to share! Please try again later.");
                                }
                            }}
                        >
                            Share
                        </Button>
                    </div>
                </>
            )}
            {sent && (
                <div>
                    <h3 className="txt-uppercase">Sent!</h3>
                    <p class="txt-small">
                        <a
                            href="/sent"
                            onClick={(e) => {
                                e.preventDefault();
                                onClose();
                            }}
                        >
                            Close
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default ShareRecommendations;
