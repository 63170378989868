import React, { useEffect, useState } from "react";
import { AppContextStore } from "../../../stores/app-context";

const AppVersion = () => {
    const [_version, setVersion] = useState("");
    const version = AppContextStore.useState((v) => v.versions);

    useEffect(() => {
        const getVersion = async () => {
            const REQUIRE_TERMINATOR = ""; // Confuses Webpack when using `require` on a file that may not be there so it doesn't throw an error
            let versionJson;
            try {
                versionJson = require(`../../../_version.json${REQUIRE_TERMINATOR}`);
            } catch (e) {
                // Dev Environment Defaults
                versionJson = {
                    CloudBuildNumber: "0.0.0-alpha",
                };
            }
            setVersion(versionJson);
        };
        getVersion();
    }, []);

    if (!_version) {
        return null;
    }

    const appVersion = `v${_version.CloudBuildNumber}`;

    return (
        <div className="build-number">
            APP: {appVersion} | API: {version.apiVersion}
        </div>
    );
};

export default AppVersion;
