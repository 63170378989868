import { extractValue } from "$components/form-inputs/libs/helpers";
import { isArray } from "lodash";

import { FILTER_SET } from "../stores/app-context";
import { DEFAULT_SORT } from "../stores/clients";

export const buildFilters = (filters = {}) => {
    return Object.keys(filters)
        .filter((key) => {
            return filters[key] !== undefined && filters[key] !== null && filters[key].length > 0;
        })
        .map((key) => {
            const keyReplacement = FILTER_SET.find((f) => f.key === key);
            const foundKey = keyReplacement?.apiKey || key;
            if (isArray(filters[key])) {
                return filters[key]
                    .map((value) => {
                        return `${foundKey}=${extractValue(value)}`;
                    })
                    .join("&");
            }
            return `${foundKey}=${extractValue(filters[key])}`;
        })
        .join("&");
};

export const objectToParamString = (data) => {
    // Usage: objectToParamString({ a: '1', b: '2', });
    // Returns: 'a=1&b=2'
    //
    // This also supports arrays:
    // objectToParamString({ a: ['1', '2']});
    // Returns: a=1&a=2
    return Object.keys(data)
        .reduce((arr, key) => {
            if (Array.isArray(data[key])) {
                for (var val of data[key]) {
                    arr.push(`${key}=${val}`);
                }

                return arr;
            }

            if (data[key] !== undefined || typeof data[key] === "boolean") {
                arr.push(`${key}=${data[key]}`);
            }
            return arr;
        }, [])
        .join("&");
};

export const buildSort = (sort = DEFAULT_SORT, isAsc) => {
    return `${sort} ${isAsc ? "asc" : "desc"}`;
};

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
